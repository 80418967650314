(function () {
    'use strict';

    angular
        .module('eltazkaraApp')
        .controller('EventController', EventController);

    EventController.$inject = [
        'NgTableParams',
        '$stateParams',
        '$rootScope',
        '$window',
        'Filters',
        'Event',
        'User',
        'Socialshare',
        'PHANTOM_URL'
    ];

    function EventController(NgTableParams, $stateParams, $rootScope, $window, Filters, Event, User, Socialshare, PHANTOM_URL) {
        var vm = this;
        vm.id = $stateParams.id;
        vm.requestSize = 5; //initial list size
        vm.countPerLoad = 5; //increase per step
        vm.listOffset = 0; //load more start index
        // vm.eventUrl = APP_URL + '#/event/' + vm.id;
        $rootScope.sharingEnabled = true;

        vm.filters = {
            "CategoryId": -1,
            "PerformerId": -1,
            "VenueId": -1
        };

        function createUsingFullOptions() {
            var initialParams = { count: vm.requestSize };

            var initialSettings = {
                counts: [],
                dataset: vm.list,
            };

            return new NgTableParams(initialParams, initialSettings);
        }

        vm.EventDetails;
        vm.eventLoading = false;
        vm.StartEventDay;
        vm.StartEventMonth;
        vm.StartEventYear;
        vm.EndEventDay;
        vm.EndEventMonth;
        vm.EndEventYear;
        vm.loadingMore;
        vm.loadMoreText = "Load More";
        vm.list = [];
        vm.hasPerformances = false;
        vm.hasPerformancesFiltered = false;
        vm.allCategories = [];
        vm.allEvents = [];
        vm.allEventsLoading = false;
        vm.allVenues = [];
        vm.buyBlocked = false;
        vm.sellBlocked = false;
        vm.hasTickets = false;

        vm.getEvent = function () {
            vm.eventLoading = true;

            Event.getEvent({ "Id": vm.id }).$promise.then(function (data) {
                vm.eventLoading = false;

                if (data) {
                    var dataList = data.AllEvents;
                    var tempItem = {
                        "name": dataList[0].Name,
                        "Description": dataList[0].Description,
                        "Image": dataList[0].Image,
                        "ParentCategory": dataList[0].ParentCategory,
                        "ParentCategories": dataList[0].ParentCategories,
                        "EventRestrection": dataList[0].EventRestrection,
                    };

                    vm.EventDetails = tempItem;
                    console.log(vm.EventDetails);
                }
            }, function (error) { vm.eventLoading = false; })
        };

        vm.getPerformances = function (more) {
            var listLimit = vm.countPerLoad;

            if (more) {
                vm.loadingMore = true;
                vm.loadMoreText = "Please wait...";
            }
            else {
                vm.listOffset = 0;
                vm.requestSize = vm.countPerLoad;
                vm.allEventsLoading = true;
            }

            Event.getEventPerformances({
                "Id": vm.id,
                "Offset": vm.listOffset,
                "Limit": listLimit,
                "Filters": vm.filters
            }).$promise.then(function (data) {
                vm.allEventsLoading = false;

                if (data) {
                    if (data.DataList.length > 0)
                        vm.hasPerformances = vm.hasPerformancesFiltered = true;
                    else {
                        if (data.Filtered)
                            vm.hasPerformancesFiltered = false;
                    }

                    var dataList = data.DataList;
                    vm.list = more ? vm.list : [];
                    vm.lastCall = data.LastCall;

                    for (var i = 0; i < dataList.length; i++) {
                        if (dataList[i].IsSellBlocked == true)
                            vm.sellBlocked = true;

                        if (dataList[i].IsBuyBlocked == true)
                            vm.buyBlocked = true;

                        if (dataList[i].AvailableTicket > 0)
                            vm.hasTickets = true;

                        var tempItem = {
                            "venueid": dataList[i].venueid,
                            "Name": dataList[i].Name,
                            "EventId": dataList[i].EventId,
                            "EventName": dataList[i].EventName,
                            "Description": dataList[i].Description,
                            "Performence": dataList[i].Performence,
                            "PerformenceId": dataList[i].PerformenceId,
                            "venue": dataList[i].venue,
                            "startDate": dataList[i].startDate,
                            "EndDate": dataList[i].EndDate,
                            "dayName": dataList[i].dayName,
                            "day": dataList[i].day,
                            "month": dataList[i].month,
                            "year": dataList[i].year,
                            "time": dataList[i].time,
                            "period": dataList[i].period,
                            "AvailableTicket": dataList[i].AvailableTicket,
                            "IsClosedBooking": dataList[i].IsClosedBooking,
                            "IsSellBlocked": dataList[i].IsSellBlocked,
                            "IsBuyBlocked": dataList[i].IsBuyBlocked,
                        };

                        vm.list.push(tempItem);
                        vm.StartEventDay = dataList[0].day;
                        vm.StartEventMonth = dataList[0].month;
                        vm.StartEventYear = dataList[0].year;
                        vm.EndEventDay = dataList[dataList.length - 1].Endday;
                        vm.EndEventMonth = dataList[dataList.length - 1].Endmonth;
                        vm.EndEventYear = dataList[dataList.length - 1].Endyear;
                    }

                    vm.getAllFilters();
                }

                if (more) {
                    vm.loadingMore = false;
                    vm.loadMoreText = "Load More";
                }

                vm.customConfigParams = createUsingFullOptions();
            }, function (error) { vm.allEventsLoading = false; })
        };

        vm.getAllFilters = function () {
            Filters.getEventFilters({ "id": vm.id }).$promise.then(function (data) {
                if (data) {
                    vm.allCategories = data.EventCategories;

                    if (vm.allCategories.length > 1) {
                        vm.allCategories.unshift({
                            "CategoryId": -1,
                            "CategoryName": "All"
                        });
                    }
                    else if (vm.allCategories.length === 1) {
                        vm.allCategories[0].CategoryId = -1;
                    }
                    else {
                        vm.allCategories.unshift({
                            "CategoryId": -1,
                            "CategoryName": "No categories"
                        });
                    }

                    vm.allPerformers = data.EventPerformers;

                    if (vm.allPerformers.length > 1) {
                        vm.allPerformers.unshift({
                            "PerformerId": -1,
                            "PerformerName": "All"
                        });
                    }
                    else if (vm.allPerformers.length === 1)
                        vm.allPerformers[0].PerformerId = -1;
                    else {
                        vm.allPerformers.unshift({
                            "PerformerId": -1,
                            "PerformerName": "No performers"
                        });
                    }

                    vm.allVenues = data.EventVenues;

                    if (vm.allVenues.length > 1) {
                        vm.allVenues.unshift({
                            "VenueId": -1,
                            "VenueName": "All"
                        });
                    }
                    else if (vm.allVenues.length === 1)
                        vm.allVenues[0].VenueId = -1;
                    else {
                        vm.allVenues.unshift({
                            "VenueId": -1,
                            "VenueName": "No venues"
                        });
                    }
                }

                vm.customConfigParams = createUsingFullOptions();
            }, function (error) { })
        };

        vm.getEvent();
        vm.getPerformances(false);

        vm.loadMore = function () {
            vm.listOffset = vm.requestSize;
            vm.requestSize += vm.countPerLoad;
            vm.customConfigParams.count(vm.requestSize);
            vm.getPerformances(true);
        };

        vm.trySell = function (performanceId) {
            if ($rootScope.User && $rootScope.User.Email) {
                User.IsUserBlockedOrDeleted({ "UserId": $rootScope.User.UserId }).$promise.then(function (data) {
                    if (data.status == "true")
                        location.href = "#/performance/" + performanceId + "/sell";
                    else {
                        location.href = "#/";
                        $rootScope.PopUpTitle = "Blocked User";
                        $rootScope.PopUpDescription = "You don't have access please contact with Admin";
                        $rootScope.showPopup = true;
                    }
                }, function (error) { });
            }
            else
                vm.toggleLoginPanel();
        };

        vm.share = function () {
            $rootScope.siteTitle = 'Tixawy';

            Socialshare.share({
                'provider': 'facebook',
                'attrs': {
                    'socialshareUrl': PHANTOM_URL
                }
            });
        };

        vm.toggleLoginPanel = function () {
            $window.scrollTo(0, 0);
            $rootScope.loginPanelActive = !$rootScope.loginPanelActive;
            document.getElementById("userLoginEmail").value = "";
            document.getElementById("userLoginPassword").value = "";
            document.getElementById("userLoginRemember").checked = false;
            $rootScope.userLoginError = false;
            vm.User = {};
            vm.errors = {};
        };
    }
})();
