(function () {
  'use strict';

  angular
      .module('eltazkara.handler')
      .factory('stateHandler', stateHandler);

  stateHandler.$inject = ['$rootScope', '$state', '$sessionStorage', '$window', 'Auth', 'Principal'];

  function stateHandler($rootScope, $state, $sessionStorage, $window, Auth, Principal) {
    return {
      initialize: initialize
    };

    function initialize() {
      //$rootScope.VERSION = VERSION;

      var stateChangeStart = $rootScope.$on('$stateChangeStart', function (event, toState, toStateParams, fromState) {
        $rootScope.toState = toState;
        $rootScope.toStateParams = toStateParams;
        $rootScope.fromState = fromState;

        // Redirect to a state with an external URL (http://stackoverflow.com/a/30221248/1098564)
        if (toState.external) {
          event.preventDefault();
          $window.open(toState.url, '_self');
        }

        if (Principal.isIdentityResolved()) {
          Auth.authorize();
        }

      });

      var statesArray = [];
      $rootScope.backtransition = false;

      var stateChangeSuccess = $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
        //Reset Theme
        $rootScope.theme='';
        //var titleKey = 'global.title';
        //
        // //Set the page title key to the one configured in state or use default one
        //if (toState.data.pageTitle) {
        //  titleKey = toState.data.pageTitle;
        //}
        //translationHandler.updateTitle(titleKey);

        // scroll to top
        document.body.scrollTop = document.documentElement.scrollTop = 0;

        $rootScope.backtransition = false;

        statesArray.push(fromState.name);

        var found = statesArray.indexOf(toState.name);
        if (found != -1) {

          var foundIndex = statesArray.indexOf(toState.name);
          statesArray.splice(foundIndex + 1, statesArray.length - foundIndex - 1);
          $rootScope.backtransition = true;
        }
      });

      $rootScope.$on('$destroy', function () {
        if (angular.isDefined(stateChangeStart) && stateChangeStart !== null) {
          stateChangeStart();
        }
        if (angular.isDefined(stateChangeSuccess) && stateChangeSuccess !== null) {
          stateChangeSuccess();
        }
      });
    }
  }
})();
