(function () {
  'use strict';

  angular
      .module('eltazkara.interceptor')
      .factory('errorHandlerInterceptor', errorHandlerInterceptor);

  errorHandlerInterceptor.$inject = ['$q', '$rootScope'];

  function errorHandlerInterceptor($q, $rootScope) {
    var service = {
      responseError: responseError
    };

    return service;

    function responseError(response) {
      if (response.status === 401) {
          $rootScope.$emit('eltazkara.httpUnAuthorized', response);
      }
      return $q.reject(response);
    }
  }
})();
