(function () {
  'use strict';

  angular
    .module('eltazkaraApp')
    .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider.state('terms', {
      parent: 'app',
      url: '/terms',
      views: {
        'content@': {
          templateUrl: 'static/terms.html'
        }
      },

      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('global');
        }]
      }
    })
      .state('privacy', {
        parent: 'app',
        url: '/privacy',
        views: {
          'content@': {
            templateUrl: 'static/privacy.html',
            controller: 'NotFoundController',
            controllerAs: 'vm'
          }
        }
      })
      .state('sellerAgreement', {
        parent: 'app',
        url: '/seller-agreement',
        views: {
          'content@': {
            templateUrl: 'static/sellerAgreement.html',
            controller: 'NotFoundController',
            controllerAs: 'vm'
          }
        }
      })
      .state('buyerAgreement', {
        parent: 'app',
        url: '/buyer-agreement',
        views: {
          'content@': {
            templateUrl: 'static/buyerAgreement.html',
            controller: 'NotFoundController',
            controllerAs: 'vm'
          }
        }
      })
      .state('cookies', {
        parent: 'app',
        url: '/cookies',
        views: {
          'content@': {
            templateUrl: 'static/cookies.html',
            controller: 'NotFoundController',
            controllerAs: 'vm'
          }
        }
      })
      .state('about', {
        parent: 'app',
        url: '/about',
        views: {
          'content@': {
            templateUrl: 'static/about.html',
            controller: 'NotFoundController',
            controllerAs: 'vm'
          }
        }
      })
      ;
  }
})();
