(function () {
    'use strict';
    angular
        .module('eltazkaraApp')
        .directive('fullName', checkNameInput)
        .directive('mobilePhone', checkPhoneInput)
        .directive('price', checkPriceInput)
        .directive('ticketsNum', checkNumberOfTickets)
        .directive('match', matchPass);
    //HS: Valid name input
    var validLetters = [];
    for (var i = 65; i < 91; i++) {
        validLetters.push(i);
    }
    validLetters.push(8, 9, 13, 16, 17, 18, 19, 20, 27, 32, 33, 34, 35, 36, 37, 38, 39, 40, 45, 46);
    //HS: Valid phone input
    var validPhoneDigits = [];
    for (var i = 48; i < 58; i++) {
        validPhoneDigits.push(i);
    }
    validPhoneDigits.push(8, 9, 13, 16, 17, 18, 19, 20, 27, 33, 34, 35, 36, 37, 38, 39, 40, 45, 46, 144, 173, 189);
    for (var i = 96; i < 110; i++) {
        if (i === 106) continue;
        validPhoneDigits.push(i);
    }
    var primaryDigits = [];
    for (var i = 49; i < 57; i++) {
        primaryDigits.push(i);
    }
    primaryDigits.push(189);
    //HS: Valid price input
    var validPhoneDigitsCopy = angular.copy(validPhoneDigits);
    var validPriceDigits = [];
    var phonePriceDiff = [107, 109, 173, 189];
    for (var i = 0; i < phonePriceDiff.length; i++) validPhoneDigitsCopy.splice(validPhoneDigitsCopy.indexOf(phonePriceDiff[i]), 1);
    validPriceDigits = validPhoneDigitsCopy;
    function checkNameInput() {
        return {
            restrict: 'A',
            link: function (scope, element) {
                element.bind('keydown', function (event) {
                    if (validLetters.indexOf(event.keyCode) < 0) {
                        event.preventDefault();
                    }
                });
            }
        };
    }
    //HS:Phone number directive
    function checkPhoneInput() {
        return {
            restrict: 'A',
            link: function (scope, element) {
                var inputs = [];
                element.bind('keydown', function (event) {
                    //var lastInput = element.context.value[element.context.value.length - 1];
                    if (validPhoneDigits.indexOf(event.keyCode) < 0) {
                        if (event.keyCode === 187 || event.keyCode === 61) {
                            if (event.shiftKey && event.keyCode) {
                                if (event.target.selectionStart === 0) {
                                    if (element.context.value[event.target.selectionStart] === "+") {
                                        event.preventDefault();
                                        return;
                                    }
                                }
                                else {
                                    event.preventDefault();
                                    return;
                                }
                                return;
                            }
                        }
                        event.preventDefault();
                    }
                    else if (primaryDigits.indexOf(event.keyCode) > -1) {
                        if ((event.shiftKey && event.keyCode)) {
                            event.preventDefault();
                            return;
                        }
                        else if (event.keyCode === 189 || event.keyCode === 173) {
                            if (event.target.selectionStart === 0) {
                                event.preventDefault();
                                return;
                            }
                            else {
                                if (element.context.value[event.target.selectionStart] === "+"
                                    || element.context.value[event.target.selectionStart - 1] === "+") {
                                    event.preventDefault();
                                    return;
                                }
                            }
                        }
                    }
                    else {
                         if (event.keyCode === 109) {
                            if (event.target.selectionStart === 0) {
                                event.preventDefault();
                                return;
                            }
                            else {
                                if (element.context.value[event.target.selectionStart] === "+"
                                    || element.context.value[event.target.selectionStart - 1] === "+") {
                                    event.preventDefault();
                                    return;
                                }
                            }
                         }
                         else if((event.keyCode === 48 || event.keyCode === 57) && event.shiftKey) {
                             event.preventDefault();
                         }
                    }
                    //inputs.push(event.keyCode);
                });
            }
        };
    }
    //HS:Ticket group price directive
    function checkPriceInput() {
        return {
            restrict: 'A',
            link: function (scope, element) {
                element.bind('keydown', function (event) {
                    if (validPriceDigits.indexOf(event.keyCode) < 0) {
                        event.preventDefault();
                    }
                    else if (event.keyCode === 48 || event.keyCode === 96) {
                        if (event.target.selectionStart === 0) {
                            event.preventDefault();
                        }
                    }
                });
            }
        };
    }
    //HS:Max number of tickets directive
    function checkNumberOfTickets($timeout, $parse) {
        return {
            restrict: 'A',
            require: 'ngModel',
            controller: 'SellController',
            controllerAs: 'vm',
            link: function (scope, elem, attrs, ctrl) {
                scope.bundlesCopy = angular.copy(scope.vm.Bundles);
                scope.$watch('vm.NumberOfTickets', function (oldVal, newVal) {
                    scope.theCopy = [];
                    angular.element(elem).on("keyup", function (e) {
                        var theCopy = angular.copy(scope.bundlesCopy);
                        if (scope.vm.NumberOfTickets != null && scope.vm.NumberOfTickets.length === 3) {
                            e.preventDefault();
                            return;
                        }
                        scope.$apply(function () {
                            if (parseInt(scope.vm.NumberOfTickets) % 2 !== 0) {
                                if (parseInt(scope.vm.NumberOfTickets) === 1) {
                                    theCopy.splice(1, 3);
                                    //theCopy.splice(2, 1);
                                    scope.vm.PerformanceDetails.Bundles = theCopy;
                                    scope.vm.SelectedBundle = theCopy[0];
                                }
                                else {
                                    theCopy.splice(1, 1);
                                    scope.vm.PerformanceDetails.Bundles = theCopy;
                                    scope.vm.SelectedBundle = theCopy[0];
                                }
                            }
                            else {
                                scope.vm.PerformanceDetails.Bundles = theCopy;
                                scope.vm.SelectedBundle = theCopy[0];
                            }
                        })
                    });
                    if (parseInt(scope.vm.NumberOfTickets) > 25) {
                        scope.vm.NumberOfTickets = 25;
                    }
                    else if (parseInt(scope.vm.NumberOfTickets) < 1) {
                        scope.vm.NumberOfTickets = 25;
                    }
                }, true)
            }
        };
    }
    //HS:Password confirmation directive
    function matchPass() {
        return {
            restrict: 'A',
            scope: true,
            require: 'ngModel',
            link: function (scope, elem, attrs, control) {
                var checker = function () {
                    var passConf = scope.$eval(attrs.ngModel);
                    var pass = scope.$eval(attrs.match);
                    return passConf == pass;
                };
                scope.$watch(checker, function (validity) {
                    control.$setValidity("matched", validity);
                });
            }
        };
    }
})();
