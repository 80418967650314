// This directive writen by Ahmed Islam
(function() {
	'use strict';

	angular.module('eltazkara.utils').directive('eturnPopup', uploadDirective);

	popupCtrl.$inject = ['$scope', '$rootScope', '$attrs'];

	function uploadDirective() {
		return {
			restrict: 'AE',
			transclude: true,
			controller: popupCtrl,
			controllerAs: 'poper',
			bindToController: true,
			scope: {
				title: '@',
				printable: '@',
				closable: '@',
				showPoper: '='
			},
			templateUrl: 'components/utils/popup.html'
		};
	}

	function popupCtrl($scope, $rootScope, $attrs) {
		// define controller scope
		var poper = this;
		poper.title = $attrs.title;
		poper.printable = $attrs.printable;
		poper.closable = $attrs.closable;
		poper.showPopup = true;
		poper.id = $attrs.id;
		poper.spread = typeof($attrs.spread) !== 'undefined';
		poper.closePoper = function() {
			poper.showPopup = false;
			poper.showPoper = false;
		};
		poper.print = function() {
			//$("#printableArea").css("background", "rgba(255,255,255,0.5)");
			var printContents = document.getElementById('printableArea')
				.innerHTML;
			var popupWin = window.open('', '', '');
			popupWin.document.open();
			popupWin.document.write(
				'<html>' +
					document.head.innerHTML +
					'<body onload="window.print()">' +
					'<div style="background: rgba(255,255,255,0.5);' +
					'display: inline-block;' +
					'padding: 30px;' +
					'width: 800px;' +
					'margin-left: calc(50% - 400px);' +
					'color: black;">' +
					printContents +
					'</div>' +
					'</body></html>'
			);
			popupWin.document.close();
		};
	}
})();
