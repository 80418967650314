(function () {
  'use strict';

  angular
      .module('eltazkaraApp')
      .controller('SocialLoginController', SocialLoginController);

  SocialLoginController.$inject = [
    'GooglePlus',
    'Google',
    'Auth',
    '$rootScope',
    '$cookies'
  ];

  function SocialLoginController(GooglePlus, Google, Auth, $rootScope, $cookies) {
    var vm = this;

    vm.googleLogin = function () {
      GooglePlus.login().then(function (authResult) {
        Google.post({accessToken: authResult.access_token}, function (data) {
          var token = $cookies.get('social-authentication');
          Auth.loginWithToken(token, false).then(function () {
            $cookies.remove('social-authentication');
            Auth.authorize(true);
            $rootScope.doTheBack();
          });
        });
      }, function (err) {
      });
    };
  };
})();
