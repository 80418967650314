(function () {
    'use strict';

    angular
        .module('eltazkaraApp')
        .factory('thankservice', thankservice);

    thankservice.$inject = ['$resource'];

    function thankservice($resource) {
        var service = $resource(window.backendUrl + 'Users', {}, {
            'VerifiyEmail': {
                method: 'POST', isArray: false,
                url: window.backendUrl + "Users/AcceptLinkToVerifiedMail"
            },
            'SendVerificationEmailToUser': {
                method: 'POST', isArray: false,
                url: window.backendUrl + "Users/SendVerificationLinkToMail"
            }
        });
        return service;
    }
})();
