(function () {
    'use strict';

    angular
        .module('eltazkaraApp')
        .factory('Home', Home);

    Home.$inject = ['$resource'];

    function Home($resource) {
        var service = $resource(window.backendUrl + '/Home', {}, {
            'getFeaturedEvents': {
                method: 'GET', isArray: true,
                url: window.backendUrl + "Home/GetFeaturedEvents"
            },
            'getFeaturedCategoriesEvents': {
                method: 'GET', isArray: true,
                url: window.backendUrl + "Home/GetCategroiesEvents"
            },
            'getTrendingToday': {
                method: 'GET', isArray: true,
                url: window.backendUrl + "Home/GetTrendingToday"
            },
            'getPopularVenue': {
                method: 'GET', isArray: false,
                url: window.backendUrl + "Home/GetPopularVenue"
            },
            'getPopularPerformers': {
                method: 'GET', isArray: true,
                url: window.backendUrl + "Home/GetPopularPerformers"
            }
        });

        return service;
    }
})();
