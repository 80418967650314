(function () {
  'use strict';

  angular
      .module('eltazkaraApp')
      .controller('PaymentSuccessController', PaymentSuccessController);

  PaymentSuccessController.$inject = [
    '$rootScope',
    'localStorageService',
    '$timeout',
    'PaymentService'
  ];

  function PaymentSuccessController($rootScope, localStorageService,$timeout,PaymentService) {
    var vm = this;
    vm.theCanvas='';
    vm.order=[];
    if($rootScope.User)
      vm.id = $rootScope.User.UserId;

    vm.getPaymentSuccess = function () {
      PaymentService.getPaymentSuccess({ "UserId": vm.id , "OrderReference":2})
      .$promise.then(function (data) {
        if (data) {
          var input = {
            "reference": data.Reference,
            "performanceName": data.PerformanceName,
            "performanceDate": data.PerformanceDate,
            "performanceDay": data.PerformanceDay,
            "performanceTime": data.PerformanceTime,
            "performanceVenue": data.PerformanceVenue,
            "ticketBlock": data.TicketBlock,
            "ticketRow": data.TicketRow,
            "ticketsNumber": data.TicketsNumber,
            "ticketPrice": data.TicketPrice,
            "ticketsTotalPrice": data.TicketsTotalPrice,
            "orderFees": data.OrderFees,
            "orderTotal": data.OrderTotal,
            "orderImage": data.OrderImage,
            "usedCard":data.UsedCard
          };
          vm.order = input;
        }
      }, function (error) { })
    }
    vm.getPaymentSuccess();
    //Show the invoice so script can capture it
    document.getElementById('invoice').classList.add('show');
    html2canvas(document.getElementById('invoice'), {
      onrendered: function(canvas) {
        vm.theCanvas=canvas;
        vm.invoiceLoading=false;
      }
    });
    //Hide it again very fast :D
    document.getElementById('invoice').classList.remove('show');
    vm.genInvoice=function(){
      var invoiceWindow=window.open(vm.theCanvas.toDataURL("image/png"),'_blank');
    }

  }

})();
