(function() {
	'use strict';

	angular
		.module('eltazkaraApp')
		.controller('SellController', PaymentController);

	PaymentController.$inject = [
		'$rootScope',
		'$state',
		'$stateParams',
		'PaymentService'
	];

	function PaymentController(
		$rootScope,
		$state,
		$stateParams,
		PaymentService
	) {
		if (!$rootScope.User) {
			$rootScope.loginPanelActive = true;
		}
		var vm = this;
		vm.id = $stateParams.id;
		vm.NumberOfTickets = vm.TicketsType = vm.Section = vm.Block = vm.Row =
			'';
		vm.StartingSeatNumber = vm.SellPrice = vm.printedPrice = '';
		vm.Bundles = [
			{ name: 'Any', id: 1 },
			{ name: 'Pair', id: 2 },
			{ name: 'Full', id: 3 },
			{ name: 'Pair Last', id: 4 }
		];
		vm.PerformanceDetails;
		vm.IsIdUploaded = true;
		vm.performanceLoading = false;
		vm.submitClickable = true;
		vm.showSeating = true;
		// Seating or Standing seats
		// by default seating
		vm.SelectedSeatingType = '1';
		vm.incrementType = 'Standard';
		vm.getPerformance = function() {
			vm.performanceLoading = true;
			PaymentService.getPerformance({ Id: vm.id }).$promise.then(
				function(data) {
					vm.performanceLoading = false;
					if (data) {
						vm.PerformanceDetails = data;
						if (!data.IsPhoneVerified || !data.IsEmailVerified) {
							$state.go('account', {});
						}
						vm.IsIdUploaded = vm.PerformanceDetails.IsIdUploaded;
						vm.SelectedBundle = vm.PerformanceDetails.Bundles[0];
						vm.SelectedSection = vm.PerformanceDetails.Sections[0];
						vm.SelectedTicketType =
							vm.PerformanceDetails.TicketTypes[0];
						vm.updateSelectedSection();
					} else {
						vm.goToHome();
					}
				},
				function(error) {
					vm.performanceLoading = false;
				}
			);
		};
		vm.getPerformance();

		vm.goToAccountSettings = function() {
			$state.go('account', {});
		};

		vm.goToHome = function() {
			$state.go('home', {});
		};

		vm.updateSelectedSection = function() {
			if (vm.SelectedSection) {
				if (
					vm.SelectedSection.name
						.toLocaleLowerCase()
						.includes('standing') ||
					vm.SelectedSection.name
						.toLocaleLowerCase()
						.includes('free seating')
				) {
					vm.showSeating = false;
					vm.StartingSeatNumber = '';
					vm.incrementType = '';
					vm.Block = '';
					vm.Row = '';
				} else {
					vm.StartingSeatNumber = '1';
					vm.incrementType = 'Standard';
					vm.showSeating = true;
				}
			}
		};

		vm.saveRequestData = function(bool) {
			if (!bool || !vm.submitClickable) return;
			vm.submitClickable = false;
			if (vm.SelectedSection == null) {
				PaymentService.saveSellRequest({
					performanceId: vm.PerformanceDetails.PerformanceId,
					numberOfTickets: vm.NumberOfTickets,
					ticketTypeId: vm.SelectedTicketType.id,
					bundleId: vm.SelectedBundle.id,
					block: vm.Block,
					row: vm.Row,
					startSeat: vm.StartingSeatNumber,
					ticketPrice: vm.SellPrice,
					printedPrice: vm.printedPrice,
					IncrementSeatingType: vm.incrementType,
					IsSeating: vm.showSeating
				}).$promise.then(
					function(data) {
						if (data) {
							if ((data.message = 'success'))
								$state.go('soldTickets', {});
						}
					},
					function(error) {}
				);
			} else {
				PaymentService.saveSellRequest({
					performanceId: vm.PerformanceDetails.PerformanceId,
					numberOfTickets: vm.NumberOfTickets,
					ticketTypeId: vm.SelectedTicketType.id,
					sectionId: vm.SelectedSection.id,
					bundleId: vm.SelectedBundle.id,
					block: vm.Block,
					row: vm.Row,
					startSeat: vm.StartingSeatNumber,
					ticketPrice: vm.SellPrice,
					printedPrice: vm.printedPrice,
					IncrementSeatingType: vm.incrementType,
					IsSeating: vm.showSeating
				}).$promise.then(
					function(data) {
						if (data) {
							if ((data.message = 'success'))
								$state.go('soldTickets', {});
						}
					},
					function(error) {}
				);
			}
		};
	}
})();
