(function() {
	'use strict';

	angular
		.module('eltazkaraApp')
		.controller('AccountController', AccountController);

	AccountController.$inject = [
		'$scope',
		'$rootScope',
		'$state',
		'$timeout',
		'userInfo',
		'fieldsService',
		'RequestsService',
		'FilesService'
	];

	function AccountController(
		$scope,
		$rootScope,
		$state,
		$timeout,
		userInfo,
		fieldsService,
		RequestsService,
		FilesService
	) {
		var vm = this;
		vm.nationalIdFileBase64 = null;

		$scope.$watch('vm.myFile', function() {
			if (typeof vm.myFile === 'undefined') return;
			var reader = new FileReader();

			reader.onload = function() {
				var binary = '';
				var arrayBuffer = this.result;
				var bytes = new Uint8Array(arrayBuffer);

				for (var i = 0; i < bytes.length; i++)
					binary += String.fromCharCode(bytes[i]);

				vm.nationalIdFileBase64 = btoa(binary);
			}

			reader.readAsArrayBuffer(vm.myFile);
		});

		vm.regex = {
			email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
			mobile: /^(20|0){0,1}1[0-2,5]{1}[0-9]{8}$/,
			password: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[\w~@#$%^&*+=`|{}:;!.?\"()\[\]-]+$/
		};

		vm.toggler = function(varName) {
			if (!vm[varName]) {
				vm[varName] = false;
			}

			vm[varName] = !vm[varName];
		};

		if ($rootScope.redirecSetting == true) {
			$rootScope.redirecSetting = false;
			window.location.reload(true);
		}

		vm.showFileUploadedPopup = vm.showInProgressPopup = vm.showFileErrorPopup = false;
		vm.filePopupTitle = vm.filePopupBody = '';
		vm.NationalId = { link: null };

		if ($rootScope.User) vm.id = $rootScope.User.UserId;
		else $state.go('home');

		vm.submitEmailText = vm.submitMobileText = vm.submitNewPasswordText = 'Update';
		vm.emailSubmitted = vm.mobileSubmitted = vm.passwordSubmitted = false;
		vm.emailSent = vm.mobileSent = vm.passwordSent = false;
		vm.emailUsed = vm.mobileUsed = vm.incorrectPassword = vm.duplicatePassword = false;
		vm.passwordReset;
		vm.MailIsSent = userInfo.getMailSent();
		/**-----------------------------------------------------------------------------------------------------------------------------------
        HS:Account Info
        -----------------------------------------------------------------------------------------------------------------------------------**/
		/*HS:Email
        ---------*/
		vm.accountInfoEmail = {};
		//HS:Get
		vm.getEmail = function() {
			userInfo
				.getEmail({
					UserId: vm.id
				})
				.$promise.then(function(data) {
					if (data.User) {
						vm.setaccountInfoEmailModel(data.User);
					}
				});
		};

		vm.getEmail();
		//HS:Set
		vm.saveEmail = function(emailValid) {
			vm.emailUsed = false;
			vm.emailSubmitted = true;
			if (emailValid) {
				vm.submitEmailText = 'Please wait...';
				vm.emailSent = true;
				var emailObj = {
					UserId: vm.id,
					Email: vm.accountInfoEmail.Address
				};
				userInfo
					.saveEmail({
						obj: emailObj
					})
					.$promise.then(function(data) {
						if (data.Break_Msg) {
							vm.emailUsed = true;
							vm.submitEmailText = 'Update';
							vm.emailSent = false;
						} else if (data.User) {
							vm.setaccountInfoEmailModel(data.User);
							vm.toggler('emailAddressToggle');
							vm.emailSubmitted = false;
							userInfo.setMailSent(true);
							vm.submitEmailText = 'Update';
							vm.emailSent = false;
							window.location.href =
								'#/users/emailVerifiy/SendVerificationEmail';
						}
						// vm.submitEmailText = "Update";
						//vm.emailSent = false;
					});
			}
		};

		//HS:Update scope
		vm.setaccountInfoEmailModel = function(data) {
			//HS:Must create copies of initial unedited values from server
			$rootScope.User.IsEmailVerified = data.IsEmailVerified;
			$rootScope.User.IsPhoneVerified = data.IsPhoneVerified;
			$rootScope.User.Email = data.Email;
			vm.accountInfoEmail.Address = data.Email;
			vm.NationalId.link = data.NationalId;
			vm.accountInfoEmail.AddressCopy = angular.copy(
				vm.accountInfoEmail.Address
			);
		};
		/*HS:Mobile
        ---------*/
		vm.accountInfoMobile = {};
		//HS:Get
		vm.getMobile = function() {
			userInfo
				.getMobile({
					UserId: vm.id
				})
				.$promise.then(function(data) {
					if (data.User) {
						vm.setaccountInfoMobileModel(data.User);
					}
				});
		};
		vm.getMobile();

		vm.GoVerifiyMobile = function() {
			vm.SendErrorRequestDescription =
				'We are not able to send you a verification code, please try again later.';
			vm.SendErrorRequestBoolean = true;
			userInfo.SendSMSVerifiy({}).$promise.then(function(data) {
				if (data.status && data.status == 'success') {
					$state.go('verify');
				} else {
					vm.SendErrorRequest(data.errors[0].message);
				}
			});
		};

		vm.SendErrorRequest = function(messageError) {
			RequestsService.AddRequest({
				Subject: 'SMS Credit is Over',
				RequestTypeId: { id: 6 },
				Name: $rootScope.User.Name,
				Mobile: $rootScope.User.MobileNumber,
				Email: $rootScope.User.Email,
				Body: messageError
			}).$promise.then(
				function(data) {
					if (data) {
						$rootScope.PopUpTitle = 'Mobile Verification Error';
						$rootScope.PopUpDescription =
							'We are not able to send you a verification code, please try again later.';
						$rootScope.PopError = true;
					}
				},
				function(error) {
					$rootScope.PopUpTitle = 'Mobile Verification Error';
					$rootScope.PopUpDescription =
						'We are not able to send you a verification code, please try again later.';
					$rootScope.PopError = true;
				}
			);
		};

		//HS:Set
		vm.saveMobile = function(mobileValid, form) {
			vm.mobileUsed = false;
			vm.mobileSubmitted = true;
			if (mobileValid) {
				vm.submitMobileText = 'Please wait...';
				var mobileObj = {
					UserId: vm.id,
					MobileNumber: vm.accountInfoMobile.Number
				};
				userInfo
					.saveMobile({
						obj: mobileObj
					})
					.$promise.then(function(data) {
						if (data.Break_Msg) {
							vm.mobileUsed = true;
						} else if (data.User) {
							vm.setaccountInfoMobileModel(data.User);
							vm.toggler('mobileNumberToggle');
							vm.mobileSubmitted = false;
							$state.go('verify');
						}
						vm.submitMobileText = 'Update';
						vm.emailSent = false;
					});
			}
		};
		//HS:Update scope
		vm.setaccountInfoMobileModel = function(data) {
			//HS:Must create copies of initial unedited values from server
			$rootScope.User.IsEmailVerified = data.IsEmailVerified;
			$rootScope.User.IsPhoneVerified = data.IsPhoneVerified;
			$rootScope.User.MobileNumber = data.MobileNumber;
			vm.accountInfoMobile.Number = data.MobileNumber;
			vm.accountInfoMobile.NumberCopy = angular.copy(
				vm.accountInfoMobile.Number
			);
		};
		/*HS:Password
        -----------*/
		vm.accountInfoPasswords = {};
		//HS:Must create copies of null values
		vm.accountInfoPasswords.currentCopy = vm.accountInfoPasswords.newCopy = vm.accountInfoPasswords.confirmCopy = null;

		vm.passwordReset = false;
		$scope.$watch('vm.passwordReset', function() {
			vm.passwordText = vm.passwordReset
				? 'Password has been reset'
				: 'Edit to change';
		});
		//HS:Compare & update/reject
		vm.savePassword = function(passwordForm) {
			vm.passwordSubmitted = true;
			vm.passwordSent = false;
			if (passwordForm.$valid) {
				vm.passwordSent = true;
				var passwordObj = {
					UserId: vm.id,
					Password: vm.accountInfoPasswords.new,
					currentPassword: vm.accountInfoPasswords.current
				};
				userInfo
					.savePassword({
						obj: passwordObj
					})
					.$promise.then(function(data) {
						if (data.Msg) {
							vm.incorrectPassword = true;
							passwordForm.$setPristine();
						}
						if (data.Break_Msg) {
							vm.duplicatePassword = true;
							passwordForm.$setPristine();
						}
						if (data.User) {
							vm.passwordReset = true;
							vm.accountInfoPasswords.current = vm.accountInfoPasswords.new = vm.accountInfoPasswords.confirm = null;
							$timeout(function() {
								vm.passwordReset = vm.passwordSubmitted = false;
							}, 2000);
							vm.toggler('passwordToggle');
						}
						vm.passwordSent = false;
					});
			}
		};

		vm.uploadFiles = function() {
			if (vm.myFile != null && vm.nationalIdFileBase64 !== null) {
				var file = vm.myFile;

				if (file.size > 50000000) {
					vm.fileError = 'Image must be at most 50 MB in size.';
					vm.showFileErrorPopup = true;
					return;
				}

				vm.showInProgressPopup = true;
				
				var params = {
					Key: file.name,
					ContentType: file.type,
					FileContents: vm.nationalIdFileBase64,
					ServerSideEncryption: 'AES256',
					ACL: 'public-read'
				};

				FilesService.uploadToS3(params).$promise.then(
					function (data) {  vm.updateNId(data.url) },
					function (err) {
						console.log(err);
						vm.fileError = 'Something went wrong.';
						vm.showFileErrorPopup = true;
					});
			} else {
				vm.fileError = 'Please select an image to upload.';
				vm.showFileErrorPopup = true;
			}
		};

		vm.dismissFileUploadedPopup = function() {
			vm.showFileUploadedPopup = false;
		};

		vm.updateNId = function(fileLink) {
			userInfo
				.uploadId({
					NIdLink: fileLink
				})
				.$promise.then(
					function(data) {
						if (data) {
							vm.filePopupTitle = 'File Uploaded';
							vm.filePopupBody = 'File uploaded successfuly.';
							vm.fileUploaded = true;
							vm.showInProgressPopup = false;
							vm.showFileUploadedPopup = true;
							vm.NationalId.link = data.nationalIdLink + '?r=' + Math.random();
							vm.resetNationalId();
						}
					},
					function(error) {
						vm.filePopupTitle = 'Failed';
						vm.filePopupBody = 'Failed to upload file!';
						vm.fileUploaded = false;
						vm.showInProgressPopup = false;
						vm.showFileUploadedPopup = true;
						vm.resetNationalId();
					}
				);
		};

		/**---------------------------------------------------------------------------------------------------------------------------------*/
		//HS:Reset fields functions
		vm.resetEmailField = function() {
			fieldsService.reset(
				[[vm.accountInfoEmail, []]],
				vm.emailAddressToggle
			);
			vm.mobileSubmitted = vm.emailUsed = false;
		};

		vm.resetMobileField = function() {
			fieldsService.reset(
				[[vm.accountInfoMobile, []]],
				vm.mobileNumberToggle
			);
			vm.mobileSubmitted = vm.mobileUsed = false;
		};

		vm.resetPasswordFields = function() {
			fieldsService.reset(
				[[vm.accountInfoPasswords, []]],
				vm.passwordToggle
			);
			vm.passwordSubmitted = vm.incorrectPassword = vm.duplicatePassword = false;
		};

		vm.resetNationalId = function() {
			vm.myFile = null;
			vm.nationalIdToggle = false;
		}
	}

})();
