(function () {
    'use strict';

    angular
        .module('eltazkaraApp')
        .factory('SubCategory', SubCategory);

    SubCategory.$inject = ['$resource'];

    function SubCategory($resource) {
        var service = $resource(window.backendUrl + 'Categories', {}, {
            'getSubCategory': {
                method: 'POST', isArray: false,
                url: window.backendUrl + "Categories/GetSubCategory"
            }
        });

        return service;
    }
})();
