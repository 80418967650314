(function () {
    'use strict';
     
    angular
        .module('eltazkaraApp')
        .factory('Performer', Performer);

    Performer.$inject = ['$resource'];

    function Performer($resource) {
        var service = $resource(window.backendUrl + 'Performers', {}, {
            'getPerformer': {
                method: 'POST', isArray: false,
                url: window.backendUrl + "Performers/GetPerformer"
            },
            'getPerformerPerformances': {
                method: 'POST', isArray: false,
                url: window.backendUrl + "Performers/GetPerformerPerformances"
            }
        });

        return service;
    }
})();
