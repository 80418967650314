(function () {
  'use strict';

  angular
      .module('eltazkaraApp')
      .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider.state('search', {
      parent: 'app',
      url: '/search',
      views: {
        'content@': {
            templateUrl: 'components/search/search.html',
          controller: 'SearchController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('global');
        }]
      }
    });
  }
})()
