(function () {
    'use strict';

    angular
        .module('eltazkaraApp')
        .factory('Category', Category);

    Category.$inject = ['$resource'];

    function Category($resource) {
        var service = $resource(window.backendUrl + 'Categories', {}, {
            'getAllCategories': {
                method: 'POST', isArray: true,
                url: window.backendUrl + "Categories/GetAllCategories"
            }
        });

        return service;
    }
})();
