(function () {
  'use strict';

  angular
      .module('eltazkara.config')
      .config(localStorageConfig);

  localStorageConfig.$inject = [
    '$localStorageProvider',
    '$sessionStorageProvider',
    'localStorageServiceProvider',
    'cfpLoadingBarProvider'
  ];

  function localStorageConfig($localStorageProvider, $sessionStorageProvider, localStorageServiceProvider, cfpLoadingBarProvider) {
    $localStorageProvider.setKeyPrefix('eltazkara-');
    $sessionStorageProvider.setKeyPrefix('eltazkara-');

    localStorageServiceProvider.setPrefix('eltazkara');
    localStorageServiceProvider.setStorageType('sessionStorage');

    cfpLoadingBarProvider.includeSpinner = false;
  }
})();
