(function () {
  'use strict';

  angular
      .module('eltazkaraApp')
      .constant('VERSION', "1.0.0")
      .constant('DEBUG_INFO_ENABLED', false)
      .constant('FB_APP_ID', "460375385657393")
      .constant('BACKEND_URL', "https://tixawybackend.azurewebsites.net/api/")
      .constant('APP_URL', "https://tixawy.com/")
      .constant('PHANTOM_URL', "https://tixawy.com:8888/")
      .config(["BACKEND_URL", function (BACKEND_URL) {
        window.backendUrl = BACKEND_URL;
        window.events = BACKEND_URL + "events";
        window.venues = BACKEND_URL + "venues";
        window.search = BACKEND_URL + "Home/Search?Key=";
        window.performences = BACKEND_URL + "performences";
        window.performers = BACKEND_URL + "performers";
        window.locations = BACKEND_URL + "locations";
      }]);
})();
