(function () {
    'use strict';

    angular
        .module('eltazkara.social')
        .factory('Facebook', Facebook);

    Facebook.$inject = ['$rootScope', '$resource'];

    function Facebook($rootScope, $resource) {
        var service = $resource(window.backendUrl + 'Account/FacebookLogin', {}, {
            'post': {
                method: 'POST', params: { accessToken: '@accessToken', client_id: '@client_id', client_secret: '@client_secret' },
                interceptor: {
                    response: function (response) {
                        return response;
                    }
                }
            }
        });
        return service;
    }
})();
