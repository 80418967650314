(function () {
    'use strict';

    angular
        .module('eltazkaraApp')
        .controller('BoughtTicketsController', BoughtTicketsController);

    BoughtTicketsController.$inject = [
      '$http',
      '$state',
      '$rootScope',
      '$stateParams',
      'NgTableParams',
      'User'
    ];


    function BoughtTicketsController($http, $state, $rootScope, $stateParams, NgTableParams, User) {
        var vm = this;
        vm.showPoper = false;
        vm.Receipt;

        if($rootScope.User)
            vm.id = $rootScope.User.UserId;
        else
            $state.go("home");

        vm.dataLength_pinding = 0;
        vm.dataLength_Active = 0;
        vm.dataLength_History = 0;
        vm.dataLength_Refund = 0;
        vm.PageIndex = 1;
        vm.CountPerPage_Pending = 5;
        vm.CountPerPage_Active = 5;
        vm.CountPerPage_History = 5;
        vm.CountPerPage_Refunded = 5;
        vm.disablePending = false;
        vm.disableActive = false;
        vm.disableHistory = false;
        vm.disableRefunded = false;
        vm.BOUGHT_TICKETS_HISTORY = [];    //all bought tickets
        vm.ACTIVE_BOUGHT_TICKETS = [];     //date of event still available
        vm.PENDING_BOUGHT_TICKETS = [];    //if respond == 3 =>pending
        vm.REFUNDED_BOUGHT_TICKETS = [];    //if refunded == 4 =>refunded
        vm.hasPendingTickets = false;
        vm.hasActiveTickets = false;
        vm.hasHistoryTickets = false;
        vm.hasRefundedTickets = false;
        vm.ticketsLoading = false;

        vm.boughtTickets = function () {
            vm.ticketsLoading = true;
            User.GetBoughtTickets({ "Id": vm.id })
                .$promise.then(function (data) {
                    vm.ticketsLoading = false;
                    if (data) {
                        if (data.PendingTickets.length > 0) vm.hasPendingTickets = true;
                        if (data.ActiveTickets.length > 0) vm.hasActiveTickets = true;
                        if (data.HistoryTickets.length > 0) vm.hasHistoryTickets = true;
                        if (data.RefundedTickets.length > 0) vm.hasRefundedTickets = true;

                        for (var i = 0; i < data.PendingTickets.length; i++) {
                            var tempItem = {
                                "bought": data.PendingTickets[i].BoughtTikets,
                                "PricePerTicket": data.PendingTickets[i].PricePerTicket,
                                "OrderTotal": data.PendingTickets[i].OrderTotal,
                                "Loans": data.PendingTickets[i].Loans,
                                "row": data.PendingTickets[i].Row,
                                "section":data.PendingTickets[i].Section,
                                "block": data.PendingTickets[i].Block,
                                "Type": data.PendingTickets[i].Type,
                                "performence": data.PendingTickets[i].Performance,
                                "PerformanceId": data.PendingTickets[i].PerformanceId,
                                "EventId": data.PendingTickets[i].EventId,
                                "fullDate": data.PendingTickets[i].FullDate,
                                "year": data.PendingTickets[i].Year,
                                "day": data.PendingTickets[i].Day,
                                "month": data.PendingTickets[i].Month,
                                "dayName": data.PendingTickets[i].DayName,
                                "time": data.PendingTickets[i].Time,
                                "eventName": data.PendingTickets[i].Event,
                                "Respond": data.PendingTickets[i].PaymentResponse,
                                "CreatedOn": data.PendingTickets[i].CreatedOn,
                                "FullOrderDate": Date.parse(data.PendingTickets[i].FullOrderDate),
                                "OrderRef": data.PendingTickets[i].OrderRef,
                                "Num_Performances_InEvent": data.PendingTickets[i].Num_Performances_InEvent

                            };
                            vm.PENDING_BOUGHT_TICKETS[i] = tempItem;
                            vm.dataLength_pinding = data.PendingTickets.length;
                            vm.customConfigParamsPindingTable = createUsingFullOptionsPindingTable();
                        }
                        for (var i = 0; i < data.ActiveTickets.length; i++) {
                            var tempItem = {
                                "OrderId": data.ActiveTickets[i].OrderId,
                                "bought": data.ActiveTickets[i].BoughtTikets,
                                "OrderTotal": data.ActiveTickets[i].OrderTotal,
                                "PricePerTicket": data.ActiveTickets[i].PricePerTicket,
                                "Loans": data.ActiveTickets[i].Loans,
                                "row": data.ActiveTickets[i].Row,
                                "section": data.ActiveTickets[i].Section,
                                "block": data.ActiveTickets[i].Block,
                                "Type": data.ActiveTickets[i].Type,
                                "performence": data.ActiveTickets[i].Performance,
                                "PerformanceId": data.ActiveTickets[i].PerformanceId,
                                "EventId": data.ActiveTickets[i].EventId,
                                "fullDate": data.ActiveTickets[i].FullDate,
                                "year": data.ActiveTickets[i].Year,
                                "day": data.ActiveTickets[i].Day,
                                "month": data.ActiveTickets[i].Month,
                                "dayName": data.ActiveTickets[i].DayName,
                                "time": data.ActiveTickets[i].Time,
                                "eventName": data.ActiveTickets[i].Event,
                                "Respond": data.ActiveTickets[i].Respond,
                                "CreatedOn": data.ActiveTickets[i].CreatedOn,
                                "FullOrderDate": Date.parse(data.ActiveTickets[i].FullOrderDate),
                                "Num_Performances_InEvent": data.ActiveTickets[i].Num_Performances_InEvent
                            };
                            vm.ACTIVE_BOUGHT_TICKETS[i] = tempItem;
                            vm.dataLength_Active = data.ActiveTickets.length;
                            vm.customConfigParamsActiveTable = createUsingFullOptionsActiveTable();

                        }

                        for (var i = 0; i < data.HistoryTickets.length; i++) {
                            var tempItem = {
                                "OrderId": data.HistoryTickets[i].OrderId,
                                "bought": data.HistoryTickets[i].BoughtTikets,
                                "OrderTotal": data.HistoryTickets[i].OrderTotal,
                                "PricePerTicket": data.HistoryTickets[i].PricePerTicket,
                                "Loans": data.HistoryTickets[i].Loans,
                                "row": data.HistoryTickets[i].Row,
                                "section": data.HistoryTickets[i].Section,
                                "block": data.HistoryTickets[i].Block,
                                "Type": data.HistoryTickets[i].Type,
                                "performence": data.HistoryTickets[i].Performance,
                                "PerformanceId": data.HistoryTickets[i].PerformanceId,
                                "EventId": data.HistoryTickets[i].EventId,
                                "fullDate": data.HistoryTickets[i].FullDate,
                                "year": data.HistoryTickets[i].Year,
                                "day": data.HistoryTickets[i].Day,
                                "month": data.HistoryTickets[i].Month,
                                "dayName": data.HistoryTickets[i].DayName,
                                "time": data.HistoryTickets[i].Time,
                                "eventName": data.HistoryTickets[i].Event,
                                "Respond": data.HistoryTickets[i].Respond,
                                "CreatedOn": data.HistoryTickets[i].CreatedOn,
                                "FullOrderDate": Date.parse(data.HistoryTickets[i].FullOrderDate),
                                "Num_Performances_InEvent": data.HistoryTickets[i].Num_Performances_InEvent
                            };
                            vm.BOUGHT_TICKETS_HISTORY[i] = tempItem;
                            vm.dataLength_History = data.HistoryTickets.length;

                            vm.customConfigParamsHistoryTable = createUsingFullOptionsHistoryTable();
                        }

                        for (var i = 0; i < data.RefundedTickets.length; i++) {
                            var tempItem = {
                                "OrderId": data.RefundedTickets[i].OrderId,
                                "bought": data.RefundedTickets[i].BoughtTikets,
                                "OrderTotal": data.RefundedTickets[i].OrderTotal,
                                "Refunded": data.RefundedTickets[i].Refunded,
                                "PricePerTicket": data.RefundedTickets[i].PricePerTicket,
                                "Loans": data.RefundedTickets[i].Loans,
                                "row": data.RefundedTickets[i].Row,
                                "section": data.RefundedTickets[i].Section,
                                "block": data.RefundedTickets[i].Block,
                                "Type": data.RefundedTickets[i].Type,
                                "performence": data.RefundedTickets[i].Performance,
                                "PerformanceId": data.RefundedTickets[i].PerformanceId,
                                "EventId": data.RefundedTickets[i].EventId,
                                "fullDate": data.RefundedTickets[i].FullDate,
                                "year": data.RefundedTickets[i].Year,
                                "day": data.RefundedTickets[i].Day,
                                "month": data.RefundedTickets[i].Month,
                                "dayName": data.RefundedTickets[i].DayName,
                                "time": data.RefundedTickets[i].Time,
                                "eventName": data.RefundedTickets[i].Event,
                                "Respond": data.RefundedTickets[i].Respond,
                                "CreatedOn": data.RefundedTickets[i].CreatedOn,
                                "FullOrderDate": Date.parse(data.RefundedTickets[i].FullOrderDate),
                                "Num_Performances_InEvent": data.RefundedTickets[i].Num_Performances_InEvent
                            };
                            vm.REFUNDED_BOUGHT_TICKETS[i] = tempItem;
                            vm.dataLength_Refund = data.RefundedTickets.length;
                            vm.customConfigParamsRefundTable = createUsingFullOptionsRefundTable();
                        }
                    }
                }, function (error) {
                    vm.ticketsLoading = false;
                })
        }

        vm.boughtTickets();

        vm.showReceipt = function (Orderid) {
            vm.showPoper = true;
            User.GetReceipt({ "Id": Orderid })
             .$promise.then(function (data) {
                 if (data) {

                     var tempItem = {
                         "PricePerTicket": data.PricePerTicket,
                         "boughtTikets": data.boughtTikets,
                         "ServicesFees": data.ServicesFees,
                         "DeliveryFees": data.DeliveryFees,
                         "Total": data.Total,
                         "GrandTotal": data.GrandTotal,
                         "Fees":data.Fees,
                         "Loans": data.Loans,
                         "Perfomance": data.Perfomance,
                         "Reference":data.Ref
                     };
                     vm.Receipt = tempItem;

                 }
             }, function (error) { })
        }
        vm.proceedToPayment = function (Orderid) {
            location.href = "#/payment_details/" + Orderid;
        }
        vm.loadMorePending = function () {
            if (vm.CountPerPage_Pending < vm.dataLength_pinding) {
                vm.customConfigParamsPindingTable.count(vm.CountPerPage_Pending + 5);
                vm.CountPerPage_Pending += 5;
            }
            else
                vm.disablePending = true;

        }
        vm.loadMoreActive = function () {
            if (vm.CountPerPage_Active < vm.dataLength_Active) {
                vm.customConfigParamsActiveTable.count(vm.CountPerPage_Active + 5);
                vm.CountPerPage_Active += 5;
            }
            else
                vm.disableActive = true;

        }
        vm.loadMoreHistory = function () {
            if (vm.CountPerPage_History < vm.dataLength_History) {
                vm.customConfigParamsHistoryTable.count(vm.CountPerPage_History + 5);
                vm.CountPerPage_History += 5;
            }
            else
                vm.disableHistory = true;
        }
        vm.loadMoreRefunded = function () {
            if (vm.CountPerPage_Refunded < vm.dataLength_Refund) {
                vm.customConfigParamsRefundTable.count(vm.CountPerPage_Refunded + 5);
                vm.CountPerPage_Refunded += 5;
            }
            else {
                vm.disableRefunded = true;
            }
                
        }

        function createUsingFullOptionsPindingTable() {
            var initialParams = {
                count: vm.CountPerPage_Pending, // initial page size
                sorting: { FullOrderDate: "desc" }
            };
            var initialSettings = {
                counts: [],
                dataset: vm.PENDING_BOUGHT_TICKETS
            };
            return new NgTableParams(initialParams, initialSettings);
        }

        function createUsingFullOptionsActiveTable() {
            var initialParams = {
                count: vm.CountPerPage_Active, // initial page size
                sorting: { FullOrderDate: "desc" }
            };
            var initialSettings = {
                counts: [],
                dataset: vm.ACTIVE_BOUGHT_TICKETS
            };
            return new NgTableParams(initialParams, initialSettings);
        }

        function createUsingFullOptionsHistoryTable() {
            var initialParams = {
                count: vm.CountPerPage_History, // initial page size
                sorting: { FullOrderDate: "desc" }
            };
            var initialSettings = {
                counts: [],
                dataset: vm.BOUGHT_TICKETS_HISTORY
            };
            return new NgTableParams(initialParams, initialSettings);
        }

        function createUsingFullOptionsRefundTable() {
            var initialParams = {
                count: vm.CountPerPage_Refunded // initial page size
            };
            var initialSettings = {
                counts: [],
                dataset: vm.REFUNDED_BOUGHT_TICKETS
            };
            return new NgTableParams(initialParams, initialSettings);
        }
    }
})();
