(function () {
    'use strict';

    angular
        .module('eltazkara.auth')
        .factory('Auth', Auth);

    Auth.$inject = ['$rootScope', '$state', '$stateParams', '$sessionStorage', '$q', 'Principal', 'AuthServerProvider', 'Account', 'Activate', '$http', '$cookies', '$filter', 'fb'];

    function Auth($rootScope, $state, $stateParams, $sessionStorage, $q, Principal, AuthServerProvider, Account, Activate, $http, $cookies, $filter, fb) {
        var service = {
            activateAccount: activateAccount,
            authorize: authorize,
            login: login,
            getPreviousState: getPreviousState,
            logout: logout,
            loginWithToken: loginWithToken,
            storeAuthenticationToken: storeAuthenticationToken,
            resetPreviousState: resetPreviousState,
            storePreviousState: storePreviousState,
            updateAccount: updateAccount,
            isAuthenticated: isAuthenticated
        };

        return service;

        function activateAccount(key, callback) {
            var cb = callback || angular.noop;

            return Activate.get(key,
                function (response) {
                    return cb(response);
                },
                function (err) {
                    return cb(err);
                }.bind(this)).$promise;
        }

        function authorize(force) {
            var authReturn = Principal.identity(force).then(authThen);

            return authReturn;

            function authThen() {
                var isAuthenticated = Principal.isAuthenticated();
                $rootScope.Date = $filter('date')(new Date(), 'yyyyMMddHHmmss');

                if ($cookies.get('expires') != null) {
                    var expiryDate = $filter('date')(new Date($cookies.get('expires')), 'yyyyMMddHHmmss');
                    if ($rootScope.Date.toString() > expiryDate) {
                        $cookies.remove('expires');
                        logout();
                    }
                }

                if (isAuthenticated) {
                    $rootScope.loggedOut = false;
                } else {
                    $rootScope.loggedOut = true;
                }

                if ($sessionStorage.IsGoVerifiy) {
                    if ($sessionStorage.IsGoVerifiy == true) {
                        $sessionStorage.IsGoVerifiy = false;
                        $state.go('verify');
                    }
                }

                // an authenticated user can't access to login and register pages
                if (isAuthenticated && $rootScope.toState.parent === 'account'
                    && ($rootScope.toState.name === 'login' || $rootScope.toState.name === 'register'
                        || $rootScope.toState.name === 'social-auth')) {
                    $state.go('home');
                }

                // recover and clear previousState after external login redirect (e.g. oauth2)
                if (isAuthenticated && !$rootScope.fromState.name && getPreviousState()) {
                    var previousState = getPreviousState();
                    resetPreviousState();
                    $state.go(previousState.name, previousState.params);
                }
            }
        }

        function login(credentials) {
            AuthServerProvider.login(credentials);
        }

        function loginWithToken(jwt, rememberMe) {
            return AuthServerProvider.loginWithToken(jwt, rememberMe);
        }

        function storeAuthenticationToken(jwt, rememberMe) {
            if (rememberMe) {
                $localStorage.authenticationToken = jwt;
            } else {
                $sessionStorage.authenticationToken = jwt;
            }

            window.location.reload(true);
        }

        function logout() {
            $rootScope.loggedOut = true;
            AuthServerProvider.logout();
            Principal.authenticate(null);
            $rootScope.User = null;
            fb.logout();
            $state.go('home');
        }

        function updateAccount(account, callback) {
            var cb = callback || angular.noop;

            return Account.save(account,
                function () {
                    return cb(account);
                },
                function (err) {
                    return cb(err);
                }.bind(this)).$promise;
        }

        function getPreviousState() {
            var previousState = $sessionStorage.previousState;
            return previousState;
        }

        function resetPreviousState() {
            delete $sessionStorage.previousState;
        }

        function storePreviousState(previousStateName, previousStateParams) {
            var previousState = { "name": previousStateName, "params": previousStateParams };
            $sessionStorage.previousState = previousState;
        }

        function isAuthenticated() {
            return Principal.isAuthenticated();
        }
    }
})();
