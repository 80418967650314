(function () {
    'use strict';

    angular
        .module('eltazkaraApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('performer', {
            parent: 'app',
            url: '/performer/:id',
            views: {
                'content@': {
                    templateUrl: 'components/performers/performer.html',
                    controller: 'PerformerController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                }]
            }
        });
    }
})();
