(function () {
  'use strict';

  angular
      .module('eltazkaraApp')
      .controller('TicketsController', TicketsController);

  TicketsController.$inject = [
    '$http',
    '$rootScope',
    'localStorageService'
  ];

  function TicketsController($http, $rootScope, localStorageService) {
      var self = this;

      self.TicketPerPage = 10;
      self.CurrentPage = 1;
      self.TicketsList = [];

      self.getItems = function () {
          $http({
              method: "POST",
              url: window.backendUrl + "Performances/GetEventTickets",
              headers: { 'Content-Type': 'application/json' },
              data: JSON.stringify({ "PageIndex": self.CurrentPage, "CountPerPage": self.TicketPerPage })
          }).then(function mySucces(response) {
              if (response.data.length == 0) {
              }
              else {
                  var TicketData;
                  var TicketsCount = response.data.length;
                  for (var i = 0; i < Ticketscount; i++) {
                      TicketData = JSON.parse(response.data[i].FullJSON).Object;
                      if (TicketData) {
                          var tempItem = {
                              "id": TicketData.TicketId,
                              "DateSold": TicketData.DateSold,
                              "Seat": TicketData.Seat,
                              "Status": TicketData.Status,
                              "TicketGroupId": TicketData.TicketGroupId,
                          };
                          self.TicketsList.push(tempItem);
                      }
                  }
              }
          }, function myError(response) {
          });
      }
      self.getItems();
  }

})();
