(function () {
    'use strict';

    angular
        .module('eltazkaraApp')
        .factory('Balance', Balance);

    Balance.$inject = ['$resource'];

    function Balance($resource) {
        var service = $resource(window.backendUrl + 'Users', {}, {
            'balanceSheet': {
                method: 'POST', isArray: false,
                url: window.backendUrl + "Users/BalanceSheet"
            }
        });
        return service;
    }
})();
