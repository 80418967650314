(function () {
  'use strict';
  
  angular
      .module('eltazkara.utils')
      .directive('infiniteScroll', infiniteScroll)
  
  infiniteScroll.$inject = [];
  
  function infiniteScroll() {
    return {
      restrict: 'A',
      scope: {
        addMore: '&addMore',
        disableLoadMore: '=disableLoadMore'
      },
      link: infiniteScrollLinker
    }
  }

  function infiniteScrollLinker(scope, element, attrs) {
    var lastLeftLocation = 0;
    element.scroll(function (e) {
      if (scope.disableLoadMore)
        return;

      var $width = element.outerWidth()
      var $scrollWidth = element[0].scrollWidth;
      var $scrollLeft = element.scrollLeft();

      var delta = $scrollWidth - $width - $scrollLeft;
      if ((delta >= 0 && delta <= 5) || (delta <= 0 && delta <= 5) &&
          lastLeftLocation <= $scrollLeft) {
        scope.addMore();
      }

      lastLeftLocation = e.currentTarget.scrollLeft;
    });
  }
})();