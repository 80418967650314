(function () {
    'use strict';
    angular
        .module('eltazkaraApp')
        .factory('Filters', Filters);
    Filters.$inject = ['$resource'];
    function Filters($resource) {
        var service = $resource(window.backendUrl, {}, {
            //HS:Categories
            'getCategoryFilters': {
                method: 'POST', isArray: false,
                url: window.backendUrl + "Categories/GetFilters"
            },
            //HS:Events
            'getEventFilters': {
                method: 'POST', isArray: false,
                url: window.backendUrl + "Events/GetFilters"
            },
            //HS:Performers
            'getPerformerFilters': {
                method: 'POST', isArray: false,
                url: window.backendUrl + "Performers/GetFilters"
            },
            //HS:Venues
            'getVenueFilters': {
                method: 'POST', isArray: false,
                url: window.backendUrl + "Venues/GetFilters"
            }
        });
        return service;
    }
})();
