(function () {
    'use strict';

    angular
        .module('eltazkara.auth')
        .factory('AuthServerProvider', AuthServerProvider);

    AuthServerProvider.$inject = ['$http', '$rootScope', '$localStorage', '$sessionStorage', '$q', 'Account', '$state', '$cookies'];

    function AuthServerProvider($http, $rootScope, $localStorage, $sessionStorage, $q, Account, $state, $cookies) {
        var service = {
            getToken: getToken,
            login: login,
            loginWithToken: loginWithToken,
            storeAuthenticationToken: storeAuthenticationToken,
            logout: logout
        };

        return service;

        function getToken() {
            return $localStorage.authenticationToken || $sessionStorage.authenticationToken;
        }

        function login(credentials) {

            //3alala ya radwan mattshalsh
            var loginUrl = window.backendUrl.replace("api/", "token");
            delete $localStorage.authenticationToken;
            delete $sessionStorage.authenticationToken;
            return $http.post(loginUrl, credentials.Body, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
                .success(authenticateSuccess).
                error(function (error) {
                    $rootScope.loginClick = false;
                    $rootScope.LoginSubmit = "Login";
                    $rootScope.userLoginError = true;
            });


            function authenticateSuccess(data, status, headers, config) {
                var authorizationData = {
                    token: data.access_token,
                    userName: data.userName,
                    refreshToken: data.refresh_token,
                    useRefreshTokens: true
                };
                $cookies.put('expires',data[".expires"]);
                $rootScope.userLoginError = false;
                $rootScope.loggedOut = false;

                service.storeAuthenticationToken(authorizationData, credentials.rememberMe);

                if($rootScope.redirectVerifyAfterLogin == true) {
                    $sessionStorage.IsGoVerifiy = true;
                }

                window.location.reload(true);

                return jwt;
            }
        }

        function loginWithToken(jwt, rememberMe) {
            var deferred = $q.defer();

            if (angular.isDefined(jwt)) {
                this.storeAuthenticationToken(jwt, rememberMe);
                deferred.resolve(jwt);
            } else {
                deferred.reject();
            }

            return deferred.promise;
        }

        function storeAuthenticationToken(jwt, rememberMe) {
            if (rememberMe) {
                $localStorage.authenticationToken = jwt;
            } else {
                $sessionStorage.authenticationToken = jwt;
            }
        }

        function logout() {
            $http({
                method : "GET",
                url : window.backendUrl + "Account/Logout"
            })
            .then(function (response) {})
            .catch(function (error) {
                console.log(error)
            })
            .finally(function() {
                delete $localStorage.authenticationToken;
                delete $sessionStorage.authenticationToken;
                window.location.reload(true);
            });
        }
    }
})();
