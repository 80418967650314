(function () {
    'use strict';

    angular
        .module('eltazkara.auth')
        .factory('Account', Account);

    Account.$inject = ['$resource', '$rootScope' , '$injector'];

    function Account($resource, $rootScope, $injector) {
        var service = $resource(window.backendUrl + 'Users/getUserData', {}, {
            'get': {
                method: 'GET', isArray: false,
                url: window.backendUrl + "Users/getUserData",
                interceptor: {
                    response: function (response) {
                        if(response.data.Name){
                           if(response.data.IsBlocked == true || response.data.Deleted == true)
                           {
                              var auth = $injector.get('Auth');
                              auth.logout();
                           }
                           else {
                              $rootScope.User = response.data;
                           }
                        }
                        else if(response.data.redirect && $rootScope.loggedOut != true)
                        {
                            var auth = $injector.get('Auth');
                            auth.logout();
                        }
                        return response;
                    }
                }
            }
        });

        return service;
    }
})();
