(function () {
    'use strict';

    var dependencies = [

    ];

    angular.module('eltazkara.utils', dependencies);

    angular
        .module('eltazkaraApp')
        .directive('spinner', renderSpinner);

    //HS:Ticket group price directive
    function renderSpinner() {
        return {
            restrict: 'E',
            template:  
                '<div class="{{give}}" style="text-align:{{push}};background:white">' +
                    '<img style="width:50px" src="../../assets/images/spinner.gif" />' +
                '</div>',
            link: function (scope, elem, attr) {
                scope.push = attr.push;
                scope.give = attr.give;
            }
        };
    }

})();