(function () {
  'use strict';

  angular
      .module('eltazkara.social')
      .factory('Google', Google);

  Google.$inject = ['$resource'];

  function Google($resource) {
    var service = $resource(window.backendUrl + 'google', {}, {
      'post': {
        method: 'POST', params: {accessToken: '@accessToken'},
        interceptor: {
          response: function (response) {
            // expose response
            return response;
          }
        }
      }
    });

    return service;
  }
})();
