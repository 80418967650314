(function () {
    'use strict';

    angular
        .module('eltazkara.interceptor', [])
        .factory('httpInterceptor', ['$q', '$injector', '$timeout', '$rootScope', function ($q, $injector, $timeout, $rootScope) {
            return {
                request: function (request) {
                    return request;
                },
                response: function (response) {
                    if (!response.config.override) 
                        response.config.override = true;

                    var http = $injector.get('$http');

                    if (http.pendingRequests.length < 1) {
                        $timeout(function () {
                            $rootScope.htmlReady();
                        }, 1000);
                    }

                    return response
                },
                responseError: function (response) {
                    //var faviconRequested = response.config.url.indexOf("favicon") > -1;
                    var componentRequested = response.config.url.indexOf("Events") > -1 ||
                        response.config.url.indexOf("Performances") > -1 ||
                        response.config.url.indexOf("Performers") > -1 ||
                        response.config.url.indexOf("Venues") > -1;
                    if (response.status === 404 && componentRequested) {
                        $injector.get("$state").go('404');
                        return $q.reject(response);
                    }
                    return $q.reject(response);
                }
            };
        }])
        .config(['$httpProvider', function ($httpProvider) {
            $httpProvider.interceptors.push('httpInterceptor');
        }]);
})();
//$rootScope.$broadcast('loading:hide')