
(function () {
    'use strict';

    angular
        .module('eltazkaraApp')
        .controller('NotFoundController', NotFoundController);

    NotFoundController.$inject = [
        '$rootScope'
    ];

    function NotFoundController($rootScope) {
        var vm = this;
    }
})();
