(function() {
	'use strict';

	angular.module('eltazkara.utils').directive('file', file);

	file.$inject = [];

	function file() {
		return {
			restrict: 'AE',
			scope: {
				file: '@',
				trigger: '&'
			},
			link: fileLinker
		};
	}

	function fileLinker(scope, el, attrs) {
		el.bind('change', function(event) {
			var files = event.target.files;
			scope.$parent.$parent.vm.myFile = files[0];
			scope.files = files;
			scope.$parent.files = files;
			if (angular.isDefined(scope.trigger)) {
				scope.trigger({ files: scope.files });
			}
			scope.$apply();
		});
	}
})();
