(function () {
    'use strict';

    angular
        .module('eltazkaraApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('register', {
            parent: 'app',
            url: '/users/profile',
            views: {
                'content@': {
                    templateUrl: 'components/users/profile.html',
                    controller: 'ProfileController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                }]
            }
        }).state('account', {
            parent: 'app',
            url: '/users/account',
            views: {
                'content@': {
                    templateUrl: 'components/users/account.html',
                    controller: 'AccountController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                }]
            }
        }).state('boughtTickets', {
            parent: 'app',
            url: '/users/orders',
            views: {
                'content@': {
                    templateUrl: 'components/users/boughtTickets.html',
                    controller: 'BoughtTicketsController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                }]
            }
        }).state('soldTickets', {
            parent: 'app',
            url: '/users/tickets_for_sale',
            views: {
                'content@': {
                    templateUrl: 'components/users/soldTickets.html',
                    controller: 'SoldTicketsController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                }]
            }
        }).state('order', {
            parent: 'app',
            url: '/users/order',
            views: {
                'content@': {
                    templateUrl: 'components/users/order.html',
                    controller: 'OrderController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                }]
            }
        })
        .state('verify', {
            parent: 'app',
            url: '/users/verify',
            views: {
                'content@': {
                    templateUrl: 'components/users/verifyRegistration.html',
                    controller: 'VerifyController',
                    controllerAs: 'vm'
                }
            }
        }).state('thanks', {
            parent: 'app',
            url: '/users/emailVerifiy/:code',
            views: {
                'content@': {
                    templateUrl: 'components/users/thanks.html',
                    controller: 'ThanksController',
                    controllerAs: 'vm'
                }
            }
        }).state('balanceSheet', {
            parent: 'app',
            url: '/users/balanceSheet',
            views: {
                'content@': {
                    templateUrl: 'components/users/balanceSheet.html',
                    controller: 'balanceSheetController',
                    controllerAs: 'vm'
                }
            }
        })
    }
})();
