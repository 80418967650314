(function () {
  'use strict';

  angular
      .module('eltazkara.footer')
      .controller('FooterController', FooterController);

  FooterController.$inject = [
    '$rootScope'
  ];

  function FooterController($rootScope) {
    var footerC = this;
    footerC.date = new Date();
  }


})();
