(function () {
    'use strict';

    angular
        .module('eltazkaraApp')
        .controller('CategoriesController', CategoriesController);

    CategoriesController.$inject = [
      'Category'
    ];

    function CategoriesController(Category) {
        var vm = this;
        vm.categoriesLoading = false;
        vm.CategoriesList = [];
        vm.getPerformences = function () {
            vm.categoriesLoading = true;
            Category.getAllCategories({})
                .$promise.then(function (data) {
                    vm.categoriesLoading = false;
                    if (data) {
                        var dataList = data;
                        for (var i = 0 ; i < dataList.length ; i++) {
                            var tempItem = {
                                "categoryId": dataList[i].CategoryId,
                                "Name": dataList[i].ParentCategory,
                                "Theme": dataList[i].Theme,
                                "ChildCategories": dataList[i].ChildCategories,
                            };
                            vm.CategoriesList[i] = tempItem;
                        }
                    }
                }, function (error) { vm.categoriesLoading = false; })
        }
        vm.getPerformences();
    }

})();
