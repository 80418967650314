(function () {
    'use strict';
    angular
        .module('eltazkaraApp')
        .controller('PerformerController', PerformerController);
    PerformerController.$inject = [
      '$rootScope',
      'NgTableParams',
      '$stateParams',
      '$window',
      'Filters',
      'Performer',
      'User'
    ];
    function PerformerController($rootScope, NgTableParams, $stateParams, $window, Filters, Performer, User,PerformerEvents ) {
        var vm = this;
        vm.id = $stateParams.id;
        vm.requestSize = 5; //HS:initial list size
        vm.countPerLoad = 5; //HS:increase per step
        vm.listOffset = 0; //HS:load more start index
        vm.filters = {
            "CategoryId": -1,
            "EventId": -1,
            "VenueId": -1
        };
        vm.months = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];
        vm.customConfigParams = createUsingFullOptions();
        function createUsingFullOptions() {
            var initialParams = {
                count: vm.requestSize
            };
            var initialSettings = {
                counts: [],
                dataset: vm.list
            };
            return new NgTableParams(initialParams, initialSettings);
        }
        vm.loadingMore;
        vm.loadMoreText = "Load More";
        //HS:Performer data functions
        vm.list = [];
        vm.hasPerformances = false;
        vm.hasPerformancesFiltered = false;
        vm.allCategories = [];
        vm.allEvents = [];
        vm.allEventsLoading = false;
        vm.allVenues = [];
        //HS:Item
        vm.getPerformer = function () {
            vm.performerLoading = true;
            Performer.getPerformer({ "id": vm.id })
                .$promise.then(function (data) {
                    vm.performerLoading = false;
                    if (data.PerformerDetails) {
                        var dataList = data.PerformerDetails;
                        var tempItem = {
                            "name": dataList[0].Name,
                            "Description": dataList[0].Description,
                            "Image": dataList[0].Image,
                            "Facebook": dataList[0].Facebook,
                            "Website": dataList[0].Website,
                            "Views": dataList[0].Views,
                        };
                        vm.performerDetails = tempItem;
                    }
                }, function (error) { vm.performerLoading = false; })
        }
        //HS:Datalist
        vm.getPerformances = function (more) {
            var listLimit = vm.countPerLoad;
            if (more) {
                vm.loadingMore = true;
                vm.loadMoreText = "Please wait...";
            }
            else {
                vm.listOffset = 0;
                vm.requestSize = vm.countPerLoad;
                vm.allEventsLoading = true;
            }
            Performer.getPerformerPerformances({
                "Id": vm.id,
                "Offset": vm.listOffset,
                "Limit": listLimit,
                "Filters": vm.filters
            })
            .$promise.then(function (data) {
                vm.allEventsLoading = false;
                if (data.DataList) {
                    if (data.DataList.length > 0) {
                        vm.hasPerformances = vm.hasPerformancesFiltered = true;
                    }
                    else {
                        if (data.Filtered) {
                            vm.hasPerformancesFiltered = false;
                        }
                    }
                    var dataList = data.DataList;
                    vm.list = more ? vm.list : [];
                    vm.lastCall = data.LastCall;
                    for (var i = 0; i < dataList.length; i++) {
                        var tempItem = {
                            "Name": dataList[i].Name,
                            "EventId": dataList[i].EventId,
                            "EventName": dataList[i].EventName,
                            "Description": dataList[i].Description,
                            "Performence": dataList[i].Performence,
                            "PerformenceId": dataList[i].PerformenceId,
                            "venue": dataList[i].venue,
                            "venueid": dataList[i].venueid,
                            "startDate": dataList[i].startDate,
                            "EndDate": dataList[i].EndDate,
                            "dayName": dataList[i].dayName,
                            "day": dataList[i].day,
                            "month": dataList[i].month,
                            "year": dataList[i].year,
                            "time": dataList[i].time,
                            "period": dataList[i].period,
                            "avTickets": dataList[i].AvailableTicket,
                            "IsClosedBooking": dataList[i].IsClosedBooking,
                            "IsBuyBlocked": dataList[i].IsBuyBlocked,
                            "IsSellBlocked":dataList[i].IsSellBlocked,
                            "Num_Performances_InEvent": dataList[i].Num_Performances_InEvent,
                        };
                        vm.list.push(tempItem);
                    }
                    //HS:Filters firing
                    vm.getAllFilters();
                }
                if (more) {
                    vm.loadingMore = false;
                    vm.loadMoreText = "Load More";
                }
                vm.customConfigParams = createUsingFullOptions();
            }, function (error) { vm.allEventsLoading = false; })
        }
        //HS:Filters
        vm.getAllFilters = function () {
            Filters.getPerformerFilters({
                "id": vm.id
            })
            .$promise.then(function (data) {
                if (data) {
                    vm.allCategories = data.PerformerCategories;
                    if (vm.allCategories.length > 1) {
                        vm.allCategories.unshift({
                            "CategoryId": -1,
                            "CategoryName": "All"
                        });
                    }
                    else if (vm.allCategories.length === 1) {
                        vm.allCategories[0].CategoryId = -1;
                    }
                    else {
                        vm.allCategories.unshift({
                            "CategoryId": -1,
                            "CategoryName": "No categories"
                        });
                    }
                    vm.allEvents = data.PerformerEvents;
                    if (vm.allEvents.length > 1) {
                        vm.allEvents.unshift({
                            "EventId": -1,
                            "EventName": "All"
                        });
                    }
                    else if (vm.allEvents.length === 1) {
                        vm.allEvents[0].EventId = -1;
                    }
                    else {
                        vm.allEvents.unshift({
                            "EventId": -1,
                            "EventName": "No events"
                        });
                    }
                    vm.allVenues = data.PerformerVenues;
                    if (vm.allVenues.length > 1) {
                        vm.allVenues.unshift({
                            "VenueId": -1,
                            "VenueName": "All"
                        });
                    }
                    else if (vm.allVenues.length === 1) {
                        vm.allVenues[0].VenueId = -1;
                    }
                    else {
                        vm.allVenues.unshift({
                            "VenueId": -1,
                            "VenueName": "No venues"
                        });
                    }
                }
                vm.customConfigParams = createUsingFullOptions();
            }, function (error) { })
        }
        //HS:Functions firing
        //HS:Item
        vm.getPerformer();
        //HS:Datalist
        vm.getPerformances(false);
        /**---------------------------------------------------------------------------------------------------------------------------------*/
        vm.loadMore = function () {
            vm.listOffset = vm.requestSize;
            vm.requestSize += vm.countPerLoad;
            vm.customConfigParams.count(vm.requestSize);
            vm.getPerformances(true);
        }
        //HS:sell button click action
        vm.trySell = function (PerformanceId) {
            if ($rootScope.User && $rootScope.User.Email) {
                User.IsUserBlockedOrDeleted({ "UserId": $rootScope.User.UserId })
                .$promise.then(function (data) {
                    if (data.status == "true") {
                        location.href = "#/performance/" + PerformanceId + "/sell";
                    }
                    else {
                        location.href = "#/";
                        $rootScope.PopUpTitle = "Blocked User";
                        $rootScope.PopUpDescription = "You don't have access please contact with Admin";
                        $rootScope.showPopup = true;
                    }
                }, function (error) { })
            }
            else {
                vm.toggleLoginPanel();
            }
        }
        vm.toggleLoginPanel = function () {
          $window.scrollTo(0, 0);
          $rootScope.loginPanelActive = !$rootScope.loginPanelActive;
          document.getElementById("userLoginEmail").value = "";
          document.getElementById("userLoginPassword").value = "";
          document.getElementById("userLoginRemember").checked = false;
          $rootScope.userLoginError = false;
          vm.User = {};
          vm.errors = {};
        };

    }
})();
