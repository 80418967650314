(function () {
    'use strict';
    angular
        .module('eltazkaraApp')
        .controller('PerformanceController', PerformanceController)
        .filter("MoreQuantityFilter", MoreQuantityFilter);
    PerformanceController.$inject = [
      '$scope',
      '$rootScope',
      '$stateParams',
      '$location',
      '$window',
      '$filter',
      'Performance',
      'NgTableParams',
      'PerformersService',
      'TicketsService',
      '$state',
      'User',
      '$cookies'
    ];
    function PerformanceController($scope, $rootScope, $stateParams, $location, $window, $filter, Performance, NgTableParams, PerformersService, TicketsService, $state, User,$cookies) {
        var vm = this;
        vm.PerformanceLoading = false;
        vm.data = [];
        vm.id = $stateParams.id;
        vm.listSize = 5;
        vm.CountPerPage = 5;
        vm.moreTickets;
        vm.totalQuantitiesTrimmed = [];
        //$locationProvider.html5Mode(true).hashPrefix('!');
        /**-----------------------------------------------------------------------------------------------------------------------------------
        Performance data functions
        -----------------------------------------------------------------------------------------------------------------------------------**/
        vm.PerformanceDetails = {};
        //Item
        if ($cookies.get('refresh') != null) {
            $cookies.remove('refresh');
            window.location.reload(true);
        }
        vm.getPerformance = function () {
            vm.PerformanceLoading = true;
            Performance.getPerformance({ "Id": vm.id, "CountPerPage": vm.CountPerPage, "PageIndex": vm.PageIndex })
            .$promise.then(function (data) {
                vm.PerformanceLoading = false;
                if (data) {
                    var dataList = data.AllPerformances;
                    if (dataList.length > 0) {
                        vm.PerformanceDetails = dataList[0];
                        //console.log(vm.PerformanceDetails);
                        if (vm.PerformanceDetails.AvailableTickets == 0) {
                            vm.showPerformanceData = false;
                            vm.dontShow = false;
                        } else {
                            vm.dontShow = true;

                        }
                    }
                }
            }, function (error) { vm.PerformanceLoading = false; })
        }
        //Performers (name tags)
        vm.performers = [];
        vm.getPerformers = function () {
            PerformersService.getPerformers({ "PerformanceId": vm.id })
             .$promise.then(function (data) {
                 if (data) {
                     vm.performers = data;
                 }
             });
        }
        //Link to performer page
        vm.goPerformer = function (performerId) {
            $location.path('performer/' + performerId);
        }
        //Datalist
        vm.getTickets = function () {
            TicketsService.getTicketsList({ "PerformanceId": vm.id })
             .$promise.then(function (data) {
                 if (data.TicketList) {
                     vm.data = data.TicketList;
                     vm.totalQuantities = data.TotalQuantities;
                     if (vm.totalQuantities.length > 6) {
                         for (var qty = 0; qty < 5; qty++) {
                             vm.totalQuantitiesTrimmed.push(vm.totalQuantities[qty]);
                         }
                         vm.totalQuantitiesTrimmed.push(vm.totalQuantities[5] + "+");
                     }
                     else {
                         vm.totalQuantitiesTrimmed = vm.totalQuantities;
                     }
                     vm.totalTickets = data.TotalTickets;
                     vm.minPrice = data.MinPrice;
                     vm.customConfigParams = new NgTableParams(
                     {
                         count: vm.listSize
                     },
                     {
                         counts: [],
                         filterOptions: { filterFilterName: "MoreQuantityFilter" },
                         dataset: vm.data
                     });
                     vm.moreTickets = vm.CountPerPage < vm.data.length;
                     vm.bindFilters();
                     if (vm.customConfigParams.total() < 1) {
                         vm.showPerformanceData = false;
                     }
                     else {
                         vm.showPerformanceData = true;
                     }

                 }
             });
        }
        /**-----------------------------------------------------------------------------------------------------------------------------------
        Functions firing
        -----------------------------------------------------------------------------------------------------------------------------------**/
        //Item
        vm.getPerformance();
        //Performers (name tags)
        vm.getPerformers();
        //Datalist
        vm.getTickets();

        /**---------------------------------------------------------------------------------------------------------------------------------*/
        vm.loadMore = function () {
            if (vm.moreTickets) {
                vm.listSize += vm.CountPerPage;
                vm.customConfigParams.count(vm.listSize);
            }
            vm.moreTickets = vm.moreTickets && vm.data.length > vm.customConfigParams.count();
            vm.customConfigParams.reload();
        }
        vm.bindFilters = function () {
            $scope.$watch('[vm.ticketgroupPriceFilter, vm.ticketgroupQuantityFilter]', function () {
                vm.customConfigParams.filter().TicketPrice = vm.ticketgroupPriceFilter;
                vm.customConfigParams.filter().Quantities = vm.ticketgroupQuantityFilter;
                vm.customConfigParams.reload();
            });
        };

        vm.openPhotoSwipe = function () {
            var pswpElement = document.querySelectorAll('.pswp')[0];
            // build items array
            var items = [
                {
                    src: vm.PerformanceDetails.SeatmapImage2,
                    w: 1960,
                    h: 1268,
                    title: "swipe to exit"
                }
            ];
            var options = {
                history: false,
                focus: false,
                showAnimationDuration: 0,
                hideAnimationDuration: 0
            };
            var gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, items, options);
            gallery.init();
        };

        vm.buyPerformanceTickets = function (ticket, quantity) {
            if ($rootScope.User && $rootScope.User.Email) {
                User.IsUserBlockedOrDeleted({ "UserId": $rootScope.User.UserId })
                .$promise.then(function (data) {
                    if (data.status == "true") {
                        Performance.isTicketGroupCancelled(ticket)
                        .$promise.then(function(data) {
                            if (!data.status) {
                                $rootScope.PopUpTitle = "Ticket Not Found";
                                $rootScope.PopUpDescription = "This ticket may have been cancelled or no longer exists.";
                                $rootScope.showPopup = true;
                                location.href = "#/";
                                return;
                            }
                            $state.go('payment', { id: vm.id, ticket: ticket, performanceDetails: vm.PerformanceDetails });
                        }, function(error) {
                            $rootScope.PopUpTitle = "Error";
                            $rootScope.PopUpDescription = "Something went wrong. Please try again or contact the administration.";
                            $rootScope.showPopup = true;
                            window.location.reload(true);
                        });
                    }
                    else {
                        location.href = "#/";
                        $rootScope.PopUpTitle = "Blocked User";
                        $rootScope.PopUpDescription = "You don't have access. Please contact the administration.";
                        $rootScope.showPopup = true;
                    }
                }, function (error) { })
            }
            else {
                vm.toggleLoginPanel();
            }
        };

        //HS:sell button click action
        vm.trySell = function () {
            if ($rootScope.User && $rootScope.User.Email) {
                User.IsUserBlockedOrDeleted({ "UserId": $rootScope.User.UserId })
                .$promise.then(function (data) {
                    if (data.status == "true") {
                        location.href = "#/performance/" + vm.id + "/sell";
                    }
                    else {
                        location.href = "#/";
                        $rootScope.PopUpTitle = "Blocked User";
                        $rootScope.PopUpDescription = "You don't have access please contact with Admin";
                        $rootScope.showPopup = true;
                    }
                }, function (error) { })
            }
            else {
                vm.toggleLoginPanel();
            }
        }

        vm.toggleLoginPanel = function () {
            $window.scrollTo(0, 0);
            $rootScope.loginPanelActive = !$rootScope.loginPanelActive;
            document.getElementById("userLoginEmail").value = "";
            document.getElementById("userLoginPassword").value = "";
            document.getElementById("userLoginRemember").checked = false;
            $rootScope.userLoginError = false;
            vm.User = {};
            vm.errors = {};
        };



    }
    function MoreQuantityFilter() {
        return function (data, selectedTicket) {
            return (data || []).filter(function (curTicket) {
                var qCriteria = true; var pCriteria = true;
                var length = curTicket.Quantities.length;
                var curPrice = curTicket.TicketPrice;
                var curQuantity = curTicket.Quantities;
                var maxNum = curQuantity.sort()[length - 1];
                var selectedPrice = selectedTicket.TicketPrice;
                var selectedQuantity = selectedTicket.Quantities;
                if (selectedQuantity) {
                    if (selectedQuantity.indexOf("+") > -1) {
                        selectedQuantity = 6;
                        qCriteria = qCriteria && maxNum > selectedQuantity;
                    }
                    else {
                        selectedQuantity = parseInt(selectedQuantity);
                        qCriteria = curQuantity.indexOf(selectedQuantity) > -1;
                    }
                }
                if (selectedPrice) {
                    var pCriteria = curPrice <= selectedPrice;
                }
                return qCriteria && pCriteria;
            });
        };
    }

})();
