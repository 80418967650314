(function () {
    'use strict';

    angular
        .module('eltazkaraApp')
        .factory('Venue', Venue);

    Venue.$inject = ['$resource'];

    function Venue($resource) {
        var service = $resource(window.backendUrl + 'Venues', {}, {
            'getVenue': {
                method: 'POST', isArray: false,
                url: window.backendUrl + "Venues/GetVenues"
            },
            'getVenuePerformances': {
                method: 'POST', isArray: false,
                url: window.backendUrl + "Venues/GetVenuePerformances"
            }
        });

        return service;
    }
})();
