(function () {
  'use strict';

  angular
      .module('eltazkara.utils')
      .directive('scroll', file);

  file.$inject = [];

  function file() {
    return {
      restrict: 'A',
      scope: {
        scrollTo: '@'
      },
      link: scrollLinker
    }
  };

  function scrollLinker(scope, $elm, attrs) {
    $elm.on('click', function() {                                                    
        $('html,body').animate({scrollTop: $(scope.scrollTo).offset().top }, "slow");
    });
  }

})();
