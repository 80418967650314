(function () {
    'use strict';

    angular
        .module('eltazkaraApp')
        .factory('PaymentService', PaymentService);

    PaymentService.$inject = ['$resource'];

    function PaymentService($resource) {
        var service = $resource(window.backendUrl + '/Orders', {}, {
            'generateOrder': {
                method: 'POST', isArray: false,
                url: window.backendUrl + "Orders/OnCreateOrder"
            },
            'getOrder': {
                method: 'POST', isArray: false,
                url: window.backendUrl + "Orders/GetOrder"
            },
            'getUserCards': {
                method: 'POST', isArray: true,
                url: window.backendUrl + "Users/GetUserCards"
            },
            'getPaymentSuccess': {
                method: 'POST', isArray: false,
                url: window.backendUrl + "Orders/PaymentSuccessResponse"
            },
            'reviewOrder': {
                method: 'POST', isArray: false,
                url: window.backendUrl + "Orders/ReviewOrder"
            },
            'getPerformance': {
                method: 'POST', isArray: false,
                url: window.backendUrl + "Sell/GetPerformance"
            },
            'saveSellRequest': {
                method: 'POST', isArray: false,
                url: window.backendUrl + "Sell/SaveSellRequest"
            },
            'payOrder': {
                method: 'POST', isArray: false,
                url: window.backendUrl + "Orders/OnPay"
            },
            'cancelOrder': {
                method: 'POST', isArray: false,
                url: window.backendUrl + "Orders/AutoCancelOrders"
            },
            'AddCreditCard': {
                method: 'POST', isArray: false,
                url: window.backendUrl + "Users/AddCreditCard"
            },
            'checkOrderPayment':{
                method: 'POST', isArray: false,
                url: window.backendUrl + "Orders/CheckOrderPayment"
            },
            'getDeliveryCities': {
                method: 'GET', isArray: true,
                url: window.backendUrl + "Locations/GetDeliveryCities"
            },
            'getCouriers': {
                method: 'GET', isArray: true,
                url: window.backendUrl + "Locations/getCouriers"
            }
        });
        return service;
    }
})();
