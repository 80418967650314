(function () {
  'use strict';

  angular
      .module('eltazkaraApp')
      .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider.state('social-login', {
      url: '/login',
      views: {
        'content@': {
          templateUrl: 'social/social-login.html',
          controller: 'SocialLoginController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('global');
        }]
      }
    });
  }
})();
