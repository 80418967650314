(function () {
    'use strict';

    angular
    .module('eltazkaraApp')
    .controller('balanceSheetController', balanceSheetController);

    balanceSheetController.$inject = [
    '$http',
    '$rootScope',
    'NgTableParams',
    'User',
    '$state'
    ];

    function balanceSheetController($http, $rootScope, NgTableParams, User, $state) {
        var vm = this;
    };
})();