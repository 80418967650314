(function () {
    'use strict';

    angular
        .module('eltazkaraApp')
        .factory('CategoryEvents', CategoryEvents);

    CategoryEvents.$inject = ['$resource'];

    function CategoryEvents($resource) {
        var service = $resource(window.backendUrl + 'Categories', {}, {
            'getcategoryEvents': {
                method: 'POST', isArray: false,
                url: window.backendUrl + "Categories/GetCategoryPerformances"
            },
            'getFeaturedEvent': {
                method: 'POST', isArray: false,
                url: window.backendUrl + "Categories/GetFeaturedEvent"
            },
            'GetSubCategoryTheme': {
                method: 'POST', isArray: false,
                url: window.backendUrl + "Categories/GetSubCategoryTheme"
            }
        });

        return service;
    }
})();
