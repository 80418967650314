(function () {
  'use strict';

  angular
      .module('eltazkara.auth')
      .factory('Activate', Activate);

  Activate.$inject = ['$resource'];

  function Activate($resource) {
    var service = $resource(window.backendUrl + 'activate', {}, {
      'get': {method: 'GET', params: {}, isArray: false}
    });

    return service;
  }
})();
