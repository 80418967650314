(function () {
  'use strict';

  angular
      .module('eltazkaraApp')
      .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
      $stateProvider.state('venue', {
    parent: 'app',
    url: '/venue/:id',
    views: {
      'content@': {
          templateUrl: 'components/venues/venue.html',
        controller: 'VenueController',
        controllerAs: 'vm'
      }
    },
    resolve: {
      translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
        $translatePartialLoader.addPart('global');
      }]
    }
  });
  }
})();
