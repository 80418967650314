(function () {
    'use strict';

    angular
        .module('eltazkara.auth')
        .factory('User', User);

    User.$inject = ['$resource', '$rootScope'];

    function User($resource, $rootScope) {
        var service = $resource(window.backendUrl + 'users/:login', {}, {
            'query': { method: 'GET', isArray: true },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    $rootScope.user = data;
                    return data;
                }
            },
            'save': { method: 'POST' },
            'update': {
                method: 'PUT',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    $rootScope.user = data;
                    return data;
                }
            },
            'delete': { method: 'DELETE' },
        });
        return service;
    }
})();
