(function() {
	'use strict';
	angular.module('eltazkaraApp').factory('userInfo', userInfo);
	userInfo.$inject = ['$resource'];
	function userInfo($resource) {
		var service = $resource(
			window.backendUrl + 'Users',
			{},
			{
				getProfileInfoDetails: {
					method: 'POST',
					isArray: false,
					url: window.backendUrl + 'Users/GetProfileInfo'
				},
				saveProfileInfoDetails: {
					method: 'POST',
					isArray: false,
					url: window.backendUrl + 'Users/UpdateProfileInfo'
				},
				getLocation: {
					method: 'POST',
					isArray: false,
					url: window.backendUrl + 'Locations/GetLocation'
				},
				saveLocation: {
					method: 'POST',
					isArray: false,
					url: window.backendUrl + 'Locations/SaveLocation'
				},
				getCompanyInfoDetails: {
					method: 'POST',
					isArray: false,
					url: window.backendUrl + 'Users/GetCompanyInfo'
				},
				saveCompanyInfoDetails: {
					method: 'POST',
					isArray: false,
					url: window.backendUrl + 'Users/UpdateCompanyInfo'
				},
				getAllCountries: {
					method: 'GET',
					isArray: true,
					url: window.backendUrl + 'Locations/GetCountries'
				},
				getCompanyCountries: {
					method: 'GET',
					isArray: true,
					url: window.backendUrl + 'Locations/GetCompanyCountries'
				},
				getCountryCities: {
					method: 'POST',
					isArray: false,
					url: window.backendUrl + 'Locations/GetCountryCities'
				},
				getEmail: {
					method: 'POST',
					isArray: false,
					url: window.backendUrl + 'Users/GetEmail'
				},
				saveEmail: {
					method: 'POST',
					isArray: false,
					url: window.backendUrl + 'Users/SaveEmail'
				},
				getMobile: {
					method: 'POST',
					isArray: false,
					url: window.backendUrl + 'Users/GetMobile'
				},
				saveMobile: {
					method: 'POST',
					isArray: false,
					url: window.backendUrl + 'Users/SaveMobile'
				},
				savePassword: {
					method: 'POST',
					isArray: false,
					url: window.backendUrl + 'Users/SavePassword'
				},
				SendSMSVerifiy: {
					method: 'POST',
					isArray: false,
					url: window.backendUrl + 'Users/SendVerificationMsg'
				},
				uploadId: {
					method: 'POST',
					isArray: false,
					url: window.backendUrl + 'Users/UpdateNationalId'
				},

				getWhereToRedirectAfterVerify: function() {
					return this.wehreToRedirect;
				},
				setWhereToRedirectAfterVerify: function(value) {
					this.wehreToRedirect = value;
				}
			}
		);
		service.data = '#/users/account';
		service.getData = function() {
			return this.data;
		};
		service.sendData = function(data) {
			this.data = data;
			//$rootScope.$broadcast('data_shared');
		};
		service.Mailsent = false;
		service.getMailSent = function() {
			return this.Mailsent;
		};
		service.setMailSent = function(data) {
			this.Mailsent = data;
			//$rootScope.$broadcast('data_shared');
		};
		service.smsSent = false;
		service.getSmsSent = function() {
			return this.smsSent;
		};
		service.setSmsSent = function(data) {
			this.smsSent = data;
			//$rootScope.$broadcast('data_shared');
		};
		return service;
	}
})();
