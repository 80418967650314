

(function () {
  'use strict';

  angular
      .module('eltazkara.auth')
      .service('SearchData', SearchData);

  function SearchData() {
    var service = {};
    var data;
    service.setData = function (data) {
      self.data = data;
    }
    service.getData = function () {
      return self.data;
    }
    return service;
  }
})();

