(function () {
    'use strict';

    angular
        .module('eltazkaraApp')
        .factory('PerformersService', PerformersService);

    PerformersService.$inject = ['$resource'];

    function PerformersService($resource) {
        var service = $resource(window.backendUrl + 'Performances', {}, {
            'getPerformers': {
                method: 'POST', isArray: false,
                url: window.backendUrl + "Performances/GetPerformers"
            }
        });

        return service;
    }
})();
