(function () {
    'use strict';
    angular
        .module('eltazkaraApp')
        .factory('fieldsService', fields);
    function fields() {
        return {
            //HS:Reset all ng models in the given form
            reset: function (objs, toggled) {
                for (var i = 0; i < objs.length; i++) {
                    var obj = objs[i][0];
                    if (obj instanceof Object) {
                        var objProps = objs[i][1];

                        if (!toggled) {
                            if (objProps.length > 0) {
                                for (var i = 0; i < objProps.length; i++) {
                                    obj[objProps[i]] = obj[objProps[i] + 'Copy'];
                                }
                            }
                            else {
                                for (var prop in obj) {
                                    if (prop.indexOf('Copy') > -1) continue;

                                    obj[prop] = obj[prop + 'Copy'];
                                }
                            }
                        }
                        else {
                            for (var prop in obj) {
                                if (prop.indexOf('Copy') > -1) continue;
                                obj[prop + 'Copy'] = obj[prop];
                            }
                        }
                    }
                }
            }
        }
    }
})();
