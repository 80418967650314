(function() {
	'use strict';
    
	angular
		.module('eltazkaraApp')
		.controller('ProfileController', ProfileController);

	ProfileController.$inject = [
		'$rootScope',
		'userInfo',
		'$state',
		'$timeout',
		'fieldsService'
	];

	function ProfileController(
		$rootScope,
		userInfo,
		$state,
		$timeout,
		fieldsService
	) {
		var vm = this;
		if ($rootScope.User) vm.id = $rootScope.User.UserId;
		else $state.go('home');
		vm.submitProfileText = vm.submitLocationText = vm.submitCompanyText ='Update';
		vm.profileSubmitted = vm.locationSubmitted = vm.companySubmitted = false;
		vm.profileSent = vm.locationSent = vm.companySent = false;
        vm.profileInfoDetails = {};
		vm.genders = [['none', 'Not set'], [true, 'Male'], [false, 'Female']];

		vm.getProfileInfo = function() {
			userInfo.getProfileInfoDetails({ UserId: vm.id }).$promise.then(function(data) {
                if (data.User) {
                    vm.setProfileInfoModel(data.User);
                    vm.updateDays(vm.profileInfoDetails.Month);
                }
            });
		};

		vm.getProfileInfo();

        vm.saveProfileInfo = function(profileInfoValid) {
			vm.profileSubmitted = true;

			if (profileInfoValid) {
				vm.submitProfileText = 'Please wait...';
				vm.profileSent = true;

				vm.profileInfoDetails.NameCopy = vm.profileInfoDetails.GenderCopy = vm.profileInfoDetails.DayCopy 
                    = vm.profileInfoDetails.MonthCopy = vm.profileInfoDetails.YearCopy = null;

				vm.profileInfoDetails.Gender =vm.profileInfoDetails.Gender === 'none'
                    ? null
                    : vm.profileInfoDetails.Gender;

				vm.profileInfoDetails.UserId = vm.id;
				vm.profileInfoDetails.Birthdate = vm.profileInfoDetails.Year === 'YYYY'
                    ? null
                    : (vm.profileInfoDetails.Birthdate =
                            vm.profileInfoDetails.Year +
                            '-' +
                            vm.profileInfoDetails.Month +
                            '-' +
                            vm.profileInfoDetails.Day);
                            
				userInfo.saveProfileInfoDetails({ profileInfoDetails: vm.profileInfoDetails }).$promise.then(function(data) {
                    if (data.User) {
                        vm.setProfileInfoModel(data.User);
                        vm.submitProfileText = 'Update';
                        vm.profileSubmitted = false;
                        $rootScope.User.Name = data.User.Name.split(' ')[0];
                        vm.toggler('profileInfo');
                    }

                    vm.profileSent = false;
                });
			}
		};

		vm.setProfileInfoModel = function(data) {
			vm.profileInfoDetails.Name = data.Name;
			vm.profileInfoDetails.NameCopy = angular.copy(vm.profileInfoDetails.Name);
			vm.profileInfoDetails.Gender = data.Gender;
			vm.profileInfoDetails.GenderCopy = angular.copy(vm.profileInfoDetails.Gender);

			switch (vm.profileInfoDetails.Gender) {
				case true:
					vm.GenderLiteral = 'Male';
					break;
				case false:
					vm.GenderLiteral = 'Female';
					break;
				case 'none':
					vm.GenderLiteral = 'Not set';
			}

			vm.profileInfoDetails.Day = data.Day ? data.Day : 'DD';
			vm.profileInfoDetails.DayCopy = angular.copy(vm.profileInfoDetails.Day);
			vm.profileInfoDetails.Month = data.Month ? data.Month : 'MM';
			vm.profileInfoDetails.MonthCopy = angular.copy(vm.profileInfoDetails.Month);
			vm.profileInfoDetails.Year = data.Year ? data.Year : 'YYYY';
			vm.profileInfoDetails.YearCopy = angular.copy(vm.profileInfoDetails.Year);

			vm.profileInfoDetails.Birthdate = data.Year
				? vm.combineDate(vm.profileInfoDetails.Day, vm.profileInfoDetails.Month, vm.profileInfoDetails.Year)
				: null;
		};

		vm.checkValidity = function(e) {
			if (e.which === 50) 
                e.preventDefault();
		};

        vm.combineDate = function(d, m, y) {
			var day = d < 10 ? '0' + d : d;
			var month = m < 10 ? '0' + m : m;
			var year = y;
			return day + '/' + month + '/' + year;
		};

        vm.countries = [];
		vm.homeCities = [];
		vm.companyCities = [];
		vm.homeCountriesLoading = vm.companyCountriesLoading = vm.homeCitiesLoading = vm.compnayCitiesLoading = false;
		vm.homeCityLoading, vm.companyCityLoading;

        vm.getHomeCountries = function() {
			vm.homeCountriesLoading = true;
			vm.homeLocationCountryLiteral = vm.homeLocationCityLiteral = 'Please wait...';
			vm.homeCountries = [];

			vm.homeCountries.push({
				CountryId: 0,
				CountryName: 'Please wait...'
			});

			userInfo.getAllCountries().$promise.then(function(data) {
				vm.homeCountriesLoading = false;

				if (data) {
					vm.homeCountries = [];
					vm.homeCountries = data;

					vm.homeCountries.unshift({
						CountryId: 0,
						CountryName: 'None'
					});

					if (vm.homeLocationInfoDetails.CountryId == 0)
						vm.homeLocationCountryLiteral = 'Not set';
					else {
						for (var i = 0; i < vm.homeCountries.length; i++) {
							if (vm.homeCountries[i].CountryId == vm.homeLocationInfoDetails.CountryId) {
								vm.homeLocationCountryLiteral = vm.homeCountries[i].CountryName;
								break;
							}
						}
					}

					vm.getHomeCountryCities(false);
				}
			});
		};

        vm.getCompanyCountries = function() {
			vm.companyCountriesLoading = true;
			vm.companyLocationCountryLiteral = vm.companyLocationCityLiteral = 'Please wait...';
			vm.companyCountries = [];

			vm.companyCountries.push({
				CountryId: 0,
				CountryName: 'Please wait...'
			});

			userInfo.getCompanyCountries().$promise.then(function(data) {
				vm.companyCountriesLoading = false;

				if (data) {
					vm.companyCountries = [];
					vm.companyCountries = data;

					vm.companyCountries.unshift({
						CountryId: 0,
						CountryName: 'None'
					});

					if (vm.companyLocationInfoDetails.CountryId == 0)
						vm.companyLocationCountryLiteral = 'Not set';
					else {
						for (var i = 0; i < vm.companyCountries.length; i++) {
							if (vm.companyCountries[i].CountryId == vm.companyLocationInfoDetails.CountryId) {
								vm.companyLocationCountryLiteral = vm.companyCountries[i].CountryName;
								break;
							}
						}
					}

					vm.getCompanyCountryCities(false);
				}
			});
		};

        vm.getHomeCountryCities = function(changed) {
			vm.homeCitiesLoading = true;
			vm.homeCityLoading = false;

			if (vm.homeLocationInfoDetails.CityId > 0 && !vm.locationInfo) {
				vm.homeLocationCityLiteral = 'Please wait...';
				vm.homeCityLoading = true;
			}

			vm.homeCities = [];

			if (changed) {
				if (vm.homeLocationInfoDetails.CountryId <= 0) {
					vm.homeLocationInfoDetails.CityId = null;
					vm.homeLocationInfoDetails.Address = null;
					vm.homeLocationInfoDetails.PostalCode = null;
				} else {
					vm.homeCities.push({
						CityId: 0,
						CityName: 'Please wait...'
					});

					vm.homeLocationInfoDetails.CityId = vm.homeCities[0].CityId;
				}
			}

			userInfo.getCountryCities({ CountryId: vm.homeLocationInfoDetails.CountryId }).$promise.then(function(data) {
                vm.homeCitiesLoading = false;

                if (data.CountryCities) {
                    vm.homeCities = data.CountryCities;

                    if (changed)
                        vm.homeLocationInfoDetails.CityId = vm.homeCities[0].CityId;
                    else {
                        for (var i = 0; i < vm.homeCities.length; i++) {
                            if (vm.homeCities[i].CityId == vm.homeLocationInfoDetails.CityId)
                                vm.homeLocationCityLiteral = vm.homeCities[i].CityName;
                        }
                    }

                    vm.homeCityLoading = false;
                }
            });
		};

        vm.getCompanyCountryCities = function(changed) {
			vm.compnayCitiesLoading = true;
			vm.companyCityLoading = false;

			if (vm.companyLocationInfoDetails.CityId > 0) {
				vm.companyLocationCityLiteral = 'Please wait...';
				vm.companyCityLoading = true;
			}

			vm.companyCities = [];

			if (changed) {
				if (vm.companyLocationInfoDetails.CountryId <= 0) {
					vm.companyLocationInfoDetails.CityId = null;
					vm.companyLocationInfoDetails.Address = null;
					vm.companyLocationInfoDetails.PostalCode = null;
					vm.companyInfoDetails.CompanyName = null;
				} else {
					vm.companyCities.push({
						CityId: 0,
						CityName: 'Please wait...'
					});

					vm.companyLocationInfoDetails.CityId = vm.companyCities[0].CityId;
				}
			}

			userInfo.getCountryCities({ CountryId: vm.companyLocationInfoDetails.CountryId }).$promise.then(function(data) {
                vm.compnayCitiesLoading = false;

                if (data.CountryCities) {
                    vm.companyCities = data.CountryCities;

                    if (changed)
                        vm.companyLocationInfoDetails.CityId = vm.companyCities[0].CityId;
                    else {
                        for (var i = 0; i < vm.companyCities.length; i++) {
                            if (vm.companyCities[i].CityId == vm.companyLocationInfoDetails.CityId) {
                                vm.companyLocationCityLiteral = vm.companyCities[i].CityName;
                            }
                        }
                    }

                    vm.companyCityLoading = false;
                }
            });
		};

		vm.homeLocationInfoDetails = {
			LocationTypeId: 1,
			CountryId: 0,
			CityId: 0
		};

        vm.getHomeLocation = function() {
			userInfo.getLocation({
                UserId: vm.id,
                LocationTypeId: vm.homeLocationInfoDetails.LocationTypeId
            }).$promise.then(function(data) {
                if (data.Location) {
                    vm.setHomeLocationInfoModel(data.Location);
                    vm.toggler('locationInfo');
                }
            });
		};

		vm.getHomeLocation();

		vm.saveHomeLocation = function(homeLocationInfoValid) {
			vm.locationSubmitted = true;

			if (homeLocationInfoValid) {
				vm.submitLocationText = 'Please wait...';
				vm.locationSent = true;
				userInfo.saveLocation({
                    UserId: vm.id,
                    locationInfoDetails: vm.homeLocationInfoDetails
                }).$promise.then(function(data) {
                    if (data.Location) {
                        vm.setHomeLocationInfoModel(data.Location);
                        vm.locationSubmitted = false;
                        vm.submitLocationText = 'Update';
                        vm.toggler('locationInfo');
                    }

                    vm.locationSent = false;
                });
			}
		};

		vm.setHomeLocationInfoModel = function(data) {
			$timeout(function() {
				vm.homeLocationInfoDetails.LocationId = data.LocationId;
				vm.homeLocationInfoDetails.CountryId = data.CountryId > 0 ? data.CountryId : 0;
				vm.homeLocationInfoDetails.CountryIdCopy = angular.copy(vm.homeLocationInfoDetails.CountryId);
				vm.homeLocationInfoDetails.CityId = data.CityId > 0 ? data.CityId : 0;
				vm.homeLocationInfoDetails.CityIdCopy = angular.copy(vm.homeLocationInfoDetails.CityId);

				vm.homeLocationCityLiteral = vm.homeLocationInfoDetails.CityId > 0
                    ? vm.homeLocationCityLiteral
                    : null;

				vm.homeLocationInfoDetails.Address = data.Address;
				vm.homeLocationInfoDetails.AddressCopy = angular.copy(vm.homeLocationInfoDetails.Address);
				vm.homeLocationInfoDetails.PostalCode = data.PostalCode;
				vm.homeLocationInfoDetails.PostalCodeCopy = angular.copy(vm.homeLocationInfoDetails.PostalCode);
			});

			vm.getHomeCountries();
		};

        vm.companyInfoDetails = {};

		vm.companyLocationInfoDetails = {
			LocationTypeId: 2,
			CountryId: 0,
			CityId: 0
		};
		
        vm.getCompanyInfo = function() {
			userInfo.getCompanyInfoDetails({ UserId: vm.id }).$promise.then(function(data) {
                if (data.User)
                    vm.setCompanyInfoModel(data.User);
            });
		};

		vm.getCompanyInfo();
		
        vm.getCompanyLocation = function() {
			userInfo
				.getLocation({
					UserId: vm.id,
					LocationTypeId: vm.companyLocationInfoDetails.LocationTypeId
				})
				.$promise.then(function(data) {
					if (data.Location) {
						vm.setCompanyLocationInfoModel(data.Location);
						vm.toggler('locationInfo');
					}
				});
		};

		vm.getCompanyLocation();

		vm.saveCompanyInfo = function(companyInfoValid) {
			vm.companySubmitted = true;

			if (companyInfoValid) {
				vm.submitCompanyText = 'Please wait...';
				vm.companySent = true;
				vm.companyInfoDetails.UserId = vm.id;

				userInfo.saveCompanyInfoDetails({ companyInfoDetails: vm.companyInfoDetails }).$promise.then(function(data) {
                    if (data.User) {
                        vm.setCompanyInfoModel(data.User);
                        vm.submitCompanyText = 'Update';
                        vm.companyInfo = false;
                    }
                });

				userInfo.saveLocation({
                    UserId: vm.id,
                    locationInfoDetails: vm.companyLocationInfoDetails
                }).$promise.then(function(data) {
                    if (data.Location) {
                        vm.setCompanyLocationInfoModel(data.Location);
                        vm.submitCompanyText = 'Update';
                        vm.companySubmitted = false;
                        vm.companyInfo = false;
                    }

                    vm.companySent = false;
                });
			}
		};

		vm.setCompanyInfoModel = function(data) {
			vm.companyInfoDetails.CompanyName = data.CompanyName;
			vm.companyInfoDetails.CompanyNameCopy = angular.copy(vm.companyInfoDetails.CompanyName);
			vm.companyInfoDetails.CompanyPhone = data.CompanyPhone;
			vm.companyInfoDetails.CompanyPhoneCopy = angular.copy(vm.companyInfoDetails.CompanyPhone);
		};

		vm.setCompanyLocationInfoModel = function(data) {
			$timeout(function() {
				vm.companyLocationInfoDetails.LocationId = data.LocationId;
				vm.companyLocationInfoDetails.CountryId = data.CountryId > 0 ? data.CountryId : 0;
				vm.companyLocationInfoDetails.CountryIdCopy = angular.copy(vm.companyLocationInfoDetails.CountryId);
				vm.companyLocationInfoDetails.CityId = data.CityId > 0 ? data.CityId : 0;
				vm.companyLocationInfoDetails.CityIdCopy = angular.copy(vm.companyLocationInfoDetails.CityId);

				vm.companyLocationCityLiteral = vm.companyLocationInfoDetails.CityId > 0
                    ? vm.companyLocationCityLiteral
                    : null;

				vm.companyLocationInfoDetails.Address = data.Address;
				vm.companyLocationInfoDetails.AddressCopy = angular.copy(vm.companyLocationInfoDetails.Address);
				vm.companyLocationInfoDetails.PostalCode = data.PostalCode;
				vm.companyLocationInfoDetails.PostalCodeCopy = angular.copy(vm.companyLocationInfoDetails.PostalCode);
			});

			vm.getCompanyCountries();
		};

		vm.toggler = function(varName) {
			if (!vm[varName]) {
				vm[varName] = false;
			}

			vm[varName] = !vm[varName];
		};

		vm.regex = {
			name: /\s/g, //A basic name regex (ignored for now)
			mobile: /^(20|0){0,1}1[0-2,5]{1}[0-9]{8}$/
		};

		vm.checkFullBirthdate = function(ctrl) {
			var notFull =
				vm.profileInfoDetails.Day === 'DD' ||
				vm.profileInfoDetails.Month === 'MM' ||
				vm.profileInfoDetails.Year === 'YYYY';

			var clear =
				vm.profileInfoDetails.Day === 'DD' &&
				vm.profileInfoDetails.Month === 'MM' &&
				vm.profileInfoDetails.Year === 'YYYY';

			var dateValid = clear || !notFull;
			ctrl.$setValidity('notFull', dateValid);
		};

		vm.months = {
			0: ['MM', 'MM', '31'],
			1: ['Jan', 1, '31'],
			2: ['Feb', 2, '29'],
			3: ['Mar', 3, '31'],
			4: ['Apr', 4, '30'],
			5: ['May', 5, '31'],
			6: ['Jun', 6, '30'],
			7: ['Jul', 7, '31'],
			8: ['Aug', 8, '31'],
			9: ['Sep', 9, '30'],
			10: ['Oct', 10, '31'],
			11: ['Nov', 11, '30'],
			12: ['Dec', 12, '31']
		};

		vm.days = [];
		vm.years = [];
		var now = new Date();
		vm.thisYear = parseInt(now.getFullYear());
		vm.firstValidYear = vm.thisYear - 100;
		vm.lastValidYear = vm.thisYear - 13;

		for (var i = vm.lastValidYear; i >= vm.firstValidYear; i--) {
			vm.years.push(i);
		}

		vm.years.unshift('YYYY');

		vm.updateDays = function (month) {
			vm.days = ['DD'];

			if (isNaN(month)) {
				for (var i = 1; i < 32; i++) {
					vm.days.push(i);
				}

				vm.profileInfoDetails.Day = vm.days[0];
				return;
			}

			var days = parseInt(vm.months[month][2]);

			for (var i = 1; i <= days; i++) {
				vm.days.push(i);
			}
		};

		vm.resetProfileFields = function() {
			fieldsService.reset([[vm.profileInfoDetails, []]], vm.profileInfo);
		};

		vm.resetHomeLocationFields = function() {
			fieldsService.reset(
				[[vm.homeLocationInfoDetails, []]],
				vm.locationInfo
			);
		};
        
		vm.resetCompanyFields = function() {
			fieldsService.reset(
				[
					[vm.companyInfoDetails, []],
					[vm.companyLocationInfoDetails, []]
				],
				vm.companyInfo
			);
		};
	}
})();
