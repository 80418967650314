(function () {
    'use strict';
    angular
        .module('eltazkaraApp')
        .controller('ThanksController', ThanksController);
    ThanksController.$inject = [
        '$rootScope',
        '$scope',
        '$location',
        '$state',
        '$stateParams',
        '$cookies',
        'User',
        'thankservice',
        'userInfo'
    ];
    function ThanksController($rootScope, $scope, $location, $state, $stateParams, $cookies, User, thankservice, userInfo) {
        var vm = this;
        vm.code = $stateParams.code;

        if (vm.code == "SendVerificationEmail") {
            $cookies.remove('refresh2');
            if ($cookies.get('MailSent') == 'false' || $cookies.get('MailSent') == null) {
                thankservice.SendVerificationEmailToUser({})
                                    .$promise.then(
                                    function (data) {
                                        vm.showButton = false;
                                        if (data.status == "true") {
                                            document.getElementById("result").innerHTML = "Please check your email to verify your account";
                                        }
                                        else
                                            document.getElementById("result").innerHTML = "An error has occured";
                                    },
                                    function (error) {
                                    });
                userInfo.setMailSent(true);
                $cookies.remove('MailSent');
                $cookies.put('MailSent', 'true');
            } else {
                vm.showButton = true;
            };

        }
        else {
            thankservice.VerifiyEmail({ "code": vm.code })
                .$promise.then(
                    function (data) {
                        if (data) {
                            if (data.status === "false")
                                document.getElementById("result").innerHTML = "You must login to verify your account ";
                            else if (data.status === "true") {
                                if ($cookies.get('refresh2') != 'true' || $cookies.get('refresh2') == null) {
                                    $cookies.put('refresh2', 'true');
                                    window.location.reload(true);
                                }

                                document.getElementById("result").innerHTML = "Thank You. Your email has been verified successfully";
                            }
                        }

                    }, function (error) {
                        document.getElementById("result").innerHTML = "There is an error";
                    })
        }

        vm.ResendMail = function () {
            $cookies.remove('MailSent');
            $cookies.put('MailSent', 'false');
            $state.reload();
        };


    };
})();