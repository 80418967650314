(function () {
    'use strict';
    angular
        .module('eltazkaraApp')
        .controller('VenueController', VenueController);
    VenueController.$inject = [
      '$scope',
      '$rootScope',
      'NgTableParams',
      '$stateParams',
      '$window',
      'Filters',
      'Venue',
      'User'
    ];
    function VenueController($scope, $rootScope, NgTableParams, $stateParams, $window, Filters, Venue, User, VenueEvents) {
        var vm = this;
        vm.id = $stateParams.id;
        vm.requestSize = 5; //HS: initial list size
        vm.countPerLoad = 5; //HS: increase per step
        vm.listOffset = 0; //HS: load more start index
        vm.filters = {
            "CategoryId": -1,
            "EventId": -1,
            "PerformerId": -1
        };
        vm.customConfigParams = createUsingFullOptions();
        function createUsingFullOptions() {
            var initialParams = {
                count: vm.requestSize
            };
            var initialSettings = {
                counts: [],
                dataset: vm.list
            };
            return new NgTableParams(initialParams, initialSettings);
        }
        vm.openPhotoSwipe = function () {
            var pswpElement = document.querySelectorAll('.pswp')[0];
            // build items array
            var items = [
                {
                    src: 'assets/images/temp/seating.jpg',
                    w: 1960,
                    h: 1268
                },
                {
                    src: 'assets/images/temp/seating2.jpg',
                    w: 1274,
                    h: 938
                }
            ];
            var options = {
                history: false,
                focus: false,
                showAnimationDuration: 0,
                hideAnimationDuration: 0
            };
            var gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, items, options);
            gallery.init();
        };
        vm.loadingMore;
        vm.loadMoreText = "Load More";
        //HS: Venue data functions
        vm.list = [];
        vm.hasPerformances = false;
        vm.hasPerformancesFiltered = false;
        vm.allCategories = [];
        vm.allEvents = [];
        vm.allEventsLoading = false;
        vm.allVenues = [];
        vm.VenueDetails;
        vm.venueLoading = false;
        //HS: Item
        vm.getVenue = function () {
            vm.venueLoading = true;
            Venue.getVenue({ "Id": vm.id })
            .$promise.then(function (data) {
                vm.venueLoading = false;
                if (data.AllVenues) {
                    var dataList = data.AllVenues;
                    if (dataList.length > 0) {
                        var tempItem = {
                            "name": dataList[0].Name,
                            "Description": dataList[0].Description,
                            "Image": dataList[0].Image,
                            "Image2": dataList[0].Image2,
                            "SeatmapUrl": dataList[0].SeatmapUrl,
                            "Facebook": dataList[0].Facebook,
                            "Website": dataList[0].Website,
                            "Views": dataList[0].Views,
                            "Address": dataList[0].Address,
                            "Long": dataList[0].Long,
                            "Latt": dataList[0].Latt,
                            "Phone": dataList[0].Phone
                        };
                        vm.VenueDetails = tempItem;
                    }
                }
            }, function (error) { vm.venueLoading = false; })
        }
        //HS: Datalist
        vm.getPerformances = function (more) {
            var listLimit = vm.countPerLoad;
            if (more) {
                vm.loadingMore = true;
                vm.loadMoreText = "Please wait...";
            }
            else {
                vm.listOffset = 0;
                vm.requestSize = vm.countPerLoad;
                vm.allEventsLoading = true;
            }
            Venue.getVenuePerformances({
                "Id": vm.id,
                "Offset": vm.listOffset,
                "Limit": listLimit,
                "Filters": vm.filters
            })
            .$promise.then(function (data) {
                vm.allEventsLoading = false;
                if (data.DataList) {
                    if (data.DataList.length > 0) {
                        vm.hasPerformances = vm.hasPerformancesFiltered = true;
                    }
                    else {
                        if (data.Filtered) {
                            vm.hasPerformancesFiltered = false;
                        }
                    }
                    var dataList = data.DataList;
                    vm.list = more ? vm.list : [];
                    vm.lastCall = data.LastCall;
                    for (var i = 0; i < dataList.length; i++) {
                        var tempItem = {
                            "PerformenceId": dataList[i].PerformenceId,
                            "Name": dataList[i].Name,
                            "EventId": dataList[i].EventId,
                            "EventName": dataList[i].EventName,
                            "Description": dataList[i].Description,
                            "Performence": dataList[i].Performence,
                            "venue": dataList[i].venue,
                            "startDate": dataList[i].startDate,
                            "EndDate": dataList[i].EndDate,
                            "dayName": dataList[i].dayName,
                            "day": dataList[i].day,
                            "month": dataList[i].month,
                            "year": dataList[i].year,
                            "time": dataList[i].time,
                            "period": dataList[i].period,
                            "avTickets": dataList[i].AvailableTicket,
                            "IsClosedBooking": dataList[i].IsClosedBooking,
                            "IsBuyBlocked": dataList[i].IsBuyBlocked,
                            "IsSellBlocked": dataList[i].IsSellBlocked,
                        };
                        vm.list.push(tempItem);
                    }
                    if (vm.list.length < 1) {
                        vm.showVenuePerformances = false;
                    } else {
                        vm.showVenuePerformances = true;
                    }
                    //HS: Filters firing
                    vm.getAllFilters();
                }
                if (more) {
                    vm.loadingMore = false;
                    vm.loadMoreText = "Load More";
                }
                vm.customConfigParams = createUsingFullOptions();
            }, function (error) { vm.allEventsLoading = false; })
        }
        //HS: Filters
        vm.getAllFilters = function () {
            Filters.getVenueFilters({
                "id": vm.id
            })
            .$promise.then(function (data) {
                if (data) {
                    vm.allCategories = data.VenueCategories;
                    if (vm.allCategories.length > 1) {
                        vm.allCategories.unshift({
                            "CategoryId": -1,
                            "CategoryName": "All"
                        });
                    }
                    else if (vm.allCategories.length === 1) {
                        vm.allCategories[0].CategoryId = -1;
                    }
                    else {
                        vm.allCategories.unshift({
                            "CategoryId": -1,
                            "CategoryName": "No categories"
                        });
                    }
                    vm.allPerformers = data.VenuePerformers;
                    if (vm.allPerformers.length > 1) {
                        vm.allPerformers.unshift({
                            "PerformerId": -1,
                            "PerformerName": "All"
                        });
                    }
                    else if (vm.allPerformers.length === 1) {
                        vm.allPerformers[0].PerformerId = -1;
                    }
                    else {
                        vm.allPerformers.unshift({
                            "PerformerId": -1,
                            "PerformerName": "No performers"
                        });
                    }
                    vm.allEvents = data.VenueEvents;
                    if (vm.allEvents.length > 1) {
                        vm.allEvents.unshift({
                            "EventId": -1,
                            "EventName": "All"
                        });
                    }
                    else if (vm.allEvents.length === 1) {
                        vm.allEvents[0].EventId = -1;
                    }
                    else {
                        vm.allEvents.unshift({
                            "EventId": -1,
                            "EventName": "No events"
                        });
                    }
                }
                vm.customConfigParams = createUsingFullOptions();
            }, function (error) { })
        }
        //HS: Function firing
        //HS: Item
        vm.getVenue();
        //HS: Datalist
        vm.getPerformances(false);
        vm.loadMore = function () {
            vm.listOffset = vm.requestSize;
            vm.requestSize += vm.countPerLoad;
            vm.customConfigParams.count(vm.requestSize);
            vm.getPerformances(true);
        }
        //HS:sell button click action
        vm.trySell = function(performanceId){
              if($rootScope.User&&$rootScope.User.Email)
                {
                    User.IsUserBlockedOrDeleted({"UserId": $rootScope.User.UserId})
                    .$promise.then(function (data) {
                    if (data.status == "true") {
                      location.href = "#/performance/" + performanceId + "/sell";
                    }
                    else {
                      location.href = "#/";
                      $rootScope.PopUpTitle = "Blocked User";
                      $rootScope.PopUpDescription = "You don't have access please contact with Admin";
                      $rootScope.showPopup = true;
                      }
                    }, function (error) { })
                }
              else
              {
                vm.toggleLoginPanel();
              }
        }
        vm.toggleLoginPanel = function () {
          $window.scrollTo(0, 0);
          $rootScope.loginPanelActive = !$rootScope.loginPanelActive;
          document.getElementById("userLoginEmail").value = "";
          document.getElementById("userLoginPassword").value = "";
          document.getElementById("userLoginRemember").checked = false;
          $rootScope.userLoginError = false;
          vm.User = {};
          vm.errors = {};
        };
    }
})();
