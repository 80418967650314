(function () {
    'use strict';

    angular
        .module('eltazkaraApp')
        .controller('CategoryController', CategoryController)
        .directive('myRepeatDirective', function () {
            return function (scope, element, attrs) {
                //if(scope.$index)
            };
        });

    CategoryController.$inject = [
        '$rootScope',
        'NgTableParams',
        '$stateParams',
        '$window',
        'CategoryEvents',
        'SubCategory',
        'Filters',
        'User'
    ];

    function CategoryController($rootScope, NgTableParams, $stateParams, $window, CategoryEvents, SubCategory, Filters, User) {
        var vm = this;
        vm.id = $stateParams.id;
        vm.countPerLoad = 5; //HS:Increase per step
        vm.requestSize = vm.countPerLoad; //HS:Initial list size
        vm.listOffset = 0; //HS:Load more start index
        vm.customConfigParams = createUsingFullOptions();

        function createUsingFullOptions() {
            var initialParams = {
                count: vm.requestSize
            };

            var initialSettings = {
                counts: [],
                dataset: vm.list
            };

            return new NgTableParams(initialParams, initialSettings);
        }

        vm.loadingMore;
        vm.loadMoreText = "Load More";
        vm.CurrentGrandParentId;
        vm.CurrentParentId;
        vm.list = [];
        vm.categoryPerformancesLoading = false;
        vm.hasPerformances = false;
        vm.hasPerformancesFiltered = false;
        vm.SubCategoriesList = [];
        vm.SiblingsCategories = [];
        vm.GrandParentCategories = [];
        vm.featuredEvent;

        vm.filters = {
            "CategoryId": -1,
            "EventId": -1,
            "PerformerId": -1,
            "VenueId": -1
        };

        vm.allSubCategories = [];
        vm.allEvents = [];
        vm.allPerformers = [];
        vm.allVenues = [];
        vm.availableTickets = 0;
        vm.categoryDetails;

        vm.getPerformances = function (more) {
            var listLimit = vm.countPerLoad;

            if (more) {
                vm.loadingMore = true;
                vm.loadMoreText = "Please wait...";
            }
            else {
                vm.listOffset = 0;
                vm.requestSize = vm.countPerLoad;
            }

            vm.categoryPerformancesLoading = true;

            CategoryEvents.getcategoryEvents({
                "Id": vm.id,
                "Offset": vm.listOffset,
                "Limit": listLimit,
                "Filters": vm.filters
            }).$promise.then(function (data) {
                vm.categoryPerformancesLoading = false;

                if (data.EventDetails) {
                    if (data.EventDetails.length > 0)
                        vm.hasPerformances = vm.hasPerformancesFiltered = true;
                    else {
                        if (data.Filtered)
                            vm.hasPerformancesFiltered = false;
                    }

                    vm.list = more ? vm.list : [];
                    vm.lastCall = data.LastCall;

                    for (var i = 0; i < data.EventDetails.length; i++) {
                        var tempItem = {
                            "venueid": data.EventDetails[i].venueid,
                            "Name": data.EventDetails[i].Name,
                            "EventId": data.EventDetails[i].EventId,
                            "EventName": data.EventDetails[i].EventName,
                            "Num_Performances_InEvent": data.EventDetails[i].Num_Performances_InEvent,
                            "Description": data.EventDetails[i].Description,
                            "Performence": data.EventDetails[i].Performence,
                            "PerformenceId": data.EventDetails[i].PerformenceId,
                            "venue": data.EventDetails[i].venue,
                            "startDate": data.EventDetails[i].startDate,
                            "EndDate": data.EventDetails[i].EndDate,
                            "dayName": data.EventDetails[i].dayName,
                            "day": data.EventDetails[i].day,
                            "month": data.EventDetails[i].month,
                            "year": data.EventDetails[i].year,
                            "time": data.EventDetails[i].time,
                            "period": data.EventDetails[i].period,
                            "IsClosedBooking": data.EventDetails[i].IsClosedBooking,
                            "IsSellBlocked": data.EventDetails[i].IsSellBlocked,
                            "IsBuyBlocked": data.EventDetails[i].IsBuyBlocked,
                            "avTickets": data.EventDetails[i].AvailableTicket,
                        };

                        vm.availableTickets += data.EventDetails[i].AvailableTicket;
                        vm.list.push(tempItem);
                    }

                    vm.getAllFilters();
                }

                if (more) {
                    vm.loadingMore = false;
                    vm.loadMoreText = "Load More";
                }

                vm.customConfigParams = createUsingFullOptions();
            }, function (error) { vm.categoryPerformancesLoading = false; })
        }

        vm.getNumber = function (num) {
            return new Array(num);
        }

        vm.subCategoriesLength = 0;
        vm.sbilingsCategoryLength = 0;

        vm.getsubcategory = function () {
            SubCategory.getSubCategory({ "Id": vm.id }).$promise.then(function (data) {
                if (data.ChildCategories) {
                    vm.CurrentParentId = data.CurrentParentId;
                    vm.CurrentGrandParentId = data.CurrentGrandParentId;
                    vm.ParentId = data.ParentId;

                    var tempItem = {
                        "Name": data.Name,
                        "Theme": data.Theme,
                        "Id": data.Id,
                        "ParentId": data.ParentId,
                        "ParentName": data.ParentName
                    };

                    vm.categoryDetails = tempItem;
                    vm.subCategoriesLength = data.ChildCategories.length;
                    vm.sbilingsCategoryLength = data.SiblingsCategories.length;
                    vm.number = Math.ceil(vm.subCategoriesLength / 6);
                    vm.sbilingsNumber = Math.ceil(vm.sbilingsCategoryLength / 6);

                    if (vm.subCategoriesLength > 0) {
                        for (var i = 0; i < vm.subCategoriesLength; i++) {
                            var tempItem = {
                                "ChildCategoryName": data.ChildCategories[i].ChildCategoryName,
                                "Id": data.ChildCategories[i].Id
                            };

                            vm.SubCategoriesList[i] = tempItem;
                        }
                    }

                    for (var i = 0; i < data.SiblingsCategories.length; i++) {
                        var tempItem = {
                            "ChildCategoryName": data.SiblingsCategories[i].ChildCategoryName,
                            "Id": data.SiblingsCategories[i].Id
                        };

                        vm.SiblingsCategories[i] = tempItem;
                    }

                    for (var i = 0; i < data.GrandParentCategories.length; i++) {
                        var tempItem = {
                            "GrandParentName": data.GrandParentCategories[i].GrandParentName,
                            "GrandParentId": data.GrandParentCategories[i].GrandParentId,
                        };

                        vm.GrandParentCategories.push(tempItem);
                    }
                }
            }, function (error) { });
        };

        //Sub item theme
        vm.GetSubCategoryTheme = function () {
            CategoryEvents.GetSubCategoryTheme({ "Id": vm.id }).$promise.then(function (data) {
                if (data.Theme)
                    $rootScope.theme = data.Theme;
                
                vm.getPerformances(false);
                vm.getsubcategory();
                vm.getFeaturedEvent();
            }, function (error) { });
        };

        //Sub item theme
        vm.GetSubCategoryTheme();

        vm.ParentExist = function (ParentId) {
            for (var i = 0; i < vm.GrandParentCategories.length; i++) {

                if (ParentId == vm.GrandParentCategories[i].GrandParentId)
                    return false;
            }

            return true;
        };

        vm.HasFeatureEvent = false;

        vm.getFeaturedEvent = function () {
            CategoryEvents.getFeaturedEvent({ "Id": vm.id }).$promise.then(function (data) {
                vm.HasFeatureEvent = true;
                if (data) {
                    var tempItem = {
                        "venueid": data.venueid,
                        "Name": data.Name,
                        "EventId": data.EventId,
                        "EventName": data.EventName,
                        "Description": data.Description,
                        "Performence": data.Performence,
                        "PerformenceId": data.PerformenceId,
                        "venue": data.venue,
                        "Image4": data.Image4,
                        "startDate": data.startDate,
                        "EndDate": data.EndDate,
                        "dayName": data.dayName,
                        "day": data.day,
                        "month": data.month,
                        "year": data.year,
                        "time": data.time,
                        "period": data.period,
                        "IsClosedBooking": data.IsClosedBooking,
                        "IsBuyBlocked": data.IsBuyBlocked,
                        "IsSellBlocked": data.IsSellBlocked,
                        "avTickets": data.AvailableTicket,
                    };

                    vm.featuredEvent = tempItem;

                    if (vm.featuredEvent.Name.length > 45) {
                        vm.featuredEvent.Name = vm.featuredEvent.Name.substring(0, 45);
                        vm.featuredEvent.Name += "...";
                    }
                }
            }, function (error) { });
        };

        vm.subsDisabled = false;

        vm.getAllFilters = function () {
            Filters.getCategoryFilters({ "id": vm.id }).$promise.then(function (data) {
                if (data) {
                    vm.allSubCategories = data.CategorySubcategories;

                    if (vm.allSubCategories.length >= 1) {
                        vm.allSubCategories.unshift({
                            "CategoryId": -1,
                            "CategoryName": "All"
                        });
                    }
                    else {
                        vm.allSubCategories.unshift({
                            "CategoryId": -1,
                            "CategoryName": "No subcategories"
                        });
                    }

                    vm.allEvents = data.CategoryEvents;

                    if (vm.allEvents.length > 1) {
                        vm.allEvents.unshift({
                            "EventId": -1,
                            "EventName": "All"
                        });
                    }
                    else if (vm.allEvents.length === 1) {
                        vm.allEvents[0].EventId = -1;
                    }
                    else {
                        vm.allEvents.unshift({
                            "EventId": -1,
                            "EventName": "No events"
                        });
                    }

                    vm.allVenues = data.CategoryVenues;

                    if (vm.allVenues.length > 1) {
                        vm.allVenues.unshift({
                            "VenueId": -1,
                            "VenueName": "All"
                        });
                    }
                    else if (vm.allVenues.length === 1) {
                        vm.allVenues[0].VenueId = -1;
                    }
                    else {
                        vm.allVenues.unshift({
                            "VenueId": -1,
                            "VenueName": "No venues"
                        });
                    }

                    vm.allPerformers = data.CategoryPerformers;

                    if (vm.allPerformers.length > 1) {
                        vm.allPerformers.unshift({
                            "PerformerId": -1,
                            "PerformerName": "All"
                        });
                    }
                    else if (vm.allPerformers.length === 1) {
                        vm.allPerformers[0].PerformerId = -1;
                    }
                    else {
                        vm.allPerformers.unshift({
                            "PerformerId": -1,
                            "PerformerName": "No performers"
                        });
                    }
                }

                vm.customConfigParams = createUsingFullOptions();
            }, function (error) { })
        };

        vm.loadMore = function () {
            vm.listOffset = vm.requestSize;
            vm.requestSize += vm.countPerLoad;
            vm.customConfigParams.count(vm.requestSize);
            vm.getPerformances(true);
        };
        
        vm.trySell = function (performanceId) {
            if ($rootScope.User && $rootScope.User.Email) {
                User.IsUserBlockedOrDeleted({ "UserId": $rootScope.User.UserId }).$promise.then(function (data) {
                    if (data.status == "true")
                        location.href = "#/performance/" + performanceId + "/sell";
                    else {
                        location.href = "#/";
                        $rootScope.PopUpTitle = "Blocked User";
                        $rootScope.PopUpDescription = "You don't have access please contact with Admin";
                        $rootScope.showPopup = true;
                    }
                }, function (error) { });
            }
            else
                vm.toggleLoginPanel();
        };
        
        vm.toggleLoginPanel = function () {
            $window.scrollTo(0, 0);
            $rootScope.loginPanelActive = !$rootScope.loginPanelActive;
            document.getElementById("userLoginEmail").value = "";
            document.getElementById("userLoginPassword").value = "";
            document.getElementById("userLoginRemember").checked = false;
            $rootScope.userLoginError = false;
            vm.User = {};
            vm.errors = {};
        };
    }
})();
