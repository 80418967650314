(function () {
    'use strict';

    angular
        .module('eltazkaraApp')
        .controller('VerifyController', VerifyController);

    UsersController.$inject = [
        '$rootScope',
        '$state',
        'User',
        'stateService',
        'userInfo',
        'RequestsService',
        '$cookies'
    ];

    function VerifyController(
        $rootScope,
        $state,
        User,
        stateService,
        userInfo,
        RequestsService,
        $cookies
    ) {
        var vm = this;
        vm.firstSent = false;
        vm.secondSent = false;
        vm.disableButton = false;
        vm.wehreToRedirect = '#/users/account';
        vm.verificationCheck = false;

		vm.regex = {
			mobile: /^(20|0){0,1}1[0-2,5]{1}[0-9]{8}$/
		};

        if ($rootScope.User)
            vm.id = $rootScope.User.UserId;
        else
            $state.go('home');

        vm.checkVerification = function () {
            User.checkVerification({ UserId: vm.id }).$promise.then(function (data) {
                vm.verificationCheck = true;
            });
        };

        if ($cookies.get('SmsSent') == 'true') {
            vm.showEmailPopUp = true;
            vm.resendVerificationCodeText = 'Resend Code';
            vm.firstSent = true;
            vm.secondSent = false;
            $cookies.remove('SmsSent');
            $cookies.put('SmsSent', 'false');
        } else {
            vm.firstSent = false;
            vm.secondSent = true;
            vm.resendVerificationCodeText = 'Send Code';
        }

        vm.wehreToRedirect = userInfo.getData();
        vm.checkVerification();
        vm.mobileNumber, vm.verificationCode;
        vm.verificationCodeSubmitted = false;
        vm.incorrectVerificationCode = false;
        vm.verificationCodeResent = false;
        vm.verifyText = 'Verify';
        vm.resendVerificationCodeText = 'Send Code';
        vm.skipVerificationText = 'Skip';
        vm.changeMobileNumberText = 'Change';
        var redirector = stateService.getPrev();

        vm.getMobileNumber = function () {
            vm.mobileNumber = 'Please wait...';

            User.getMobileNumber({ UserId: vm.id }).$promise.then(function (data) {
                if (data.User)
                    vm.mobileNumber = data.User.MobileNumber;
            });
        };

        vm.getMobileNumber();

        vm.rejectMobileNumber = function () {
            vm.mobileNumberCopy = angular.copy(vm.mobileNumber);
            vm.toggleMobileRejection();
        };

        vm.cancelRejection = function () {
            vm.mobileNumber = vm.mobileNumberCopy;
            vm.toggleMobileRejection();
        };

        vm.verify = function (codeThere) {
            vm.verificationCodeSubmitted = true;
            
            if (codeThere) {
                vm.verifyText = 'Please wait...';
                User.verifyMobileNumber({
                    UserId: vm.id,
                    VerificationCode: vm.verificationCode
                }).$promise.then(function (data) {
                    if (data.Msg === 'Verified') {
                        var redirection = redirector === 'account' ? 'account' : 'home';
                        vm.verificationCode = null;
                        vm.message = '';

                        if (vm.wehreToRedirect != '') {
                            userInfo.setWhereToRedirectAfterVerify('#/users/account');
                            $cookies.put('refresh', 'true');
                            location.href = vm.wehreToRedirect;
                        } else
                            $state.go(redirection);
                    } else {
                        vm.incorrectVerificationCode = true;
                        vm.message = 'Invalid code';
                    }

                    vm.verifyText = 'Verify';
                });
            }
            else
                vm.message = 'Code is required';
        };
        
        //Redirect back to account settings if came from it, home page otherwise
        vm.skipVerification = function () {
            if (vm.skipVerificationText === 'Skip') {
                var redirection = redirector === 'account' ? 'account' : 'home';
                vm.verificationCode = null;
                vm.message = '';
                $state.go(redirection);
            }
        };

        vm.resendVerificationCode = function () {
            if (vm.disableButton != true) {
                userInfo.SendSMSVerifiy({}).$promise.then(function (data) {
                    vm.resendVerificationCodeText = 'Please wait...';

                    if (data.status && data.status == 'success') {
                        vm.verificationCodeResent = true;
                        vm.resendVerificationCodeText = 'Resend Code';
                        vm.disableButton = false;
                    } 
                    else
                        vm.SendErrorRequest(data.status);
                });

            }
            vm.disableButton = true;
        };

        vm.SendErrorRequest = function (messageError) {
            RequestsService.AddRequest({
                Subject: 'SMS Credit is Over',
                RequestTypeId: { id: 6 },
                Name: $rootScope.User.Name,
                Mobile: $rootScope.User.MobileNumber,
                Email: '',
                ToFinance: true,
                Body: messageError
            }).$promise.then(function (data) { }).catch(function (error) {
                console.log(error)
            }).finally(function () {
                vm.resendVerificationCodeText = 'Resend Code';
                $rootScope.PopUpTitle = 'Mobile Verification Error';
                $rootScope.PopUpDescription = 'We are not able to send you a verification code, please try again later.';
                $rootScope.PopError = true;
            });
        };

        vm.change = function (numberValid) {
            vm.newNumberSubmitted = true;
            vm.mobileUsed = false;

            if (numberValid) {
                vm.changeMobileNumberText = 'Please wait...';

                User.UpdateMobileAndSendSMS({ NewMobile: vm.mobileNumber }).$promise.then(function (data) {
                    if (data.status) {
                        $rootScope.PopUpTitle = 'Update Error';
                        $rootScope.PopUpDescription = data.status;
                        $rootScope.PopError = true;
                    }

                    if (data.Break_Msg)
                        vm.mobileUsed = true;

                    if (data.Msg === 'changed')
                        vm.toggleMobileRejection();

                    vm.changeMobileNumberText = 'Change';
                });
            }
            else
                vm.message = 'Code is required';
        };

        vm.toggleMobileRejection = function () {
            vm.mobileRejectionToggle = !vm.mobileRejectionToggle;
            vm.message = '';
        };
    }
})();
