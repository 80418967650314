(function () {
    'use strict';

    angular
    .module('eltazkaraApp')
    .controller('SoldTicketsController', SoldTicketsController);

    SoldTicketsController.$inject = [
    '$rootScope',
    'NgTableParams',
    'User',
    '$state',
    'PaymentService'
    ];

    function SoldTicketsController($rootScope, NgTableParams, User,$state, PaymentService) {
        var vm = this;
        vm.showPoper = false;
        vm.PageIndex = 1;
        vm.CountPerPage_RejectedTickets = 5;
        vm.CountPerPage_SoldTickets = 5;
        vm.CountPerPage_ActiveTickets = 5;
        vm.CountPerPage_PendingTickets = 5;
        vm.CountPerPage_CancelledTickets = 5;

        vm.soldTicketsLoading = false;
        vm.disableRejectedTickets = false;
        vm.disableSoldTickets = false;
        vm.disableActiveTickets = false;
        vm.disablePendingTickets = false;
        vm.disableCancelledTickets = false;

        vm.PendingTickets = [];
        vm.RejectedTickets = [];
        vm.ActiveTickets = [];
        vm.SoldTickets = [];
        vm.CancelledTickets = [];
        vm.FinalActiveTickets = [];
        vm.FinalPendingTickets = [];
        vm.FinalSoldTickets = [];
        vm.FinalRejectedTickets = [];
        vm.FinalCancelledTickets=[];
        vm.ReceiptTickets = [];

        vm.showCancel = false;
        vm.tgtc = 0;

        vm.GetSoldTickets = function () {
            vm.soldTicketsLoading = true;
            User.GetSoldTickets({ "Id": vm.id })
            .$promise.then(function (data) {
                vm.soldTicketsLoading = false;
                if (data) {
                    vm.AllTickets = data;
                    vm.PendingTickets = data.PendingTickets;
                    vm.RejectedTickets = data.RejectedTickets;
                    vm.ActiveTickets = data.ActiveTickets;
                    vm.SoldTickets = data.SoldTickets;
                    vm.CancelledTickets = data.CancelledTickets;

                    vm.AmountDollars = data.TotalSoldTicketPriceDollars;
                    vm.AmountCents = data.TotalSoldTicketPriceCents;
                    vm.PaidDollars = data.PaidMoneyDollars;
                    vm.PaidCents = data.PaidMoneyCents;
                    vm.RemainingDollars = data.RemainingMoneyDollars;
                    vm.RemainingCents = data.RemainingMoneyCents;
                    vm.RefundDollars = data.TotalRefundedMoneyDollars;
                    vm.RefundCents = data.TotalSoldTicketPriceCents;
                    for (var i = 0; i < vm.ActiveTickets.length; i++) {
                        var tempItem = {
                            "TicketGroupId": vm.ActiveTickets[i].TicketGroupId,
                            "Day": vm.ActiveTickets[i].Day,
                            "Month": vm.ActiveTickets[i].Month,
                            "Year": vm.ActiveTickets[i].Year,
                            "Time": vm.ActiveTickets[i].Time,
                            "DayName": vm.ActiveTickets[i].DayName,
                            "RemainingTickets": vm.ActiveTickets[i].RemainingTickets,
                            "NumberOfTickets": vm.ActiveTickets[i].NumberOfTickets,
                            "Section": vm.ActiveTickets[i].Section,
                            "Block": vm.ActiveTickets[i].Block,
                            "Row": vm.ActiveTickets[i].Row,
                            "PerformanceId": vm.ActiveTickets[i].PerformanceId,
                            "PerformanceName": vm.ActiveTickets[i].PerformanceName,
                            "EventId": vm.ActiveTickets[i].EventId,
                            "EventName": vm.ActiveTickets[i].EventName,
                            "StartSeat": vm.ActiveTickets[i].StartSeat,
                            "EndSeat": vm.ActiveTickets[i].EndSeat,
                            "Price": vm.ActiveTickets[i].Price,
                            "FullDate": Date.parse(vm.ActiveTickets[i].FullDate)
                        };
                        vm.FinalActiveTickets.push(tempItem);
                    }
                    for (var i = 0; i < vm.PendingTickets.length; i++) {
                        var tempItem = {
                            "Day": vm.PendingTickets[i].Day,
                            "RequestId":vm.PendingTickets[i].RequestId,
                            "CancelText": vm.PendingTickets[i].CancelText,
                            "Month": vm.PendingTickets[i].Month,
                            "Year": vm.PendingTickets[i].Year,
                            "Time": vm.PendingTickets[i].Time,
                            "DayName": vm.PendingTickets[i].DayName,
                            "RemainingTickets": vm.PendingTickets[i].RemainingTickets,
                            "NumberOfTickets": vm.PendingTickets[i].NumberOfTickets,
                            "Section": vm.PendingTickets[i].Section,
                            "Block": vm.PendingTickets[i].Block,
                            "Row": vm.PendingTickets[i].Row,
                            "PerformanceId": vm.PendingTickets[i].PerformanceId,
                            "PerformanceName": vm.PendingTickets[i].PerformanceName,
                            "EventId": vm.PendingTickets[i].EventId,
                            "EventName": vm.PendingTickets[i].EventName,
                            "StartSeat": vm.PendingTickets[i].StartSeat,
                            "EndSeat": vm.PendingTickets[i].EndSeat,
                            "Price": vm.PendingTickets[i].Price,
                            "FullDate": Date.parse(vm.PendingTickets[i].FullDate)
                        };
                        vm.FinalPendingTickets.push(tempItem);
                    }
                    for (var i = 0; i < vm.SoldTickets.length; i++) {
                        var tempItem = {
                            "Day": vm.SoldTickets[i].Day,
                            "Month": vm.SoldTickets[i].Month,
                            "Year": vm.SoldTickets[i].Year,
                            "Time": vm.SoldTickets[i].Time,
                            "DayName": vm.SoldTickets[i].DayName,
                            "NumberOfTickets": vm.SoldTickets[i].NumberOfTickets,
                            "RemainingTickets": vm.SoldTickets[i].RemainingTickets,
                            "Section": vm.SoldTickets[i].Section,
                            "Block": vm.SoldTickets[i].Block,
                            "StartSeat": vm.SoldTickets[i].StartSeat,
                            "EndSeat": vm.SoldTickets[i].EndSeat,
                            "EndSeatUpdated": vm.SoldTickets[i].EndSeatUpdated,
                            "Row": vm.SoldTickets[i].Row,
                            "PerformanceId": vm.SoldTickets[i].PerformanceId,
                            "PerformanceName": vm.SoldTickets[i].PerformanceName,
                            "EventId": vm.SoldTickets[i].EventId,
                            "EventName": vm.SoldTickets[i].EventName,
                            "TicketsEventId": vm.SoldTickets[i].EventId,
                            "TicketsEventName": vm.SoldTickets[i].EventName,
                            "TicketsStartSeat": vm.SoldTickets[i].StartSeat,
                            "Price": vm.SoldTickets[i].Price,
                            "FullDate": Date.parse(vm.SoldTickets[i].FullDate),
                            "RequestRef": vm.SoldTickets[i].RequestRef
                        };
                        var ticketItem = {
                            "RequestRef": vm.SoldTickets[i].RequestRef,
                            "NumberOfTickets": vm.SoldTickets[i].NumberOfTickets - vm.SoldTickets[i].RemainingTickets,
                        }
                        vm.FinalSoldTickets.push(tempItem);
                        vm.ReceiptTickets.push(ticketItem);
                    }

                    for (var i = 0; i < vm.RejectedTickets.length; i++) {
                        var tempItem = {
                            "Day": vm.RejectedTickets[i].Day,
                            "Month": vm.RejectedTickets[i].Month,
                            "Year": vm.RejectedTickets[i].Year,
                            "Time": vm.RejectedTickets[i].Time,
                            "DayName": vm.RejectedTickets[i].DayName,
                            "NumberOfTickets": vm.RejectedTickets[i].NumberOfTickets,
                            "RemainingTickets": vm.RejectedTickets[i].RemainingTickets,
                            "Section": vm.RejectedTickets[i].Section,
                            "Block": vm.RejectedTickets[i].Block,
                            "Row": vm.RejectedTickets[i].Row,
                            "PerformanceId": vm.RejectedTickets[i].PerformanceId,
                            "PerformanceName": vm.RejectedTickets[i].PerformanceName,
                            "EventId": vm.RejectedTickets[i].EventId,
                            "EventName": vm.RejectedTickets[i].EventName,
                            "StartSeat": vm.RejectedTickets[i].StartSeat,
                            "EndSeat": vm.RejectedTickets[i].EndSeat,
                            "Price": vm.RejectedTickets[i].Price,
                            "FullDate": Date.parse(vm.RejectedTickets[i].FullDate)
                        };
                        vm.FinalRejectedTickets.push(tempItem);
                    }

                    for (var i = 0; i < vm.CancelledTickets.length; i++) {
                        var tempItem = {
                            "Day": vm.CancelledTickets[i].Day,
                            "Month": vm.CancelledTickets[i].Month,
                            "Year": vm.CancelledTickets[i].Year,
                            "Time": vm.CancelledTickets[i].Time,
                            "DayName": vm.CancelledTickets[i].DayName,
                            "NumberOfTickets": vm.CancelledTickets[i].NumberOfTickets,
                            "RemainingTickets": vm.CancelledTickets[i].RemainingTickets,
                            "Section": vm.CancelledTickets[i].Section,
                            "Block": vm.CancelledTickets[i].Block,
                            "Row": vm.CancelledTickets[i].Row,
                            "PerformanceId": vm.CancelledTickets[i].PerformanceId,
                            "PerformanceName": vm.CancelledTickets[i].PerformanceName,
                            "EventId": vm.CancelledTickets[i].EventId,
                            "EventName": vm.CancelledTickets[i].EventName,
                            "StartSeat": vm.CancelledTickets[i].StartSeat,
                            "EndSeat": vm.CancelledTickets[i].EndSeat,
                            "Price": vm.CancelledTickets[i].Price,
                            "FullDate": Date.parse(vm.CancelledTickets[i].FullDate)
                        };
                        vm.FinalCancelledTickets.push(tempItem);
                    }
                    
                    if(vm.FinalRejectedTickets.length == 0 && vm.FinalSoldTickets.length == 0
                        && vm.FinalPendingTickets.length == 0 && vm.FinalActiveTickets.length == 0 
                        && vm.FinalCancelledTickets.length == 0)
                    {
                          $rootScope.User.HaveSoldTicket = false;
                          $state.go('home');
                    }
                    else {
                      $rootScope.User.HaveSoldTicket = true;
                    }
                }
            }, function (error) { vm.soldTicketsLoading = false; });
        };

        vm.GetSoldTickets();
        vm.showReceipt = function (Ticket) {

            vm.subTotal = Ticket.NumberOfTickets * Ticket.Price
            PaymentService.getOrder({ "OrderRef": Ticket.RequestRef })
            .$promise.then(function (data) {
                if (data) {
                    for (var i = 0; i < vm.ReceiptTickets.length; i++) {
                        if (vm.ReceiptTickets[i].RequestRef == Ticket.RequestRef) {
                            vm.ReceiptNumberOfTickets = vm.ReceiptTickets[i].NumberOfTickets;
                            break;
                        }
                    }
                    if (data.message == "4041")
                        location.href = "#/Home";
                    else
                        vm.order = data;
                    vm.showPoper = true;
                }
            }, function (error) { })
        }
        vm.loadMoreRejectedTickets = function () {
            if (vm.CountPerPage_RejectedTickets < vm.RejectedTickets.length) {
                vm.customConfigParamsRejected.count(vm.CountPerPage_RejectedTickets + 5);
                vm.CountPerPage_RejectedTickets += 5;
            }
            else
                vm.disableRejectedTickets = true;
        }

        vm.loadMoreSoldTickets = function () {
            if (vm.CountPerPage_SoldTickets < vm.SoldTickets.length) {
                vm.customConfigParamsSold.count(vm.CountPerPage_SoldTickets + 5);
                vm.CountPerPage_SoldTickets += 5;
            }
            else
                vm.disableSoldTickets = true;

        }

        vm.loadMoreActiveTickets = function () {
            if (vm.CountPerPage_ActiveTickets < vm.ActiveTickets.length) {
                vm.customConfigParamsActive.count(vm.CountPerPage_ActiveTickets + 5);
                vm.CountPerPage_ActiveTickets += 5;
            }
            else
                vm.disableActiveTickets = true;

        }

        vm.loadMorePendingTickets = function () {
            if (vm.CountPerPage_PendingTickets < vm.PendingTickets.length) {
                vm.customConfigParamsPending.count(vm.CountPerPage_PendingTickets + 5);
                vm.CountPerPage_PendingTickets += 5;
            }
            else
                vm.disablePendingTickets = true;
        }

        vm.loadMoreCancelledTickets = function () {
            if (vm.CountPerPage_CancelledTickets < vm.CancelledTickets.length) {
                vm.customConfigParamsCancelled.count(vm.CountPerPage_CancelledTickets + 5);
                vm.CountPerPage_CancelledTickets += 5;
            }
            else
                vm.disableCancelledTickets = true;
        }

        //vm.tableParams = new NgTableParams({}, { dataset: data});
        vm.customConfigParamsActive = createUsingFullOptionsActive();

        function createUsingFullOptionsActive() {
            var initialParams = {
                count: vm.CountPerPage_ActiveTickets, // initial page size
                sorting: { FullDate: "desc" }
            };
            var initialSettings = {
                counts: [],
                dataset: vm.FinalActiveTickets
            };
            return new NgTableParams(initialParams, initialSettings);
        };
        vm.customConfigParamsPending = createUsingFullOptionsPending();

        function createUsingFullOptionsPending() {
            var initialParams = {
                count: vm.CountPerPage_PendingTickets, // initial page size
                sorting: { FullDate: "desc" }
            };
            var initialSettings = {
                counts: [],
                dataset: vm.FinalPendingTickets
            };
            return new NgTableParams(initialParams, initialSettings);
        }
        vm.customConfigParamsSold = createUsingFullOptionsSold();

        function createUsingFullOptionsSold() {
            var initialParams = {
                count: vm.CountPerPage_SoldTickets, // initial page size
                sorting: { FullDate: "desc" }
            };
            var initialSettings = {
                counts: [],
                dataset: vm.FinalSoldTickets
            };
            return new NgTableParams(initialParams, initialSettings);
        }
        vm.customConfigParamsRejected = createUsingFullOptionsRejected();

        function createUsingFullOptionsRejected() {
            var initialParams = {
                count: vm.CountPerPage_RejectedTickets, // initial page size
                sorting: { FullDate: "desc" }
            };
            var initialSettings = {
                counts: [],
                dataset: vm.FinalRejectedTickets
            };
            return new NgTableParams(initialParams, initialSettings);
        }

        vm.customConfigParamsCancelled = createUsingFullOptionsCancelled();

        function createUsingFullOptionsCancelled() {
            var initialParams = {
                count: vm.CountPerPage_CancelledTickets, // initial page size
                sorting: { FullDate: "desc" }
            };
            var initialSettings = {
                counts: [],
                dataset: vm.FinalCancelledTickets
            };
            return new NgTableParams(initialParams, initialSettings);
        }

        vm.CancelSellRequest = function (RequestId) {
            User.CancelSellRequest({ "Id": RequestId })
            .$promise.then(function (data) {
                if (data) {
                  if(vm.FinalRejectedTickets.length != 0 && vm.FinalSoldTickets.length != 0
                      && vm.FinalPendingTickets.length != 0 && vm.FinalActiveTickets.length != 0)
                      {
                        $state.reload();
                      }
                      else {
                        $rootScope.User.HaveSoldTicket = false;
                        $state.go('home');
                      }
                }
            }, function (error) { });
        };

        
        vm.CancelTicketGroup = function (ticketGroupId) {
            vm.showCancel = true;
            vm.tgtc = ticketGroupId;
        };

        vm.CancelTicketGroupDismiss = function () {
            vm.showCancel = false;
            vm.tgtc = 0;
        };

        vm.CannotCancelTicketGroupDismiss = function() {
            vm.showCannotCancel = false;
        }

        vm.CancelTicketGroupVerified = function () {
            User.CancelTicketGroup({ "ticketGroupId": vm.tgtc })
            .$promise.then(function (data) {
                if (data) {
                    if(data.message && data.message === 'Ticket group on hold') {
                        vm.CancelTicketGroupDismiss();
                        vm.showCannotCancel = true;
                        return;
                    }
                    $state.reload();
                }
            }, function (error) { });
        };
    }

})();
