

(function () {
  'use strict';

  angular
      .module('eltazkara.utils')
      .directive('fieldEditable', fieldEditable);

  fieldEditable.$inject = [];

  function fieldEditable() {
    return {
      restrict: 'A',
      scope: {
        isOpen: '&'
      },
      link: fieldEditableLinker
    }
  };

  function fieldEditableLinker(scope, $elm, attrs) {
    scope.isOpen=true;
  }

})();
