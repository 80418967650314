(function () {
    'use strict';

    angular
        .module('eltazkaraApp')
        .factory('TicketsService', TicketsService);

    TicketsService.$inject = ['$resource'];

    function TicketsService($resource) {
        var service = $resource(window.backendUrl + 'Performances', {}, {
            'getTicketsList': {
                method: 'POST', isArray: false,
                url: window.backendUrl + "Performances/GetTickets"
            }
        });

        return service;
    }
})();