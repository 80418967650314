(function () {
    'use strict';

    angular
        .module('eltazkaraApp')
        .factory('User', User);

    User.$inject = ['$resource'];

    function User($resource) {
        var service = $resource(window.backendUrl + 'Users', {}, {
            'GetBoughtTickets': {
                method: 'POST', isArray: false,
                url: window.backendUrl + "Users/GetBoughtTickets"
            },
            'HasSoldTickets': {
                method: 'POST', isArray: false,
                url: window.backendUrl + "Users/HasSoldTickets"
            },
            'GetSoldTickets': {
                method: 'POST', isArray: false,
                url: window.backendUrl + "Users/GetSoldTickets"
            },
            'CancelSellRequest': {
                method: 'POST', isArray: false,
                url: window.backendUrl + "Users/CancelSellRequest"
            },
            'CancelTicketGroup': {
                method: 'POST', isArray: false,
                url: window.backendUrl + "Users/CancelTicketGroup"
            },
            'GetReceipt': {
                method: 'POST', isArray: false,
                url: window.backendUrl + "Users/GetReceipt"
            },
            'checkVerification': {
                method: 'POST', isArray: false,
                url: window.backendUrl + "Users/CheckVerification"
            },
            'getMobileNumber': {
                method: 'POST', isArray: false,
                url: window.backendUrl + "Users/GetMobile"
            },
            'verifyMobileNumber': {
                method: 'POST', isArray: false,
                url: window.backendUrl + "Users/Verify"
            },
            'resendVerificationCode': {
                method: 'POST', isArray: false,
                url: window.backendUrl + "Users/ResendCode"
            },
            'changeMobileNumber': {
                method: 'POST', isArray: false,
                url: window.backendUrl + "Users/UpdateMobile"
            },
            'CheckEmailPhone': {
                method: 'POST', isArray: false,
                url: window.backendUrl + "Users/CheckEmailIfExist"
            }
            ,
            'ResetPasswordUsingMail': {
                method: 'POST', isArray: false,
                url: window.backendUrl + "Users/ResetPasswordUsingMail"
            },
            'UpdateMobileAndSendSMS': {
                method: 'POST', isArray: false,
                url: window.backendUrl + "Users/UpdateMobileAndSendSMS"
            },
            'IsUserBlocked': {
                method: 'POST', isArray: false,
                url: window.backendUrl + "Users/IsUserBlocked"
            },
            'IsUserBlockedOrDeleted': {
                method: 'POST', isArray: false,
                url: window.backendUrl + "Users/IsUserBlockedOrDeleted"
            }
        });
        service.showVerify = true;
        service.getShowVerify = function () {
            return this.showVerify;
        };
        service.setShowVerify = function (data) {
            this.showVerify = data;
            //$rootScope.$broadcast('data_shared');
        };
        return service;
    }
})();
