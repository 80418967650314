(function() {
	'use strict';

	var dependencies = [
		'ngRoute',
		'ui.router',
		'ngAria',
		'ngResource',
		'LocalStorageModule',
		'ngStorage',
		'ngCookies',
		'ngCacheBuster',
		'angular-loading-bar',
		'pascalprecht.translate',
		'ngFileUpload',
		'720kb.socialshare',
		'angucomplete-alt',
		'ngTable',
		'ngMap',
		'timer',
		'ngTouch',
        'seo'
	];

	var modules = [
		'eltazkara.handler',
		'eltazkara.config',
		'eltazkara.topBar',
		'eltazkara.footer',
		'eltazkara.social',
		'eltazkara.auth',
		'eltazkara.utils',
		'eltazkara.interceptor'
	];

	angular
		.module('eltazkaraApp', dependencies.concat(modules))
		.run(elTazkaraStarter)
		.factory('UserData', UserData);

	elTazkaraStarter.$inject = [
		'$http',
		'$rootScope',
		'$location',
		'$timeout',
		'$window',
		'UserData',
		'stateHandler'
	];

	// starter
	function elTazkaraStarter(
		$http,
		$rootScope,
		$location,
		$timeout,
		$window,
		UserData,
		stateHandler
	) {
		stateHandler.initialize();
		$http.defaults.headers.common.Accept = '*/*';
		$rootScope.accountText = UserData.getKey('UserName', 'Login');
		$rootScope.backendURL = window.location.href.replace(window.location.hash, '');

		var forceSSL = function() {
			if ($location.protocol() !== 'https' && $window.location.href.indexOf("localhost")==-1) {
				$window.location.href = $location
					.absUrl()
					.replace('http', 'https');
			}
		};

		forceSSL();

		/**
		 * loading function to be called to start/end loading
		 * @param { onOff }
		 */
		$rootScope.startLoading = function(onOff, apply) {
			if (onOff) {
				$rootScope.isLoading = true;

				$timeout(function() {
					$rootScope.bodyLoading = true;
				}, 1000);
			} else {
				$rootScope.isLoading = false;
				$rootScope.bodyLoading = false;
			}

			if (apply)
				$rootScope.$apply();
		};

		/**
		 * to call a toast alert
		 *
		 * @param { message } message to show up
		 * in toast
		 */
		$rootScope.showToast = function(message) {
			$mdToast.show(
				$mdToast
					.simple()
					.textContent(message)
					.position('bottom left')
					.hideDelay(1000)
			);
		};
	}

	function UserData($window) {
		return {
			set: function(userData, homeLocation, companyLocation) {
				for (var property in userData) {
					if (userData[property] == null ||
						userData[property] === 'undefined'
					) {
						$window.localStorage[property] = '';
						continue;
					}

					$window.localStorage[property] = userData[property];
				}

				if (homeLocation) {
					for (var property in homeLocation) {
						$window.localStorage['Home' + property] = homeLocation[property];
					}
				}

				if (companyLocation) {
					for (var property in companyLocation) {
						$window.localStorage['Company' + property] = companyLocation[property];
					}
				}

				$window.localStorage['IsVerified'] = false;
				$window.localStorage['VerifictaionSkipped'] = false;
				$window.localStorage['LogoutText'] = 'Logout';
				$window.localStorage['VerificationHeading'] = 'Thank you for registering';
			},
			getUser: function() {
				var user = {
					UserId: $window.localStorage['UserId'],
					Name: $window.localStorage['Name'],
					Email: $window.localStorage['Email'],
					MobileNumber: $window.localStorage['MobileNumber'],
					Image: $window.localStorage['Image'],
					Gender: $window.localStorage['Gender'],
					CompanyName: $window.localStorage['CompanyName'],
					CompanyPhone: $window.localStorage['CompanyPhone'],
					IsVerified: $window.localStorage['IsVerified'],
					IsBlocked: $window.localStorage['IsBlocked'],
					VerificationCode: $window.localStorage['VerificationCode'],
					Token: $window.localStorage['Token'],
					Birthdate: $window.localStorage['Birthdate'],
					CreatedOn: $window.localStorage['CreatedOn']
				};

				return user;
			},
			getHomeLocation: function() {
				var location = {
					LocationId: $window.localStorage['HomeLocationId'],
					LocationTypeId: $window.localStorage['HomeLocationTypeId'],
					CountryId: $window.localStorage['HomeCountryId'],
					CityId: $window.localStorage['HomeCityId'],
					Address: $window.localStorage['HomeAddress'],
					PostalCode: $window.localStorage['HomePostalCode']
				};

				return location;
			},
			getCompanyLocation: function() {
				var location = {
					LocationId: $window.localStorage['CompanyLocationId'],
					LocationTypeId: $window.localStorage['CompanyLocationTypeId'],
					CountryId: $window.localStorage['CompanyCountryId'],
					CityId: $window.localStorage['CompanyCityId'],
					Address: $window.localStorage['CompanyAddress'],
					PostalCode: $window.localStorage['CompanyPostalCode']
				};

				return location;
			},
			setKey: function(key, val) {
				$window.localStorage[key] = val;
			},
			getKey: function(key, defaultValue) {
				return $window.localStorage[key] || defaultValue;
			},
			getBackendURL: function() {
				return window.location.href.replace(window.location.hash, '');
			},
			isVerified: function() {
				return $window.localStorage['IsVerified'];
			},
			isVerificationSkipped: function() {
				return !$window.localStorage['VerifictaionSkipped'];
			},
			CLEAR: function() {
				$window.localStorage.clear();
			}
		};
	}

})();
