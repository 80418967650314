(function () {
  'use strict';

  angular
      .module('eltazkara.utils')
      .directive('appenzaUpload', uploadDirective);

  uploadCtrl.$inject = [
    '$scope',
    'localStorageService',
    '$timeout',
    '$rootScope'
  ]

  function uploadDirective() {
    return {
      restrict: 'AE',
      controller: uploadCtrl,
      controllerAs: 'uploader',
      bindToController: true,
      scope: {
        showUpload: '=showUpload',
        files: '=files',
        modelType: '=modelType',
        trigger: '&',
        remainder: '@remainder'
      },
      templateUrl: 'components/utils/upload.html'
    };
  }

  function uploadCtrl($scope, localStorageService, $rootScope) {
    // define controller scope
    var uploader = this;

    uploader.filesAsData = [];
    uploader.remainingFiles = uploader.remainder;

    uploader.initialize = function () {
      uploader.showDialog = true;
      uploader.uniqueFileName = "";
      uploader.uniques = [];
      uploader.isAttachment = false;
      uploader.fileNames = [];
      uploader.filesSizes = [];
      uploader.finishedFile = [];
      uploader.sizeLimit = 105857600000;
      uploader.uploadProgress = [];
      uploader.showProgress = true;
      uploader.showFiles = false;
      uploader.flag = 0;
      uploader.imagesDataName = "imagesData_" + uploader.modelType;
      uploader.creds = {
        bucket: 'eltazkara-dev',
        access_key: 'AKIAIIOV5IY4W2WXHLXA',
        secret_key: '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'
      };

      var images = localStorageService.get('images');
      if (images) {
        uploader.filesUniqueNames = images;
      } else {
        uploader.filesUniqueNames = "";
      }
    };

    uploader.upload = function () {
      if (!uploader.files) return;

      AWS.config.update({
        accessKeyId: uploader.creds.access_key,
        secretAccessKey: uploader.creds.secret_key
      });

      AWS.config.region = 'us-west-2';

      var bucket = new AWS.S3({params: {Bucket: uploader.creds.bucket}});

      if (uploader.files) {
        uploader.flag = 1;
        for (var i = 0; i < uploader.files.length; i++) {
          uploader.uploadProgress.push(0);
          uploader.fileNames.push(uploader.files[i].name);
          uploader.filesSizes.push(uploader.files[i].size);
        }
      }

      if (uploader.files) {
        for (var i = 0; i < uploader.files.length; i++) {
          uploader.file = uploader.files[i];
          // Perform File Size Check First
          var fileSize = Math.round(parseInt(uploader.file.size));
          if (fileSize > uploader.sizeLimit) {
            var msg = 'Sorry, your attachment is too big. Maximum ' + uploader.fileSizeLabel(uploader.sizeLimit) + ' file attachment allowed' + '\nFile Too Large';
            $rootScope.showToast(msg);
            return false;
          }
          // Prepend Unique String To Prevent Overwrites
          uploader.uniqueFileName = uploader.uniqueString() + '-' + uploader.file.name;
          uploader.name = uploader.file.name;
          uploader.uniques.push(uploader.uniqueFileName);
          uploader.finishedFile[uploader.uniqueFileName] = -2;

          var params = {
            Key: uploader.uniqueFileName,
            ContentType: uploader.file.type,
            Body: uploader.file,
            ServerSideEncryption: 'AES256',
            ACL: 'public-read'
          };

          var options = {partSize: 5 * 1024 * 1024, queueSize: 1};

          bucket.upload(params, options, function (err, data) {
            if (err) {
              return false;
            }
            else {
              // Reset The Progress Bar
              setTimeout(function () {
                uploader.uploadProgress[uploader.fileNames.indexOf(uploader.file.name)] = 0;
                uploader.$digest();
              }, 4000);
            }
          }).on('httpUploadProgress', function (progress) {
            var key = progress.key;
            var index = uploader.fileNames.indexOf(key.substring(key.indexOf('-') + 1, key.length));
            var loaded = Math.round(progress.loaded / progress.total * 100);
            uploader.uploadProgress[index] = loaded === 100 ? 99 : loaded;
            $scope.$digest();
          }).send(function (err, data) {
            var key = data.key ? data.key : data.Key;
            var index = uploader.fileNames.indexOf(key.substring(key.indexOf('-') + 1, key.length));
            uploader.uploadProgress[index] = 100;
            $scope.$digest();
            if (data.Key) {
              var index = uploader.fileNames.indexOf(data.Key.substring(data.Key.indexOf('-') + 1, data.Key.length));
              uploader.addUrlToResources(data.Key, uploader.files[index].type);
            } else {
              var index = uploader.fileNames.indexOf(data.key.substring(data.key.indexOf('-') + 1, data.key.length));
              uploader.addUrlToResources(data.key, uploader.files[index].type);
            }
            if (key) {
              uploader.filesAsData.push(data.Location);
            }
          });
        }
      } else {
        // No File Selected
      }
    };

    uploader.fileSizeLabel = function (size) {
      var roundedSize = parseInt(size, 10);
      var megaByte = roundedSize / 1024.0 / 1024.0;
      var kiloByte = roundedSize / 1024.0;
      var byte = roundedSize;
      if (megaByte < 1 && kiloByte < 1) {
        return byte.toFixed(2) + 'B';
      } else if (megaByte < 1) {
        return kiloByte.toFixed(2) + 'KB';
      } else {
        return megaByte.toFixed(2) + 'MB';
      }
    };

    uploader.trackUploads = function () {
      var curFlag = 1;
      for (var progress in uploader.uploadProgress) {
        curFlag &= (uploader.uploadProgress[progress] === 100);
      }
      if (curFlag) {
        uploader.showProgress = false;
        uploader.showFiles = true;
        uploader.storeImagesData();
        $scope.$digest();
      }
    };

    uploader.uniqueString = function () {
      var text = "";
      var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

      for (var i = 0; i < 8; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
      }
      return text;
    }

    uploader.truncateString = function (index) {
      if (uploader.files) {
        if (uploader.files[index]) {
          var trueName = uploader.files[index].name;
          if (trueName.length > 15)
            return '...';
        }
      }
    };

    uploader.storeImagesData = function () {
      var imagesData = localStorageService.get(uploader.imagesDataName);
      if (imagesData) {
        var toStoreData = imagesData.concat(uploader.filesAsData.filter(function (item) {
          return imagesData.indexOf(item) < 0;
        }));
        localStorageService.set(uploader.imagesDataName, toStoreData);
      } else {
        localStorageService.set(uploader.imagesDataName, uploader.filesAsData);
      }
    };

    uploader.deleteImage = function (index) {
      var imagesData = localStorageService.get(uploader.imagesDataName);
      imagesData.splice(index, 1);
      localStorageService.set(uploader.imagesDataName, imagesData);
      uploader.filesAsData.splice(index, 1);
    }

    uploader.addUrlToResources = function (key, originalFileType) {
      var fileName = key;
      uploader.finishedFile[fileName] = 0;
      if (uploader.filesUniqueNames.length > 0) {
        uploader.filesUniqueNames += "," + key;
      } else {
        uploader.filesUniqueNames = key;
      }
      uploader.trackUploads();
    };

    uploader.updateChoosenFiles = function (files) {
      if (files.length > uploader.remainingFiles) {
        $rootScope.showToast('number of remaining images is only ' + uploader.remainingFiles);
      } else {
        uploader.initialize();
        uploader.remainingFiles -= uploader.files.length;
        uploader.files = files;
        $scope.$digest();
        uploader.upload();
      }
    };

    uploader.close = function () {
      uploader.storeImagesData();
      if (uploader.filesUniqueNames.length > 0) {
        var success = localStorageService.set('images', uploader.filesUniqueNames);
        if (success) {
          uploader.showUpload = false;
          uploader.showDialog = false;
        }
      } else {
        uploader.showDialog = false;
        uploader.showUpload = false;
      }
      uploader.trigger();
    };

    (function () {
      uploader.initialize();
      if (uploader.files.length > uploader.remainingFiles) {
        $rootScope.showToast('number of remaining images is only ' + uploader.remainder);
        uploader.showDialog = false;
        uploader.showUpload = false;
      } else {
        uploader.remainingFiles -= uploader.files.length;
        uploader.upload();
      }
    }());
  };

})();
