//(function () {
'use strict';
angular
    .module('eltazkaraApp')
    .controller('UsersController', UsersController)
    .factory('ConfirmCodeService', ConfirmCodeService)
    .service('ProfileService', ProfileService)
    .service('MyDataService', ['$http', function ($http) {
        this.getCountries = function () {
            return $http({
                url: window.backendUrl + "Locations/GetCountries",
                method: 'GET',
                crossDomain: true,
                withCredentials: false,
                headers: { 'content-type': 'application/json' }
            });
        };
    }])
    .service('ResendCodeService', ['$http', function ($http) {
        this.getCode = function (data) {
            return $http({
                url: window.backendUrl + "Users/ResendCode",
                method: 'POST',
                data: JSON.stringify(data),
                crossDomain: true,
                withCredentials: false,
                headers: { 'content-type': 'application/json' }
            });
        };
    }])
    .service('UpdateMobileService', UpdateMobileService)
    //.directive("mobileClaim", mobileClaim);
UsersController.$inject = [
    '$rootScope',
    '$scope',
    '$location',
    '$http',
    '$q',
    'MyDataService',
    'ProfileService',
    'ConfirmCodeService',
    'ResendCodeService',
    'UpdateMobileService',
    'UserData'
];
function UsersController($rootScope, $scope, $location, $http, $q, MyDataService, ProfileService, ConfirmCodeService, ResendCodeService, UpdateMobileService, UserData) {
    $rootScope.logoutText = UserData.getKey("LogoutText", "");
    $rootScope.mobileNumber = UserData.getKey("MobileNumber", "error");
    $rootScope.idee = UserData.getKey("UserId", "error");
    $rootScope.loginPanelActive = false;
    var vm = this;
    vm.toggleLoginPanel = function () {
        $rootScope.loginPanelActive = !$rootScope.loginPanelActive;
    }
    vm.regex = {
        Email: /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9]+$/,
        Mobile: /^(01[0-2])\d{8}$/
    }
    vm.days = [''];
    for (var i = 1; i <= 31; i++) {
        vm.days.push(i);
    }
    vm.months = {
        0: ['', '', ''],
        1: ['January', '1', '31'],
        2: ['February', '2', '29'],
        3: ['March', '3', '31'],
        4: ['April', '4', '30'],
        5: ['May', '5', '31'],
        6: ['June', '6', '30'],
        7: ['July', '7', '31'],
        8: ['August', '8', '31'],
        9: ['September', '9', '30'],
        10: ['October', '10', '31'],
        11: ['November', '11', '30'],
        12: ['December', '12', '31']
    };
    vm.years = [''];
    vm.now = new Date();
    for (var i = '1916'; i <= parseInt(vm.now.getFullYear()); i++) {
        vm.years.push(i);
    }
    vm.changeDate = function(date) {
        if (date.day > 28) {
            date.day--;
            return date;
        } else if (date.month > 11) {
            date.day = 31;
            date.month--;
            return date;
        }
    };
    vm.checkDate = function (date) {
        //if (!date.day || date.month === null || date.month === undefined || !date.year) return false;
        //var d = new Date(Date.UTC(date.year, date.month, date.day));
        //if (d && (d.getMonth() === date.month && d.getDate() === Number(date.day))) {
        //    return d;
        //}
        //return vm.checkDate(vm.changeDate(date));
        //if (date.day > date.month[1]) {
        //    date.day = date.month[1];
        //}
    };
    vm.updateDays = function () {
        var days = [];
        while (dayRange[0] <= dayRange[1]) {
            days.push(dayRange[0]++);
        }
        return days;
    };
    vm.updateMonths = function () {
        var lst = [],
            mLen = vm.months.length;

        for (var i = 0; i < mLen; i++) {
            lst.push({
                value: i,
                name: vm.months[i]
            });
        }
        return lst;
    };
    vm.getCurrDay = function () {
        var birthdate = Date(Date.parse(UserData.getKey("Birthdate", ""), "Ymd"));
    }
    vm.getCurrDay();
    vm.saveText = "Save";
    vm.verificationText = "Verify";
    vm.resendText = "Resend Code";
    vm.skipText = "Skip"
    vm.updateText = "";
    vm.changeText = "Update";
    vm.mobileUpdateText = "";
    vm.mobileRejectToggle = false;
    vm.submitted = false;
    vm.errors = {};
    vm.locations = null;
    MyDataService.getCountries().then(function (resp) {
        vm.Countries = resp.data;
    });
    vm.getCities = function () {
        if (vm.HomeLocation.CountryId !== "" && vm.HomeLocation.CountryId > -1) {
            return vm.Countries[vm.HomeLocation.CountryId-1].CountryCities;
        }
    }
    vm.getCompanyCities = function () {
        if (vm.CompanyLocation.CountryId !== "" && vm.CompanyLocation.CountryId > -1) {
            return vm.Countries[vm.CompanyLocation.CountryId-1].CountryCities;
        }
    }
    vm.User = UserData.getUser();
    vm.Locations = [];
    vm.HomeLocation = UserData.getHomeLocation();
    vm.CompanyLocation = UserData.getCompanyLocation();
    var homeLocationUndefiendCount = 0;
    var companyLocationUndefiendCount = 0;


    //Save Data
    vm.SaveData = function () {
        if (vm.saveText == 'Save') {
            if (!vm.validate()) return;
            if (vm.User.Day && vm.User.Month && vm.User.Year) {
                vm.User.Birthdate = vm.User.Day + '-' + vm.User.Month + '-' + vm.User.Year;
            }
            var currData = UserData.getUser();
            for (var property in vm.User) {
                currData[property] = vm.User[property];
            }
            var obj = {};
            obj.User = currData;
            for (var key in vm.HomeLocation) {
                if (key === "LocationId" || key === "LocationTypeId") continue;
                if (typeof (vm.HomeLocation[key]) === 'undefined') {
                    homeLocationUndefiendCount++;
                }
            }
            for (var key in vm.CompanyLocation) {
                if (key === "LocationId" || key === "LocationTypeId") continue;
                if (typeof (vm.CompanyLocation[key]) === 'undefined') {
                    companyLocationUndefiendCount++;
                }
            }
            if (homeLocationUndefiendCount === 0) {
                vm.HomeLocation.LocationTypeId = "1";
                vm.Locations.push(vm.HomeLocation);
            }
            if (companyLocationUndefiendCount === 0) {
                vm.CompanyLocation.LocationTypeId = "2";
                vm.Locations.push(vm.CompanyLocation);
            }
            var locations = [];
            for (var i = 0; i < vm.Locations.length; i++) {
                //if (Object.keys(vm.Locations[i]).length === 1) continue;
                locations.push(vm.Locations[i]);
            }
            obj.Locations = locations;
            vm.saveText = 'Please Wait...';
            ProfileService.SaveFormData(obj).then(function (d) {
                if (d.Msg === 'updated') {
                    //UserData.set(d.User);
                    UserData.set(d.User, d.HomeLocation, d.CompanyLocation);
                    //    for (var location in d.Locations) {
                    //        if (location.LocationTypeId === '1') {
                    //            UserData.setKey("HomeLocationTypeId", location);
                    //        }
                    //        else {
                    //            UserData.setKey("CompanyLocation", location);
                    //        }
                    //    }
                    //}
                    vm.updateText = "Changes are saved."
                }
                else {
                    vm.updateText = "No such user."
                }
                vm.saveText = "Save";
            });
        }
    }
    //if (!($("#ucompany").val() === '')) {
    //}
    //else {
    //    errors = 0;
    //}
    //errors += errors;
    //if (errors > 0) {
    //    $scope.$apply(function () {
    //        vm.regValid = false;
    //    })
    //}
    //else {
    //    //$scope.$apply(function () {
    //        vm.regValid = true;
    //    //})
    //}
    vm.ConfirmCode = function (code) {
        if (vm.verificationText === 'Verify') {
            if ($("#uverificationcode").val() === "") {
                vm.errors.InvalidVerificationCode = "Code cannot be empty.";
                return false;
            }
            else {
                vm.errors.InvalidVerificationCode = "";
            }
            var data = {};
            data.VerificationCode = code;
            data.UserId = UserData.getKey("UserId", "error");
            vm.verificationText = "Please Wait..."
            ConfirmCodeService.ConfirmCode(data).then(function (d) {
                if (d.Msg === 'Verified') {
                    vm.errors.InvalidVerificationCode = ""
                    UserData.set(d.User);
                    $location.path("/home");
                }
                else if (d.Msg === 'Invalid') {
                    vm.errors.InvalidVerificationCode = "Invalid verification code."
                }
                else {
                    vm.errors.InvalidVerificationCode = "No such user."
                }
                vm.verificationText = "Verify";
            });
        }
    };
    vm.ResendCode = function () {
        if (vm.resendText === 'Resend Code') {
            vm.resendText = 'Please wait...';
            var data = {};
            var userId = UserData.getKey("UserId", "-1");
            data.UserId = userId;
            ResendCodeService.getCode(data).then(function (res) {
                if (res.data.Msg === 'resent') {
                    UserData.setKey("UserCode", res.data.ResentCode);
                    vm.errors.InvalidVerificationCode = "Code has been resent.";
                }
                vm.resendText = 'Resend Code';
            })
            //.error(function () {
            //    vm.resendText === 'Resend Code';
            //});
        };
    };
    vm.skipVerification = function () {
        if (vm.skipText === 'Skip') {
            UserData.setKey("VerificationSkipped", true);
            $location.path('/home');
        }
    }
    vm.updateMobile = function() {
        $location.path('/users/verify/update');
    }
    vm.saveMobile = function (number) {
        if (vm.changeText === 'Update') {
            if ($("#newmobilenumber").val() === "") {
                vm.errors.InvalidMobileNumber = "Number cannot be empty.";
                return false;
            }
            else {
                vm.errors.InvalidMobileNumber = "";
            }
            if (vm.checkErrors()) {
                var obj = {};
                var id = UserData.getKey("UserId", "");
                var newMobile = number;
                obj.UserId = id;
                obj.NewMobile = newMobile;
                UpdateMobileService.updateMobile(obj).then(function (res) {
                    if (res.Msg === "changed") {
                        UserData.setKey("MobileNumber", res.NewMobileNumber);
                        $rootScope.mobileNumber = res.NewMobileNumber;
                        vm.toggleMobileReject();
                    }
                    vm.changeText = 'Update';
                })
            }
        }
    }
    vm.checkErrors = function() {
        var errors = 0;
        for (var key in vm.errors) {
            if (vm.errors[key] !== "") {
                errors++;
            }
        }
        if (errors > 0) {
            return false;
        }
        else {
            return true;
        }
    }
    vm.toggleMobileReject = function() {
        vm.mobileRejectToggle = !vm.mobileRejectToggle;
        vm.errors = {};
    }
    vm.validate = function () {
        var missing = 0;
        if ($("#fullname").val() === '') {
            missing++;
            vm.errors.FullName = "Full name is required.";
        }
        if ($("#mobilenumber").val() === '') {
            missing++;
            vm.errors.MobileNumber = "Mobile number is required.";
        }
        if ($("#email").val() === '') {
            missing++;
            vm.errors = "Email address is required.";
        }
        if ($("#password").val() === '') {
            missing++;
            vm.errors.Password = "Password is required.";
        }
        if (($("#day").val() !== '' || $("#month").val() !== '' || $("#year").val() !== '')) {
            if ($(this).siblings("select").val() === '') {
                missing++;
                vm.errors.BirthDate = "Full birthdate is required.";
            }
        }
        else {
            vm.errors.BirthDate = "";
        }
        if ($("#homecity").val() === '' && $("#homecountry").val() !== '') {
            missing++;
            vm.errors.HomeCity = "City is required.";
        }

        if ($("#company").val() === '') {
            vm.errors.CompanyCity = vm.errors.CompanyAddress = vm.errors.CompanyPostalCode = "";
            $("#companyphone, #companycountry, #companycity, #companyaddress, #companypostalcode").val("");
        }
        if (missing > 0) {
            return false;
        }
        else {
            var errors = 0;
            for (var key in vm.errors) {
                if (vm.errors[key] !== "") {
                    errors++;
                }
            }
            if (errors > 0) {
                return false;
            }
            else {
                return true;
            }
        }
    }
    $(document).ready(function () {
        var errors = 0;
        if ($("#company").val() === '') {
            $("#companyphone, \n\
                        #companycountry, \n\
                        #companycity, \n\
                        #companyaddress, \n\
                        #companypostalcode").attr('disabled', 'disabled');
        }
        else {
            $("#companyphone, \n\
                        #companycountry, \n\
                        #companycity, \n\
                        #companyaddress, \n\
                        #companypostalcode").attr('disabled', 'disabled').removeAttr('disabled');
        }
        $("#fullname").on("keyup change", function (e) {
            if (e.which === 9) return;
            $scope.$apply(function () {
                if ($("#fullname").val() === '') {
                    errors++;
                    vm.errors.FullName = "Full name is required.";
                }
                else if (!($("#fullname").val().match(/\s/g))) {
                    errors++;
                    vm.errors.FullName = "At least two words.";
                }
                else {
                    vm.errors.FullName = "";
                }
            });
        });
        $("#mobilenumber").on("keyup change", function (e) {
            if (e.which === 9) return;
            $scope.$apply(function () {
                if ($("#mobilenumber").val() === '') {
                    errors++;
                    vm.errors.MobileNumber = "Mobile number is required.";
                }
                else if (!($("#mobilenumber").val().match(/^(01[0-2])\d{8}$/))) {
                    errors++;
                    vm.errors.MobileNumber = "Invalid mobile number.";
                }
                else {
                    vm.errors.MobileNumber = "";
                }
            });
        });
        $("#email").on("keyup change", function (e) {
            if (e.which === 9) return;
            $scope.$apply(function () {
                if ($("#email").val() === '') {
                    errors++;
                    vm.errors.Email = "Email address is required.";
                }
                else if (!($("#email").val().match(/^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9]+$/))) {
                    errors++;
                    vm.errors.Email = "Invalid email address.";
                }
                else {
                    vm.errors.Email = "";
                }
            });
        });
        $("#password").on("keyup change", function (e) {
            if (e.which === 9) return;
            $scope.$apply(function () {
                if ($("#password").val() === $("#confirmpassword").val()) {
                    vm.errors.ConfirmPassword = "";
                }
                else {
                    errors++;
                    vm.errors.ConfirmPassword = "Passwords do not match.";
                }
                if ($("#password").val() === '') {
                    errors++;
                    vm.errors.Password = "Password is required.";
                }
                else if ($("#password").val().length < 6) {
                    errors++;
                    vm.errors.Password = "Password is too short";
                }
                else if ($("#password").val().length > 32) {
                    errors++;
                    vm.errors.Password = "Password is too long";
                }
                else {
                    vm.errors.Password = "";
                }
            });
        });
        $("#confirmpassword").on("keyup change", function (e) {
            if (e.which === 9) return;
            $scope.$apply(function () {
                if (!($("#confirmpassword").val() === $("#password").val())) {
                    errors++;
                    vm.errors.ConfirmPassword = "Passwords do not match.";
                }
                else {
                    vm.errors.ConfirmPassword = "";
                }
            });
        });
        $("#day, #month, #year").on("change", function () {
            var full = true;
            $(".birthdate").each(function () {
                if ($(this).val() === '') {
                    full = false;
                }
            });
            if ($(this).val() === '' || !full) {
                errors++;
                vm.errors.BirthDate = "Full birthdate is required.";
            }
            else {
                vm.errors.BirthDate = "";
            }
            if ($("#day").val() === '' && $("#month").val() === '' && $("#year").val() === '') {
                vm.errors.BirthDate = "";
            }
        })
        $("#male, #female").on("change", function (e) {
            if (e.which === 9) return;
            $scope.$apply(function () {
                vm.errors.Gender = "";
            });
        });
        $("#homecountry").on("change", function (e) {
            if (e.which === 9) return;
            $scope.$apply(function () {
                if ($("#homecountry").val() === '') {
                    errors++;
                    vm.errors.HomeCity = "";
                }
                else {
                    if ($("#homecity").val() === '') {
                        vm.errors.HomeCity = "City is required.";
                    }
                    else {
                        vm.errors.HomeCity = "";
                    }
                }
            });
        });
        $("#homecity").on("change", function (e) {
            if (e.which === 9) return;
            $scope.$apply(function () {
                if ($("#homecity").val() === '' && $("#homecountry").val() !== '') {
                    errors++;
                    vm.errors.HomeCity = "City is required.";
                }
                else {
                    vm.errors.HomeCity = "";
                }
            });
        });
        $("#homeaddress").on("keyup change", function (e) {
            if (e.which === 9) return;
            $scope.$apply(function () {
                if ($("#homeaddress").val() === '') {
                    errors++;
                    vm.errors.HomeAddress = "Address is required.";
                }
                else {
                    vm.errors.HomeAddress = "";
                }
            });
        });

        $("#homepostalcode").on("keyup change", function (e) {
            if (e.which === 9) return;
            $scope.$apply(function () {
                if ($("#homepostalcode").val() === '') {
                    errors++;
                    vm.errors.HomePostalCode = "Postal code is required.";
                }
                else {
                    vm.errors.HomePostalCode = "";
                }
            });
        });

        $("#company").on("keyup change", function (e) {
            if ($(this).val() === '') {
                $("#companyphone, \n\
                        #companycountry, \n\
                        #companycity, \n\
                        #companyaddress, \n\
                        #companypostalcode").attr('disabled', 'disabled');
            }
            else {
                $("#companyphone, \n\
                        #companycountry, \n\
                        #companycity, \n\
                        #companyaddress, \n\
                        #companypostalcode").removeAttr('disabled');
                $("#companyphone").on("keyup change", function (e) {
                    if (e.which === 9) return;
                    if ($("#companyphone").val() === '') {
                        $scope.$apply(function () {
                            errors++;
                            vm.errors.CompanyPhone = "Company phone number is required.";
                        })
                    }
                    else if (!($("#companyphone").val().match(/^(01[0-2])\d{8}$/))) {
                        $scope.$apply(function () {
                            errors++;
                            vm.errors.CompanyPhone = "Invalid company phone number.";
                        })
                    }
                    else {
                        $scope.$apply(function () {
                            vm.errors.CompanyPhone = "";
                        })
                    }
                });
                $("#companycountry").on("change", function (e) {
                    if (e.which === 9) return;
                    if ($(this).val() === '') {
                        $scope.$apply(function () {
                            errors++;
                            vm.errors.CompanyCity = "";
                        })
                    }
                    else {
                        if ($("#companycity").val() === '') {
                            $scope.$apply(function () {
                                vm.errors.CompanyCity = "Company city is required.";
                            })
                        }
                        else {
                            $scope.$apply(function () {
                                vm.errors.CompanyCity = "";
                            })
                        }
                    }
                });
                $("#companycity").on("change", function (e) {
                    if (e.which === 9) return;
                    if ($("#companycity").val() === '') {
                        $scope.$apply(function () {
                            errors++;
                            vm.errors.CompanyCity = "Company city is required.";
                        })
                    }
                    else if ($("#companycity").val() === '' && $("#companycountry").val() !== '') {
                        $scope.$apply(function () {
                            errors++;
                            vm.errors.CompanyCity = "Company city is required.";
                        })
                    }
                    else {
                        $scope.$apply(function () {
                            vm.errors.CompanyCity = "";
                        })
                    }
                });
                $("#companyaddress").on("keyup change", function (e) {
                    if (e.which === 9) return;
                    if ($("ucompanyaddress").val() === '') {
                        $scope.$apply(function () {
                            errors++;
                            vm.errors.CompanyAddress = "Company address is required.";
                        })
                    }
                    else {
                        $scope.$apply(function () {
                            vm.errors.CompanyAddress = "";
                        })
                    }
                });
                $("#companypostalcode").on("keyup change", function (e) {
                    if (e.which === 9) return;
                    if ($("#companypostalcode").val() === '') {
                        $scope.$apply(function () {
                            errors++;
                            vm.errors.CompanyPostalCode = "Company postal code is required.";
                        })
                    }
                    else {
                        $scope.$apply(function () {
                            vm.errors.CompanyPostalCode = "";
                        })
                    }
                });
            }
        })
        //=======================================================================================
        //Change Mobile Page
        //=======================================================================================
        $("#newmobilenumber").on("keyup change", function (e) {
            if (e.which === 9) return;
            if ($(this).val() === "") {
                $scope.$apply(function () {
                    errors++;
                    vm.errors.InvalidMobileNumber = "Number cannot be empty.";
                })
            }
            else if (!($("#newmobilenumber").val().match(vm.regex.Mobile))) {
                $scope.$apply(function () {
                    errors++;
                    vm.errors.InvalidMobileNumber = "Invalid mobile number.";
                })
            }
            else {
                $scope.$apply(function () {
                    errors++;
                    vm.errors.InvalidMobileNumber = "";
                })
            }
        })
        $("#uverificationcode").on("keyup change", function (e) {
            if (e.which === 9) return;
        })
        $("#image").on("change", function (e) {
            var fileName = e.target.files[0].name;
            vm.User.Image = fileName;
        })
    });
};
function ProfileService($http, $q) {
    var fac = {};
    fac.SaveFormData = function (data) {
        var defer = $q.defer();
        $http({
            url: window.backendUrl + "Users/Update",
            method: 'POST',
            data: JSON.stringify(data),
            crossDomain: true,
            withCredentials: false,
            headers: { 'content-type': 'application/json' }
        }).success(function (d) {
            // Success callback
            defer.resolve(d);
            //defer.resolve(d);
        }).error(function (e) {
            //Failed Callback
            alert('Error!');
            defer.reject(e);
        });
        return defer.promise;
    }
    return fac;
}
function ConfirmCodeService($http, $q) {
    var o = {};
    o.ConfirmCode = function (data) {
        var defer = $q.defer();
        $http({
            url: window.backendUrl + "Users/Verify",
            method: 'POST',
            data: JSON.stringify(data),
            crossDomain: true,
            withCredentials: false,
            headers: { 'content-type': 'application/json' }
        }).success(function (d) {
            // Success callback
            defer.resolve(d);
        }).error(function (e) {
            //Failed Callback
            alert('Error!');
            defer.reject(e);
        });
        return defer.promise;
    }
    return o;
}
function UpdateMobileService($http, $q) {
    var o = {};
    o.updateMobile = function (data) {
        var defer = $q.defer();
        $http({
            url: window.backendUrl + "Users/UpdateMobile",
            method: 'POST',
            data: JSON.stringify(data),
            crossDomain: true,
            withCredentials: false,
            headers: { 'content-type': 'application/json' }
        }).success(function (d) {
            defer.resolve(d);
        }).error(function (e) {
            alert('Error!');
            defer.reject(e);
        });
        return defer.promise;
    }
    return o;
}
