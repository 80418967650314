(function () {
    'use strict';

    angular
        .module('eltazkaraApp')
        .controller('SearchController', SearchController);

    SearchController.$inject = [
      '$rootScope',
      'NgTableParams',
      'SearchData',
      '$window',
      '$localStorage'
    ];
    function SearchController($rootScope, NgTableParams, SearchData, $window, $localStorage) {
        var vm = this;
        vm.hasperformers = false;
        vm.hasEvents = false;
        vm.hasvenues = false;

        $rootScope.initSearchPage = function () {
            var searchDataFromServer = SearchData.getData();
            if (searchDataFromServer) {
                $localStorage.searchContent = searchDataFromServer;
            }
            else {
                searchDataFromServer = $localStorage.searchContent;
            }
            var data = [];
            var performers = [];
            var venues = [];
            for (var item = 0; item < searchDataFromServer.length; item++) {
                if (searchDataFromServer[item].type == 'Performance') {
                    var event = {
                        id: searchDataFromServer[item].id,
                        fullDate: searchDataFromServer[item].day + searchDataFromServer[item].month + searchDataFromServer[item].year,
                        StartDate: searchDataFromServer[item].StartDate,
                        day: searchDataFromServer[item].day,
                        dayName: searchDataFromServer[item].dayName,
                        month: searchDataFromServer[item].month,
                        year: searchDataFromServer[item].year,
                        time: searchDataFromServer[item].time,
                        eventName: searchDataFromServer[item].eventName,
                        eventId: searchDataFromServer[item].eventId,
                        performence: searchDataFromServer[item].title,
                        venue: searchDataFromServer[item].venueName,
                        venueId: searchDataFromServer[item].venueId,
                        avTickets: searchDataFromServer[item].avTickets,
                        IsBuyBlocked: searchDataFromServer[item].IsBuyBlocked,
                        IsSellBlocked: searchDataFromServer[item].IsSellBlocked

                    }
                    data.push(event);

                }
                else if (searchDataFromServer[item].type == 'Performer') {
                    var performer = { id: searchDataFromServer[item].id, name: searchDataFromServer[item].title };
                    performers.push(performer);
                }
                else if (searchDataFromServer[item].type == 'Venue') {
                    var venue = { id: searchDataFromServer[item].id, name: searchDataFromServer[item].title };
                    venues.push(venue);
                }
            }
            if (data.length > 0) vm.hasEvents = true;
            if (performers.length > 0) vm.hasperformers = true;
            if (venues.length > 0) vm.hasvenues = true;
            vm.eventsConfig = createUsingFullOptions(data);
            vm.performersConfig = createUsingFullOptions(performers);
            vm.venuesConfig = createUsingFullOptions(venues);

            function createUsingFullOptions(dataSource) {
                var initialParams = {
                    count: 10 // initial page size
                };
                var initialSettings = {
                    // page size buttons (right set of buttons in demo)
                    counts: [],
                    // determines the pager buttons (left set of buttons in demo)
                    paginationMaxBlocks: 13,
                    paginationMinBlocks: 2,
                    dataset: dataSource
                };
                return new NgTableParams(initialParams, initialSettings);
            }
        }

        $rootScope.initSearchPage();
        vm.sell_redirection = function (event) {
            if ($rootScope.User && $rootScope.User.Email) {
                location.href = "#/performance/" + event.id + "/sell";
            }
            else {
                vm.toggleLoginPanel();
            }

        }
        vm.toggleLoginPanel = function () {
            $window.scrollTo(0, 0);
            $rootScope.loginPanelActive = !$rootScope.loginPanelActive;
            document.getElementById("userLoginEmail").value = "";
            document.getElementById("userLoginPassword").value = "";
            document.getElementById("userLoginRemember").checked = false;
            $rootScope.userLoginError = false;
            vm.User = {};
            vm.errors = {};
        };

    }
})();
