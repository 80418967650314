(function () {
    'use strict';

    angular
        .module('eltazkaraApp')
        .factory('FilesService', Files);//

    Files.$inject = ['$resource'];

    function Files($resource) {
        var service = $resource(window.backendUrl + 'Files', {}, {
            'uploadToS3': {
                method: 'POST', 
                isArray: false,
                url: window.backendUrl + "Files/UploadToS3Bucket",
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8'
                }
            }
        });
        
        return service;
    }
})();
