(function () {
    'use strict';

    angular
        .module('eltazkaraApp')
        .controller('PaymentSuccessPopupController', PaymentSuccessPopupController);

    PaymentSuccessPopupController.$inject = ['$scope', '$rootScope', '$timeout'];

    function PaymentSuccessPopupController($scope, $rootScope, $timeout) {
        var vm = this;

        $('.orderCountDown', window.parent.document).fadeOut();

        $timeout(function () {
            $rootScope.$broadcast('timer-stop');
        }, 1000);

        vm.returnToOrders = function () {
            window.top.location = '#/users/orders'
        };
    }

})();
