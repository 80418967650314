(function () {
    'use strict';

    angular
        .module('eltazkara.social')
        .factory('fb', facebookFactory)
        .directive('facebook', facebookDirective);

    // Directive
    function facebookDirective() {
        return {
            restrict: 'A',
            scope: true,
            rootScope: true,
            controller: facebookCtrl,
            link: facebookLinker
        }
    }

    function facebookFactory() {
        var logout = function () {
            FB.logout();
        };

        return {
            logout: logout
        }
    }

    facebookCtrl.$inject = ['$scope', '$rootScope', 'Facebook', '$cookies', 'Auth', '$state'];

    // Controller
    function facebookCtrl($scope, $rootScope, Facebook, $cookies, Auth, $state) {
        (function (d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            js = d.createElement(s);
            js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));

        function login() {
            FB.login(function (response) {
                if (response.authResponse) {
                    $scope.auth = response.authResponse;
                    fetch();
                }
            }, { auth_type: 'reauthenticate', scope: 'email,user_photos,user_gender' });
        };

        function fetch() {
            Facebook.post({
                accessToken: $scope.auth.accessToken,
                client_id: "ngAuthApp",
                client_secret: 0
            }, function (response) {
                if (response.data.status && response.data.status == "Success") {
                    var authorizationData = {
                        token: response.data.access_token,
                        userName: response.data.userName,
                        refreshToken: response.data.refresh_token,
                        expires_in: response.data.expires_in,
                        token_type: response.data.token_type
                    };

                    Auth.loginWithToken(authorizationData, true).then(function () {
                        Auth.authorize(true);
                        $rootScope.loggedOut = false;

                        if (response.data.redirect === 'setting') {
                            $rootScope.facebookUser = true;
                            $rootScope.redirecSetting = true;
                            $state.go('account');
                        }
                    });
                }
                else {
                    $rootScope.PopUpTitle = "Login Error";
                    $rootScope.PopUpDescription = response.data.status;
                    $rootScope.PopError = true;
                    FB.logout();
                }
            }, function (error) {
                FB.logout();
                console.log(error);
            });
        }

        $scope.getLoginStatus = function () {
            FB.getLoginStatus(function (response) {
                if (response.status === 'connected') {
                    scope.auth = response.authResponse;
                }

                scope.login_status = response.status;
                scope.stopLoading();
                scope.$apply();
            });
        }

        $scope.fetch = function () {
            if ($scope.login_status == 'connected') {
                fetch();
            } else {
                login();
            }
        };

        $scope.stopLoading = function () {
            $rootScope.startLoading(false, true);
        }

        function logout() {
            FB.logout(function (response) {
                $rootScope.$apply(function () {
                    $rootScope.user = _self.user = {};
                });
            });
        }
    }

    // Linker
    function facebookLinker(scope, element, attrs) {
        window.fbAsyncInit = function () {
            FB.init({
                appId: attrs.facebook,
                cookie: true,
                xfbml: true,
                version: 'v2.5'
            });

            FB.getLoginStatus(function (response) {
                if (response.status === 'connected') {
                    scope.auth = response.authResponse;
                }

                scope.login_status = response.status;
                scope.stopLoading();
                scope.$apply();
            });
        };
    }
})();
