(function () {
  'use strict';

  angular
      .module('eltazkaraApp')
      .controller('RequestsController', RequestsController)
      .service('RequestsService', RequestsService);
      RequestsService.$inject = ['$resource'];

      function RequestsService($resource) {
          var service = $resource(window.backendUrl + 'Requests', {}, {
              'AddRequest': {
                  method: 'POST', isArray: false,
                  url: window.backendUrl + "Requests/SaveRequest"
              },
              'getRequestTypes2': {
                  method: 'GET', isArray: true,
                  url: window.backendUrl + "Requests/GetRequestTypes"
              },
              'getUserInfo': {
                  method: 'GET', isArray: false,
                  url: window.backendUrl + "Requests/GetUserInfoIFLoggedIn"
              }
          });
          return service;
      }
  RequestsController.$inject = [
    '$rootScope',
    'RequestsService',
    '$state'
  ];

  function RequestsController($rootScope,RequestsService, $state) {
      var vm = this;
      if ($rootScope.User) {
          vm.id = $rootScope.User.UserId;
          vm.getUserInfo = function () {
              RequestsService.getUserInfo()
                  .$promise.then(function (data) {
                      if (data) {
                          vm.UserName = data.name;
                          vm.UserEmail = data.email;
                      }
                  }, function (error) { })
          };

          vm.getUserInfo();
      }
      else
          vm.id = 0;
      vm.RequestsTypes = [];
      vm.type = $state.params.type.split("_")[0] + " " + $state.params.type.split("_")[1];
      vm.getRequestTypes = function () {
          RequestsService.getRequestTypes2()
              .$promise.then(function (data) {
                  if (data) {
                      vm.Types = data;
                      for (var i = 0; i < vm.Types.length;i++){
                          if (vm.Types[i].isHidden == false) {
                              vm.RequestsTypes.push(vm.Types[i]);
                          }
                      }
                      for (var i = 0; i < vm.RequestsTypes.length; i++) {
                          if ((vm.RequestsTypes[i].name)==(vm.type)) {
                              vm.SelectedType = vm.RequestsTypes[i];
                              break;
                          }
                      }

                  }
              }, function (error) { })
      };

      vm.getRequestTypes();

      var clicked = false;
      vm.submitText = "Submit";
      vm.saveRequestData = function (bool) {
          if (!clicked) {
              vm.submitText = "Please Wait..."
              clicked = true;
              if (!bool)
                  return;
              if (vm.id != 0) {
                  vm.Name = vm.UserName;
                  vm.Email = vm.UserEmail;
              }
              RequestsService.AddRequest({
                  "Subject": vm.requestSubject,
                  "RequestTypeId": vm.SelectedType,
                  "Name": vm.Name,
                  "Mobile": vm.Mobile,
                  "Email": vm.Email,
                  "Body": vm.requestBody
              })
              .$promise.then(function (data) {
                  if (data) {
                      $state.go('home', {});
                  }
              }, function (error) {
                  clicked = false;
                  vm.submitText = "Submit";
                  $rootScope.PopUpTitle = "Error";
                  $rootScope.PopUpDescription = "Cannot submit, please conact admin, or try later";
                  $rootScope.PopError = true;
              })
          };
      }

  }

})();
