(function () {
    'use strict';

    angular
        .module('eltazkaraApp')
        .factory('Event', Event);

    Event.$inject = ['$resource'];

    function Event($resource) {
        var service = $resource(window.backendUrl + '/Events', {}, {
            'getEvent': {
                method: 'POST', isArray: false,
                url: window.backendUrl + "Events/GetEvent"
            },
            'getEventPerformances': {
                method: 'POST', isArray: false,
                url: window.backendUrl + "Events/GetEventPerformances"
            }
        });

        return service;
    }
})();
