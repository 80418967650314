(function () {
    'use strict';

    angular
        .module('eltazkaraApp')
        .controller('PaymentDetailsController', PaymentDetailsController);

    PaymentDetailsController.$inject = [
        '$http',
        'localStorageService',
        'PaymentService',
        '$scope',
        '$rootScope',
        '$state',
        '$stateParams',
        '$location',
        '$timeout',
        '$window',
        '$sce'
    ];

    function PaymentDetailsController(
        $http,
        localStorageService,
        PaymentService,
        $scope,
        $rootScope,
        $state,
        $stateParams,
        $location,
        $timeout,
        $window,
        $sce
    ) {
        var vm = this;
        vm.IsNewCard = vm.existingCard = vm.showCards = vm.timeExceeded = vm.backToCardsList = false;

        vm.months = [
            '01',
            '02',
            '03',
            '04',
            '05',
            '06',
            '07',
            '08',
            '09',
            '10',
            '11',
            '12'
        ];

        vm.orderRef = $stateParams.orderRef;
        vm.SelectedCreditCard;
        vm.publicIp;
        vm.mins;

        //Get Order Details
        vm.GetOrder = function () {
            PaymentService.getOrder({ OrderRef: vm.orderRef }).$promise.then(
                function (data) {
                    if (data) {
                        if (data.message == '4041')
                            vm.finsihTimer();
                        else {
                            vm.cardYears = data.CardYears;
                            vm.selectedYear = data.CardYears[0] + '';
                            vm.selectedMonth = '01';
                            vm.order = [];
                            vm.order = data;

                            if (data.PaymentStatus == 2)
                                vm.paymentFailed = true;
                            else if (data.Status == 2) {
                                vm.paymentDone = true;
                                vm.paymentLoaded = true;
                            }
                            else if (data.Status == 3)
                                vm.finsihTimer();
                            else {
                                vm.mins = data.RemainingTime;
                                vm.orderLoaded = true;
                                vm.showPayment = true;
                                vm.GenerateForm();
                            }
                        }
                    }
                },
                function (error) { console.log(error); }
            );
        };

        //Payfort iframe injection
        vm.GenerateForm = function () {
            var http = new XMLHttpRequest();
            var url = window.backendUrl + 'Payment/GetKashierFrameUrl';
            http.open('post', url, true);
            http.setRequestHeader('Content-type', 'application/json');

            http.onreadystatechange = function () {
                //Call a function when the state changes.
                if (http.readyState == 4 && http.status == 200) {
                    vm.paymentLoaded = true;
                    vm.kashierIFrameSrc = $sce.trustAsResourceUrl(http.response);
                }
            };

            http.send(JSON.stringify({ orderRef: $stateParams.orderRef }));
        };

        vm.GetOrder();

        //Redirect to orders page
        vm.returnToOrders = function () {
            $state.go('boughtTickets', {}, { reload: 'boughtTickets' });
        };

        //On Timer Finish
        vm.finsihTimer = function () {
            vm.showPayment = false;

            PaymentService.cancelOrder({ OrderRef: vm.orderRef }).$promise.then(function (data) {
                if (data.orderStatus == 3)
                    vm.timeExceeded = true;
            }, function (error) {
                console.log(error);
            });
        };
    }

    //iframe onload/state changes
    window.setIframeLoaded = function () {
        window.setTimeout(function () {
            PaymentDetailsController.paymentLoaded = true;
        }, 1000);
    };
})();