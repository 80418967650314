(function () {
    'use strict';

    angular
        .module('eltazkaraApp')
        .factory('Performance', Performance);

    Performance.$inject = ['$resource'];

    function Performance($resource) {
        var service = $resource(window.backendUrl + 'Performances', {}, {
            'getPerformance': {
                method: 'POST', isArray: false,
                url: window.backendUrl + "Performances/GetPerformance"
            },
            'isTicketGroupCancelled': {
                method: 'POST', isArray: false,
                url: window.backendUrl + "Performances/IsTicketGroupCancelled"
            }
        });

        return service;
    }
})();
