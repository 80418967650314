(function () {
  'use strict';

  angular
      .module('eltazkara.interceptor')
      .factory('authInterceptor', authInterceptor);

  authInterceptor.$inject = ['$rootScope', '$q', '$location', '$localStorage', '$sessionStorage'];

  function authInterceptor($rootScope, $q, $location, $localStorage, $sessionStorage) {
    var service = {
      request: request
    };

    return service;

    function request(config) {
      /*jshint camelcase: false */
      config.headers = config.headers || {};
      var authData = $localStorage.authenticationToken || $sessionStorage.authenticationToken;
      //vm.data.Body = "refresh_token=" + token + "&grant_type=refresh_token"
      //             "&client_id=ngAuthApp" + "&client_secret=0";
      if (authData) {
          config.headers.Authorization = 'Bearer ' + authData.token;
          config.headers.AuthorizationRefesh = 'Bearer ' + authData.refreshToken;
      }
      return config;
    }
  }
})();
