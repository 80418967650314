(function () {
    'use strict';

    angular
        .module('eltazkaraApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider', '$urlRouterProvider', '$locationProvider'];

    function stateConfig($stateProvider, $urlRouterProvider, $locationProvider) {
        $stateProvider.state('app', {
            abstract: true,
            views: {
                'topBar@': {
                    templateUrl: 'layouts/topBar/topBar.html',
                    controller: 'TopBarController',
                    controllerAs: 'vm'
                },
                'footer@': {
                    templateUrl: 'layouts/footer/footer.html',
                    controller: 'FooterController',
                    controllerAs: 'footerC'
                }
            },
            resolve: {
                authorize: ['Auth',
                  function (Auth) {
                      return Auth.authorize();
                  }
                ],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                }]
            }
        })
        .state('404', {
            parent: 'app',
            url: '/404',
            views: {
                'content@': {
                    templateUrl: 'components/users/404.html',
                    controller: 'NotFoundController',
                    controllerAs: 'vm'
                }
            }
        });
        $urlRouterProvider.when('', 'home').when('/', 'home').otherwise('404');
    }
})();
